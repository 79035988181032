import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const CWLogGroup = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CWLogGroup.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CWMAlarm = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CWMAlarm.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CWMQuery = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CWMQuery.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const XRay = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'XRay.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const CWMetricFilter = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CWMetricFilter.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { CWLogGroup, CWMAlarm, CWMQuery, XRay, CWMetricFilter };

