import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const CUR = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CUR.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { CUR };

