
import { APIAuth, APIDB, APIPricing } from "./Config.js"
import pako from 'pako';
import AWS from 'aws-sdk';
import { MarkerType } from 'reactflow';
import {
  ListaStandard, ResourceLookUp, TypeVPC, TypeSBox, Configuration, GlobalMoveFlag, RefreshToken, GlobalToken, Code,
  TypeTerraform, TypeRegion, TypeSubnet, TypeCloud, TypeAZ, TypeSecurityGroup, LastSaved, MaxItemPerPage, worker
} from './NodesData';
import { Stage } from "./Config.js"



var GlobalNodeID = 1;
var GlobalSaveAll = false;

function GetVPCParent(Nodes, ID) {
  try {
    while (true) {
      if (TypeVPC.includes(Nodes[ID].type)) {
        return ID;
      } else if (TypeCloud.includes(Nodes[ID].type)) {
        return 0;
      } else {
        ID = parseInt(Nodes[ID].parentNode);
      }
    }
  } catch (error) {
    //console.log("error", ID, error)
  }
}


//Return a List of source nodes connected
function SearchNodesSource(Edges, Nodes, Node, Type = "all", EdgeID = "") {
  let List = [];
  try {
    for (let j = 0; j < Edges.length; j++) {
      if (EdgeID == "") {
        if (Edges[j].target === Node.id) {
          let SourceNodeID = parseInt(Edges[j].source);
          if (Type == "all") {
            List.push(SourceNodeID);
          } else {
            if (Nodes[SourceNodeID].type == Type) {
              List.push(SourceNodeID);
            }
          }
        }
      } else {
        //If exists more than one handle, EdgeID != "". 
        //So it must check targetHadle to get information from appropriate handle
        if ((Edges[j].target === Node.id) && (Edges[j].targetHandle === EdgeID)) {
          let SourceNodeID = parseInt(Edges[j].source);
          if (Type == "all") {
            List.push(SourceNodeID);
          } else {
            if (Nodes[SourceNodeID].type == Type) {
              List.push(SourceNodeID);
            }
          }
        }

      }
    }
    return List;
  } catch (error) {
    return [];
  }
}

//Return a List of target nodes ID connected
function SearchNodesTarget(Edges, Nodes, Node, Type = "all") {
  try {
    let List = [];
    for (let j = 0; j < Edges.length; j++) {
      if (Edges[j].source === Node.id) {
        let TargetNodeID = parseInt(Edges[j].target);
        if (Type == "all") {
          List.push(TargetNodeID);
        } else {
          if (Nodes[TargetNodeID].type == Type) {
            List.push(TargetNodeID);
          }
        }
      }
    }
    return List;
  } catch (error) {
    return [];
  }
}

function SearchNodesByLevels(Edges, Nodes, StartNode, Filters) {
  function recursiveSearch(currentNode, filterIndex) {
    // Se chegamos ao último filtro e encontramos o nó, retornamos seu ID em uma lista
    if (filterIndex >= Filters.length) return [parseInt(currentNode.id, 10)];

    // Extrai direção e tipos do filtro atual
    let [direction, types] = Filters[filterIndex].split(':');
    direction = direction.trim().toLowerCase();
    let typeArray = types.split(',').map(t => t.trim());

    // Obter lista de nós conectados com base na direção e tipo
    let connectedNodes = [];
    if (direction === "source") {
      connectedNodes = typeArray.includes("all")
        ? SearchNodesSource(Edges, Nodes, currentNode, "all")
        : typeArray.flatMap(type => SearchNodesSource(Edges, Nodes, currentNode, type));
    } else if (direction === "target") {
      connectedNodes = typeArray.includes("all")
        ? SearchNodesTarget(Edges, Nodes, currentNode, "all")
        : typeArray.flatMap(type => SearchNodesTarget(Edges, Nodes, currentNode, type));
    } else {
      console.warn(`Direção desconhecida: ${direction}`);
      return [];
    }

    // Converte os nodeId de string para inteiros e realiza a busca recursiva
    connectedNodes = connectedNodes.map(id => parseInt(id, 10));

    // Armazenar os resultados de cada caminho válido
    let results = [];

    // Busca recursiva no próximo nível para cada nó encontrado
    for (let nodeId of connectedNodes) {
      const foundNodes = recursiveSearch(Nodes[nodeId], filterIndex + 1);
      results = results.concat(foundNodes); // Adiciona os nós encontrados ao resultado final
    }

    return results;
  }

  // Inicia a busca a partir do nó inicial e do primeiro filtro
  return recursiveSearch(StartNode, 0);
}






function ResourceLabelAdjust(InputLabel) {
  let Label = InputLabel;
  if (Label.includes("_")) {
    Label = InputLabel.replaceAll("_", " ");
  }
  if (InputLabel.includes("aws")) {
    Label = Label.replace("aws", "");
  }
  let List = Label.split(" ");
  Label = "";
  for (let i = 0; i < List.length; i++) {
    Label += List[i].charAt(0).toUpperCase() + List[i].slice(1) + " ";
  }
  return Label;
}

////Return true if find a especific target type node connected at two levels from a source node
function SearchNodesTargetTwoLevels(Edges, Nodes, NodeSource, ConnectionTypeL1, ConnectionTypeL2) {
  let ListTargetL1 = SearchNodesTarget(Edges, Nodes, NodeSource, ConnectionTypeL1);
  if (ListTargetL1.length == 0) {
    return false;
  } else {
    for (let i = 0; i < ListTargetL1.length; i++) {
      let ListTargetL2 = SearchNodesTarget(Edges, Nodes, Nodes[ListTargetL1[i]], ConnectionTypeL2);
      if (ListTargetL2.length == 0) {
        return false;
      } else {
        return true;
      }
    }
  }
}

////Return true if find a especific target of a node connected out
function VerifyNodesTypeTarget(Edges, Nodes, NodeSource, ConnectionType) {
  let ListTargetL1 = SearchNodesTarget(Edges, Nodes, NodeSource, ConnectionType);
  if (ListTargetL1.length == 0) {
    return false;
  } else {
    return true;
  }
}

function RemoveSelected(nodes) {
  nodes.map((node) => {
    if (node.id === 'Config') {
      node.hidden = true;
    };
    node.selected = false;
  })
}

//Returm the absolute position considering all father nodes until Cloud
function GetFatherPosition(nodes, FatherNodeID, Position) {
  try {
    let Node = nodes[FatherNodeID];
    if (typeof (FatherNodeID) != "number") {
      Node = nodes[parseInt(FatherNodeID)];
    }
    Position.x += JSON.parse(JSON.stringify(Node.position.x));
    Position.y += JSON.parse(JSON.stringify(Node.position.y));
    let NodeParent = parseInt(Node.parentNode);
    if (NodeParent !== 0) {
      //console.log("Position", Node.type, Position)
      let NewPosition = JSON.parse(JSON.stringify(GetFatherPosition(nodes, NodeParent, JSON.parse(JSON.stringify(Position)))));
      return NewPosition;
    }
    return Position;
  } catch (error) {
    return Position;
  }
}

//Returms the relative node Father position
function AdjustFatherPosition(nodes, FatherNodeID, position, It = 0) {
  try {
    let Node = nodes[parseInt(FatherNodeID)];
    position.x -= Node.position.x;
    position.y -= Node.position.y;
    let NodeParent = parseInt(Node.parentNode);
    if (NodeParent !== 0) {
      It += 1;
      let newPosition = { x: 0, y: 0 };
      if (It < 20) {
        newPosition = AdjustFatherPosition(nodes, NodeParent, position, It);
      } else { console.log("proteção de Loop infinito"); }
      position = newPosition;
    }
  } catch (error) {
    //pass
  }
  return position;
}

//Return ID List of one especific type 
function SearchNodeType(nodes, type) {
  let List = [];
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].type == type) {
      List.push(nodes[i].id);
    }
  }
  return List
}

//Return Name List of one especific type 
function SearchNodeTypeReturnNameList(nodes, type) {
  let List = [];
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].type == type) {
      List.push(nodes[i].data.Param[1][1]);
    }
  }
  return List
}

/*function compareAndAdd(str, arr) {
  let newStr = str;
  while (arr.includes(newStr)) {
    let match = newStr.match(/-(\d+)$/);
    if (match) {
      let number = parseInt(match[1]) + 1;
      newStr = newStr.replace(/-\d+$/, "-" + number);
    } else {
      newStr = newStr + "-1";
    }
  }
  return newStr;
}*/
function compareAndAdd(str, arr) {
  let newStr = str;
  while (arr.includes(newStr)) {
    const match = newStr.match(/(\d+)$/);
    if (match) {
      const number = parseInt(match[1], 10) + 1;
      newStr = newStr.replace(/\d+$/, number);
    } else {
      newStr += "1";
    }
  }
  return newStr;
}
//Compare if Node Name already exist. If true, insert a sufix for the name to be unique
//Return Name to be add.
function SearchNameNodeType(nodes, type, Name) {
  let List = SearchNodeTypeReturnNameList(nodes, type);
  //console.log("compareAndAdd(Name, List);", Name, List);
  Name = compareAndAdd(Name, List);
  return Name;
}

//Return Chield List from Selected Node and ID of selected node
function FindNodesEdgesChieldSelected(nodes, Edges) {
  var SelectedNode = "0";
  for (let i = 0; i < nodes.length; i++) {//Acha primeiro selected Node
    if (nodes[i].selected) {
      var SelectedNode = nodes[i];
    }
  }
  if (SelectedNode != "0") {
    let SelectedGroup = FindNodesEdgesChield(nodes, Edges, SelectedNode);
    //console.log("SelectedGroup", SelectedGroup)
    return SelectedGroup;
  } else {
    return [];
  }
}

//Return a list Nodes ID above the SelectedNode.
function FindNodesChieldIDFromParent(nodes, NodeParent) {
  let SelectedNode = NodeParent;
  let NodesList = FindNodesEdgesChield(nodes, [], SelectedNode);
  NodesList = NodesList[0];
  let NodesListID = [];
  for (let i = 0; i < NodesList.length; i++) {
    NodesListID.push(NodesList[i].id);
  }
  return NodesListID;
}


//Return a list of Edes and Nodes above the SelectedNode, including it.
//SelectedNode = node
function FindNodesEdgesChield(nodes, Edges, SelectedNode) {
  var SelectedNodeID = SelectedNode.id;
  var ListNodes = [SelectedNode];
  var ListNodesID = [SelectedNodeID];
  var ListSearchID = [SelectedNodeID];
  while (ListSearchID.length > 0) {
    for (let i = 0; i < nodes.length; i++) {
      let ChieldNode = nodes[i].parentNode;
      if ((ChieldNode == SelectedNodeID) && (ListNodesID.includes(nodes[i].id) == false)) {
        ListNodes.push(nodes[i]);
        ListNodesID.push(nodes[i].id);
        ListSearchID.push(nodes[i].id);
      }
    }
    ListSearchID.shift();
    SelectedNodeID = ListSearchID[0];
  }
  var ListEdges = [];
  var ListEdgesID = [];
  for (let i = 0; i < Edges.length; i++) {
    if ((ListNodesID.includes(Edges[i].target)) && (ListNodesID.includes(Edges[i].source))) {
      ListEdges.push(Edges[i]);
      ListEdgesID.push(Edges[i].id);
    }
  }
  //Find all other nodes, are connected with any of these nodes Chield.
  //First list of Nodes target these nodes Chield
  var ListEdgesOutSource = [];
  for (let i = 0; i < Edges.length; i++) {
    if (ListNodesID.includes(Edges[i].source)) {
      if (ListEdgesID.includes(Edges[i].id)) {
        //pass
      } else {
        ListEdgesOutSource.push(Edges[i]);
      }
    }
  }
  var ListEdgesOutTarget = [];
  for (let i = 0; i < Edges.length; i++) {
    if (ListNodesID.includes(Edges[i].target)) {
      if (ListEdgesID.includes(Edges[i].id)) {
        //pass
      } else {
        ListEdgesOutTarget.push(Edges[i]);
      }
    }
  }
  return [ListNodes, ListEdges, ListEdgesOutSource, ListEdgesOutTarget];
}

//Return a list of all Nodes ID below the SelectedNode, including it.
function FindNodesChieldID(nodes, SelectedNodeID, NodeType = "All") {
  try {
    //console.log("SelectedNodeID", SelectedNodeID)
    var ListNodesID = [SelectedNodeID.toString()];
    var ListSearchID = [SelectedNodeID];
    while (ListSearchID.length > 0) {
      for (let i = 0; i < nodes.length; i++) {
        let ChieldNode = nodes[i].parentNode;
        if (ChieldNode == SelectedNodeID) {
          ListNodesID.push(nodes[i].id);
          ListSearchID.push(nodes[i].id);
        }
      }
      ListSearchID.shift();
      SelectedNodeID = ListSearchID[0];
    }
    if (NodeType != "All") {
      let NewListNodesID = [];
      for (let i = 0; i < ListNodesID.length; i++) {
        if (nodes[parseInt(ListNodesID[i])].type == NodeType) {
          NewListNodesID.push(ListNodesID[i])
        }
      }
      ListNodesID = NewListNodesID;
    }
    return ListNodesID;
  } catch (error) {
    //pass
  }
}

//Return a list of only Nodes ID above parent and below the SelectedNode, including it.
function FindNodesChieldOneLevelID(nodes, FatherNodeID) {
  let ListNodesID = [];
  for (let i = 0; i < nodes.length; i++) {
    let NodeID = nodes[i].parentNode;
    if (FatherNodeID == NodeID) {
      ListNodesID.push(nodes[i].id);
    }
  }
  return ListNodesID;
}

//retrun a list of the selected nodes
function FindSelectedNodes(Nodes) {
  var ListSelected = [];
  for (let i = 0; i < Nodes.length; i++) {
    if (Nodes[i].selected == true) {
      ListSelected.push(Nodes[i]);
    }
  }
  return ListSelected;
}

//Return the especifiec resource Father ID. If didnt find, return "0"
//Nodes = array with list of nodes to be searched
//Node = Node leaf
//NodeFatherType = node type to be found
function FindResourceFather(Nodes, Node, NodeFatherType) {
  if (TypeCloud.includes(Node.type)) { return "0"; }
  while (true) {
    try {
      var ParentNode = parseInt(Node.parentNode);
    } catch (error) { return "0"; }
    let NodeType = Nodes[ParentNode].type;
    if (TypeCloud.includes(NodeType)) { return "0"; }
    if (NodeType == NodeFatherType) {
      return parseInt(Nodes[ParentNode].id);
    } else {
      Node = Nodes[ParentNode]
    }
  }
}
//return a nodeID list of the father and above
function FindAllFathers(Nodes, NodeID) {
  let ListFather = [];
  if (TypeCloud.includes(Nodes[parseInt(NodeID)].type)) {
    return ListFather;
  } else {
    let x = 30;
    while (true) {
      try {
        var ParentNode = Nodes[NodeID].parentNode;
      } catch (error) { return []; }
      if (ParentNode == "Config") { return ListFather }
      ListFather.push(ParentNode);
      NodeID = ParentNode;
      let NodeType = Nodes[ParentNode].type;
      if (TypeCloud.includes(NodeType)) {
        return ListFather;
      }
      x -= 1;
      if (x == 0) {
        console.log("Queboru loop");
        break;
      }
    }
  }
}

//Return ID of Region and Cloud above Node
function FindRegionAndCloud(Nodes, NodeID) {
  try {
    //console.log("TypeCloud", TypeCloud)
    if (TypeCloud.includes(Nodes[parseInt(NodeID)].type)) {
      return [NodeID, NodeID]
    } else {
      var Node = Nodes[parseInt(NodeID)];
      var NodeType = Node.type;
      var NodeID = Node.id;
      let x = 30;
      while (true) {
        //console.log("NodeType", NodeType);
        if (TypeRegion.includes(NodeType)) {
          let Region = NodeID;
          let Cloud = Node.parentNode;
          //console.log("AZResourceGroupN", Nodes[(parseInt(Cloud))].type)
          if (Nodes[(parseInt(Cloud))].type == "AZResourceGroupN") {
            let RG = Cloud;
            Cloud = Nodes[(parseInt(Cloud))].parentNode;
            return [Cloud, Region, RG];
          } else {
            return [Cloud, Region];
          }
        } else {
          Node = Nodes[parseInt(Node.parentNode)]
          try {
            NodeType = Node.type;
          } catch (error) {
            return [NodeID, NodeID]
          }
          NodeID = Node.id;
          x -= 1;
          if (x == 0) {
            //console.log("Queboru loop");
            return [NodeID, NodeID]
          }
        }
      }
    }
  } catch (error) {
    return [NodeID, NodeID]
  }
}

//Return ID of Region and Cloud above Node
function FindFiliationLevel(Nodes, NodeID) {
  var Node = Nodes[parseInt(NodeID)];
  var NodeFather = Nodes[parseInt(Node.parentNode)];
  var NodeFatherType = NodeFather.type;
  //console.log("NodeXXX", Node, NodeFather, NodeFatherType);
  if (TypeRegion.includes(Node.type)) {
    return 0;
  }
  let Level = 1;
  let i = 0;
  while (true) {
    if (TypeRegion.includes(NodeFatherType)) {
      return Level;
    } else {
      Level += 1;
      Node = NodeFather;
      NodeFather = Nodes[parseInt(Node.parentNode)];
      //console.log("NodeFather", NodeFather);
      NodeFatherType = NodeFather.type;
    }
    i += 1
    if (i == 20) {
      return 0;
    }
  }
}

function nextIPCIDR(ipCIDR, ListCIDR, MainCIDR = "") {
  //let PassAllList = false;
  let NextIPCIDR = FindnextIPCIDR(ipCIDR);
  //console.log("NextIPCIDR", NextIPCIDR)
  //console.log("MAinCIDR", MainCIDR)
  while (true) {
    for (let i = 0; i < ListCIDR.length; i++) {
      let FoundCIDR = true;
      for (let j = 0; j < ListCIDR.length; j++) {
        if (checkCIDROverlap(NextIPCIDR, ListCIDR[j])) {
          NextIPCIDR = FindnextIPCIDR(NextIPCIDR);
          //console.log("NextIPCIDR", NextIPCIDR)
          FoundCIDR = false;
          break;
        }
      }
      if (FoundCIDR) {
        return NextIPCIDR;
      }
    }
    //if ((MainCIDR == "") || (checkCIDROverlap(NextIPCIDR, MainCIDR) == false)) {
    //  break;
    //}
    if (MainCIDR == "") {
      break;
    }
    if (checkCIDROverlap(NextIPCIDR, MainCIDR) == false) {
      return NextIPCIDR;
    }
  }
  if (ListCIDR.length == 0) {
    return ipCIDR;
  } else {
    return "No CIDR available";
  }
}

function FindnextIPCIDR(ipCIDR, IncNumber = 1) {
  // Separa o IP do CIDR
  let cidr = ipCIDR.split("/")[1];
  let ip = ipCIDR.split("/")[0];
  let octets = ip.split(".");
  let SumOctets = getIpOctets(parseInt(cidr));
  // Converte os octetos para inteiros
  octets = octets.map(octet => parseInt(octet));
  // Calcula a máscara de rede
  let netmask = Array(4).fill(0);
  for (let i = 0; i < cidr; i++) {
    netmask[Math.floor(i / 8)] += Math.pow(2, 7 - (i % 8));
  }
  // Aplica a máscara de rede aos octetos do IP
  octets = octets.map((octet, index) => octet & netmask[index]);
  for (let i = 3; i >= 0; i--) {
    octets[i] += SumOctets[i];
    if (octets[i] > 255) {
      octets[i] = 0;
      octets[i - 1] += IncNumber; //checkIPCIDRConflict function no must inc
    }
  }
  // Monta o próximo IP como uma string
  let nextIP = octets.join(".");
  // Retorna o próximo IP/CIDR
  return `${nextIP}/${cidr}`;
}

function getIpOctets(cidr) {
  let octets = [];
  octets[0] = 0;
  octets[1] = 0;
  octets[2] = 0;
  octets[3] = 0;
  if ((cidr > 24) && (cidr < 33)) {
    octets[3] = Math.pow(2, (24 - cidr + 8))
  }
  if ((cidr > 16) && (cidr < 25)) {
    octets[2] = Math.pow(2, (16 - cidr + 8))
  }
  if ((cidr > 8) && (cidr < 17)) {
    octets[1] = Math.pow(2, (8 - cidr + 8))
  }
  if ((cidr > 0) && (cidr < 9)) {
    octets[0] = Math.pow(2, (0 - cidr + 8))
  }
  return octets;
}

function checkCIDROverlap(cidr1, cidr2) {
  // Extrai o endereço IP e o comprimento da máscara para cada bloco CIDR
  const [ip1, mask1] = cidr1.split('/');
  const [ip2, mask2] = cidr2.split('/');
  // Converte o endereço IP em um número inteiro de 32 bits
  const intIP1 = ipToNumber(ip1);
  const intIP2 = ipToNumber(ip2);
  // Converte o comprimento da máscara em um número inteiro
  const intMask1 = parseInt(mask1, 10);
  const intMask2 = parseInt(mask2, 10);
  // Calcula o intervalo de endereços IP para cada bloco CIDR
  const start1 = intIP1 & getMask(intMask1);
  const end1 = start1 + (~getMask(intMask1) >>> 0);
  const start2 = intIP2 & getMask(intMask2);
  const end2 = start2 + (~getMask(intMask2) >>> 0);
  // Verifica se há alguma sobreposição entre os intervalos de endereços IP
  return start1 <= end2 && end1 >= start2;
}
// Converte um endereço IP em um número inteiro de 32 bits
function ipToNumber(ip) {
  return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0) >>> 0;
}
// Obtém a máscara de sub-rede correspondente a um comprimento de máscara dado
function getMask(maskLength) {
  return (0xffffffff << (32 - maskLength)) >>> 0;
}
function ipToInt(ip) {
  let octets = ip.split(".");
  let result = 0;
  for (let i = 0; i < octets.length; i++) {
    result += octets[i] * Math.pow(256, octets.length - i - 1);
  }
  return result;
}

function cidrToInt(cidr) {
  let mask = 0;
  for (let i = 0; i < 32; i++) {
    if (i < cidr) {
      mask += Math.pow(2, 31 - i);
    }
  }
  return mask;
}

function dec2bin(dec) {
  return (dec >>> 0).toString(2);
}

function checkIPCIDRConflict(ipCIDR1, ipCIDR2) {
  let List = [];
  let newIp = "";
  let Dif = 0;
  let ip1 = ipCIDR1.split("/")[0];
  let cidr1 = ipCIDR1.split("/")[1];
  let ip2 = ipCIDR2.split("/")[0];
  let cidr2 = ipCIDR2.split("/")[1];
  if (cidr1 > cidr2) {
    let aux = cidr2;
    cidr2 = cidr1;
    cidr1 = aux;
    aux = ip2;
    ip2 = ip1;
    ip1 = aux;
  } else {
    if (cidr1 === cidr2) {
      List = [[ip2, cidr2]];
    } else {
    }
  }
  //console.log("ip1", ip1, "cidr1", cidr1, "ip2", ip2, "cidr2", cidr2)
  if (cidr1 !== cidr2) {
    let netmask = Array(4).fill(0);
    for (let i = 0; i < cidr2; i++) {//find the netmask of cidr2
      netmask[Math.floor(i / 8)] += Math.pow(2, 8 - (i % 8));
    }
    let result = 0;
    for (let i = 0; i < netmask.length; i++) {
      result += netmask[i] * Math.pow(256, netmask.length - i - 1);
    }
    netmask = result;

    //console.log("netmask", dec2bin(netmask));
    let IP2array = ip2.split(".");
    Dif = Math.pow(2, cidr2 - cidr1);
    ip2 = ipToInt(ip2.split("/")[0]) & netmask;
    let adder = Math.pow(2, (cidr2 - 32) * (-1));
    //console.log("adder", dec2bin(adder), cidr2, dec2bin(ip2));
    for (let i = 0; i < Dif; i++) {
      List.push([ip2, dec2bin(ip2)]);
      ip2 += adder;
    }
  }
  let Len = List.length;
  //console.log("list", List, Len);
  let Res = false;
  for (let j = 0; j < Len; j++) {
    // Converte os endereços IP para números inteiros
    ip2 = List[j]//ipToInt(ip1);
    let ip1Int = ipToInt(ip1);
    let mask2 = cidrToInt(cidr2);
    let mask1 = cidrToInt(cidr1);
    // Aplica a máscara de rede
    let network1 = ip1Int & mask1;
    let network2 = ip2 & mask2;
    // Verifica se os endereços de rede são iguais
    if (network1 === network2) {
      Res = true;
    }

  }
  return Res
}

//Return id of Father Node of node to be add. Return "0" if not permit
function PermitionToAddNode(NodeToBeAdd, NodeSelected, NodeData) {
  if (NodeData[NodeToBeAdd.slice(0, -1)].data.NodeFatherType.includes(NodeSelected.type)) {
    //Verifica se a lista em NodeFatherType inclui o node selecionado
    return NodeSelected.id;
  } else {
    if (NodeSelected.data.NodeFatherType == "NA") {//Ex.: CloudN e AZN
      return "0";
    }
    for (let k = 0; k < NodeSelected.data.NodeFatherType.length; k++) {//Percorre a lista de nodes que podem ser pai
      let NodeFatherType = NodeSelected.data.NodeFatherType[k].slice(0, -1);
      let ListChieldType = NodeData[NodeFatherType].data.ListChieldType;
      for (let i = 0; i < ListChieldType.length; i++) {
        if (ListChieldType[i] == NodeToBeAdd) {
          let NodeFatherID = NodeSelected.parentNode;
          return NodeFatherID;
        }
      }
      return "0";
    }
  }
}
function HandlerGeneral(NodeType, Func, nodes, NodeData) {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].selected == true) {
      let IDParent = PermitionToAddNode(NodeType, nodes[i], NodeData);
      if (IDParent != "0") {
        Func(IDParent);
        break
      }
    }
  }
}
function GetCIDRList(nodes, NodeType) {
  let CIDRList = [];
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].type == NodeType) {
      if (NodeType == "VPCN") {
        CIDRList.push(nodes[i].data.Param[3][2][1][1]);
      }
      if (NodeType == "SubnetN") {
        CIDRList.push(nodes[i].data.Param[7][1]);
      }
    }
  }
  return CIDRList;
}

function RemoveNodesFromList(OriginalListNodes, RemoveListId) {
  let RemoveListID = RemoveListId;
  RemoveListID.sort((a, b) => b - a);
  for (let i = 0; i < RemoveListID.length; i++) {
    for (let j = 0; j < OriginalListNodes.length; j++) {
      if (parseInt(OriginalListNodes[j].id) == RemoveListID[i]) {
        OriginalListNodes.splice(j, 1);
        break;
      }
    }
  }
  return OriginalListNodes;
}

function RemoveEdgesFromList(OriginalListEdges, RemoveListId) {

  let listaFinal = OriginalListEdges.filter(item => !RemoveListId.includes(item));
  //console.log("RemoveEdgesFromList", OriginalListEdges, RemoveListId)

  /*let RemoveListID = RemoveListId;
  RemoveListID.sort((a, b) => b - a);
  for (let i = 0; i < RemoveListID.length; i++) {
    let RemovedItem = parseInt(RemoveListID[i]);
    for (let j = 0; j < OriginalListEdges.length; j++) {
      if (parseInt(OriginalListEdges[j].id) == RemoveListID[i]) {
        OriginalListEdges.pop(RemovedItem - 1);
        break;
      }
    }
  }*/
  return listaFinal;
}

//Returm a list of nodes boxes could be father of the new node
//It calculates the absolute of this potential father box nodes
function ListBoxNodes(ListBoxNodesType, nodes) {
  console.log("ListBoxNodesType, nodes", ListBoxNodesType, nodes)
  var ListBox = [];
  let position = 0;
  for (let i = 1; i < nodes.length; i++) {
    if (ListBoxNodesType.includes(nodes[i].type)) {
      if (nodes[i].parentNode != 0) {
        position = JSON.parse(JSON.stringify(GetFatherPosition(nodes, nodes[i].parentNode, JSON.parse(JSON.stringify(nodes[i].position)))));
      } else {
        position = JSON.parse(JSON.stringify(nodes[i].position));
      }
      ListBox.push({
        "id": nodes[i].id, "x": position.x, "y": position.y,
        "height": nodes[i].style.height, "width": nodes[i].style.width, "type": nodes[i].type
      });

    }
  }
  return ListBox;
}

//Returm an IDs list of nodes potetially father, according position X,Y and ListBoxNodesType given
function findIntersectingNodesBox(nodes, position, ListBoxNodesType) {
  console.log("nodes, position, ListBoxNodesType", nodes, position, ListBoxNodesType)
  let intersectingRectangles = [];
  let rectangles = ListBoxNodes(ListBoxNodesType, nodes);
  rectangles.forEach((rectangle) => {
    if (
      position.x >= rectangle.x &&
      position.x <= rectangle.x + rectangle.width &&
      position.y >= rectangle.y &&
      position.y <= rectangle.y + rectangle.height
    ) {
      intersectingRectangles.push([rectangle.id, rectangle.type]);
    }
  });

  return intersectingRectangles;
}

function isS3BucketNameValid(bucketName) {
  // Bucket names must be between 3 and 63 characters long.
  if (bucketName.length < 3 || bucketName.length > 63) {
    return false;
  }

  // Bucket names must be made up of lowercase letters, numbers, and hyphens.
  if (!/^[a-z0-9-]+$/.test(bucketName)) {
    return false;
  }

  // Bucket names must start and end with a lowercase letter or a number.
  if (!/^[a-z0-9]/.test(bucketName) || !/[a-z0-9]$/.test(bucketName)) {
    return false;
  }

  // Bucket names cannot contain two adjacent hyphens.
  if (/--/.test(bucketName)) {
    return false;
  }

  return true;
}

function AjustEdgesNodesToSaveTotal(Edges, Nodes) {
  var NodesToDB = [];
  var EdgesToDB = [];
  var LookUp = {};
  let ContLookUp = 0;
  for (let i = 0; i < Nodes.length; i++) {//Start with 1, cause do not save the Config Node
    delete Nodes[i].data.ID;
    if (TypeRegion.includes(Nodes[i].type)) {
      Nodes[i].data.Param[2][3] = "-1" //Remover, apenas testes de Region
    }
    if (Nodes[i].type !== "NullN") {
      const NewNode = Nodes[i];//JSON.parse(JSON.stringify(Nodes[i]));
      NodesToDB.push(NewNode);
      LookUp[Nodes[i].id] = ContLookUp.toString();
      ContLookUp += 1;
    }
  }
  for (let i = 0; i < Edges.length; i++) {
    let NoSourceOrTarget = false;
    let X = Edges[i].source;
    let Y = Edges[i].target;
    //console.log("X,Y", X, Y)
    if (typeof X === "undefined" || typeof Y === "undefined") { NoSourceOrTarget = true; console.log("undefined") }
    if (Edges[i].source !== "null" && NoSourceOrTarget === false) {
      const NewEdge = Edges[i];//JSON.parse(JSON.stringify(Edges[i]));
      //console.log("NoSourceOrTarget", NoSourceOrTarget, NewEdge)
      EdgesToDB.push(NewEdge);
    }
  }
  for (let i = 0; i < NodesToDB.length; i++) {//ajusta parent node após remoção de nodes null do array
    let OldNodeID = NodesToDB[i].id;
    NodesToDB[i].id = i.toString();
    for (let j = 0; j < NodesToDB.length; j++) {
      if (NodesToDB[j].parentNode == OldNodeID) {
        NodesToDB[j].parentNode = i.toString();
      }
    }
    for (let j = 0; j < EdgesToDB.length; j++) {//ajusta edges após remoção de nodes null do array
      if (EdgesToDB[j].source == OldNodeID) {
        EdgesToDB[j].source = i.toString();
      }
      if (EdgesToDB[j].target == OldNodeID) {
        EdgesToDB[j].target = i.toString();
      }
    }
  }
  return [EdgesToDB, NodesToDB]
}

function AjustEdgesNodesToSavePartial(Edges, Nodes, RemoveConfig = true) {
  var NodesToDB = [];
  var NewEdges = [];
  //console.log("Nodes PArtial save", Edges, Nodes)
  for (let i = 0; i < Nodes.length; i++) {
    delete Nodes[i].data.ID;
    let IsGraph = Nodes[i].type === "GraphN";
    Nodes[i].selected = false;
    if (IsGraph) {
      Nodes[i].data.img = "";
      Nodes[i].data.MaxCounter = undefined;
    }
    const TypeTerraform = ["TerraformN", "TerraformBackendS3N"];
    if (TypeTerraform.includes(Nodes[i].type)) {
      Nodes[i].data.DeployingStateMachine = "Idle";
      Nodes[i].data.Compile = "";
      Nodes[i].data.TerraformExecStatus = "";
    }
  }
  for (let i = 0; i < Nodes.length; i++) {
    // Fazendo uma cópia profunda do objeto Nodes[i]
    const NewNode = JSON.parse(JSON.stringify(Nodes[i]));
    //console.log("NewNode", NewNode);
    // Usando delete para remover a propriedade 'positionAbsolute'
    delete NewNode.positionAbsolute;
    // Adicionando o NewNode modificado à lista NodesToDB
    NodesToDB.push(NewNode);
  }

  //console.log("NoesToDB partial", NodesToDB)
  if (RemoveConfig) {
    NodesToDB.shift();
  }
  //console.log("NoesToDB partial", NodesToDB)
  for (let i = 0; i < Edges.length; i++) {//remove edges com source ou tagets indefinidos
    let NoSourceOrTarget = false;
    let X = Edges[i].source;
    let Y = Edges[i].target;
    if (typeof X === "undefined" || typeof Y === "undefined") { NoSourceOrTarget = true; console.log("undefined") }
    if (Edges[i].source !== "null" && NoSourceOrTarget === false) {
      const NewEdge = JSON.parse(JSON.stringify(Edges[i]));
      NewEdges.push(NewEdge);
    }
  }
  let EdgesToDB = [];
  const MaxNodeID = NodesToDB.length;
  for (let i = 0; i < NewEdges.length; i++) {//Remove edges que estão apontando para nodes não existentes
    if (parseInt(NewEdges[i].source) > MaxNodeID || parseInt(NewEdges[i].target) > MaxNodeID) {
      //pass
    } else {
      EdgesToDB.push(NewEdges[i]);
    }
  }
  return [EdgesToDB, NodesToDB]
}

function uint8ArrayToBase64(buffer) {
  let binary = '';
  let len = buffer.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(buffer[i]);
  }
  return window.btoa(binary);
}
function Compress(originalData) {
  const compressedData = pako.deflate(originalData);
  const base64CompressedData = uint8ArrayToBase64(compressedData);
  const originalSize = new Blob([originalData]).size;
  const compressedSize = compressedData.byteLength;
  const reductionPercentage = ((originalSize - compressedSize) / originalSize) * 100;
  //console.log("Redução:", reductionPercentage.toFixed(2), "%");
  return base64CompressedData;
}

function Descompact(Descompact) {
  const compressedData = Uint8Array.from(atob(Descompact), c => c.charCodeAt(0));
  const decompressedData = pako.inflate(compressedData);
  const decompressedString = new TextDecoder().decode(decompressedData);
  const jsonData = JSON.parse(decompressedString);
  return [jsonData, decompressedString]
}

async function Save(Edges, Nodes) {
  console.log("Save")
  let NewNodes = JSON.parse(JSON.stringify(Nodes));
  let NewEdges = JSON.parse(JSON.stringify(Edges));
  //await SaveFull(NewEdges, NewNodes);
  //return
  if (GlobalSaveAll) {
    await SaveFull(NewEdges, NewNodes);
  } else {
    await SavePartial(NewEdges, NewNodes);
  }
  return
}

async function SavePartial(Edges, Nodes) {
  console.log("SavePartialWithPagination !!!!!!!!!!!!!");
  const UserName = document.getElementById("Login").value;
  let UpdateMap = {};
  let Changed = false;
  let pages = {};
  let fullSaveNeeded = false;

  // Verificação de edges duplicadas e ajuste de nodes e edges
  let NewEdges = TestDuplicateEdges(Edges);
  let Adjust = AjustEdgesNodesToSavePartial(NewEdges, Nodes);
  NewEdges = Adjust[0];
  Nodes = Adjust[1];

  const originalEdgesAndConfig = JSON.stringify([NewEdges, Configuration]);

  // Verifica se as edges ou a configuração mudaram
  console.log("Verificando se edges mudaram:", originalEdgesAndConfig !== LastSaved[0]);

  if (originalEdgesAndConfig !== LastSaved[0]) {
    const base64CompressedEdgesAndConfig = Compress(originalEdgesAndConfig);

    // Atualiza o mapa de mudanças com as edges (página 0)
    UpdateMap[0] = base64CompressedEdgesAndConfig;
    LastSaved[0] = originalEdgesAndConfig;
    Changed = true;

    // Garante que a página 0 seja atualizada
    if (!pages[0]) {
      pages[0] = [];
    }
    pages[0].push(0);  // Página 0 contém as edges
  }

  // Verificação dos nodes e preenchimento do UpdateMap
  for (let i = 0; i < Nodes.length; i++) {
    const currentPage = Math.floor((i + 1) / MaxItemPerPage);
    const Line = JSON.stringify(Nodes[i]);
    const LastSavedLine = LastSaved[i + 1] || "";

    if (Line !== LastSavedLine) {
      const base64CompressedData = Compress(Line);

      LastSaved[i + 1] = Line;
      UpdateMap[i + 1] = base64CompressedData;
      Changed = true;

      // Adiciona o item na página correspondente
      if (!pages[currentPage]) pages[currentPage] = [];
      pages[currentPage].push(i + 1);
    }
  }

  // Preparação das expressões de atualização para cada página
  let updateDataPerPage = {};
  for (const page in pages) {
    let expressionAttributeValues = {};
    let updateExpressionParts = [];
    let expressionAttributeNames = {};
    let totalExpressionSize = 0;

    // Monta a expressão de atualização para a página
    pages[page].forEach((key, index) => {
      const valueKey = `:val${index}`;
      expressionAttributeValues[valueKey] = { "S": UpdateMap[key] }; // Inclui o tipo 'S' para strings
      updateExpressionParts.push(`#${key} = ${valueKey}`);
      expressionAttributeNames[`#${key}`] = String(key);  // Certifique-se de que as chaves sejam strings

      // Calcular o tamanho total da expressão
      totalExpressionSize += key.length + valueKey.length + UpdateMap[key].length;
    });

    const updateExpression = "SET " + updateExpressionParts.join(", ");

    // Verifica se o tamanho da expressão excede o limite
    if (totalExpressionSize > 4096) {
      fullSaveNeeded = true;
      break;
    } else {
      updateDataPerPage[page] = {
        Sort: `${UserName}:${page}`, // Mantenha o Sort original
        updateExpression,
        expressionAttributeValues,
        expressionAttributeNames
      };
    }
  }

  // Se o tamanho exceder o limite, salva tudo
  if (fullSaveNeeded) {
    await SaveFull(Edges, Nodes);
  } else {
    // Envia todas as páginas atualizadas para a API
    if (Object.keys(updateDataPerPage).length !== 0) {
      const raw = [8, "NodesPartial", updateDataPerPage];
      await CallAPI(APIDB, raw, true);
    }
  }
}



async function SaveFull(Edges, Nodes, UserName = "", LastSavedIndex = "") {
  let LocalLastSaved;
  if (LastSavedIndex === "") {
    LocalLastSaved = LastSaved;
  } else {
    LocalLastSaved = LastSavedIndex;
  }
  console.log("SaveFull !!!!!!!!!!!!!");
  if (!UserName) {
    UserName = document.getElementById("Login").value;
  }
  Edges = TestDuplicateEdges(Edges);
  let Adjust = AjustEdgesNodesToSavePartial(Edges, Nodes); // Ajusta arestas e nodes
  Edges = Adjust[0];
  Nodes = Adjust[1];

  let pages = []; // Array para armazenar os ItemAttributes de cada página
  let ItemAttributes = {};
  const originalEdgesAndConfig = JSON.stringify([Edges, Configuration]);
  LocalLastSaved[0] = originalEdgesAndConfig;
  const base64CompressedEdgesAndConfig = Compress(originalEdgesAndConfig);

  // Adiciona Edges e Configuração na primeira página
  ItemAttributes["0"] = base64CompressedEdgesAndConfig; // Removido {'S': ...}

  let nodesPerPage = MaxItemPerPage;
  let page = 0;

  for (let i = 0; i < Nodes.length; i++) {
    const originalData = JSON.stringify(Nodes[i]);
    LocalLastSaved[i + 1] = originalData;
    const base64CompressedData = Compress(originalData);
    ItemAttributes[`${i + 1}`] = base64CompressedData; // Removido {'S': ...}

    // Verifica se atingiu o limite de nodes por página
    if ((i + 1) % nodesPerPage === 0) {
      console.log("page", page)
      // Armazena apenas os ItemAttributes no array de páginas
      pages.push(ItemAttributes);

      // Reseta os atributos para a próxima página e incrementa o contador de páginas
      ItemAttributes = {};
      page++;
    }
  }

  // Armazena a última página se houver atributos restantes
  if (Object.keys(ItemAttributes).length > 0) {
    pages.push(ItemAttributes);
  }

  // Envia todas as páginas para o back-end em uma única chamada
  const raw = [10, "NodesPartial", UserName, pages];
  console.log(`Enviando todas as páginas para o back-end de uma vez...`, raw);
  const response = await CallAPI(APIDB, raw, true);
  console.log("Save Full Response", response);

  GlobalSaveAll = false;
}

function ProcessLoadNew(LoadEdges, LoadNodes) {
  for (let i = 0; i < LoadNodes.length; i++) {
    const ParentNodeID = parseInt(LoadNodes[i].parentNode);
    if (ParentNodeID >= LoadNodes.length) {
      LoadNodes[i].parentNode = "1";
    }

  }
  console.log("ProcessLoadNew", LoadNodes, LoadEdges)
  let NewNodes = [];
  let NewEdges = [];
  const NewNode = JSON.parse(JSON.stringify(LoadNodes[0]));
  NewNode.id = "Config";
  NewNodes.push(NewNode)
  for (let i = 1; i < LoadNodes.length; i++) {
    let NodeID = i.toString();
    const NewNode = JSON.parse(JSON.stringify(LoadNodes[i]));
    NewNode.id = NodeID;
    if (NewNode.id == NewNode.parentNode) {
      console.log("ID = father !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    }
    if (Stage === "DevLocal") {
      NewNode.data.ID = NodeID;//usado para exibir o ID do node na renderização
    }
    NewNodes.push(NewNode);
    if (NewNode.data.LifeCycle === undefined) {
      NewNode.data.LifeCycle = { "IC": [], "CBD": false, "PD": false } //IC = IgnoreChanges, CBD = Create Before Destroy, PD = Prevent Destroy
    }
  }
  for (let i = 0; i < LoadEdges.length; i++) {
    let EdgeID = i.toString();
    const NewEdge = JSON.parse(JSON.stringify(LoadEdges[i]));
    NewEdge.id = EdgeID;
    NewEdge.type = "FloatingEdgeE";
    NewEdge.markerEnd = { type: MarkerType.ArrowClosed, width: 20, height: 20, color: '#000000', };
    NewEdge.sourceHandle = "right";
    NewEdge.targetHandle = "right";
    NewEdges.push(NewEdge);
  }
  console.log("LoadNodes ProcessLoadNew", NewNodes)
  GlobalNodeID = NewNodes.length;
  return [NewEdges, NewNodes]
}
function ProcessLoad(result) {
  //let NewNodes = [];
  //let NewEdges = [];
  const jsonData = Descompact(JSON.parse(result).body)[0];
  let LoadNodes = jsonData[0];
  let LoadEdges = jsonData[1];
  return ProcessLoadNew(LoadEdges, LoadNodes)
  /*const NewNode = JSON.parse(JSON.stringify(LoadNodes[0]));
  NewNode.id = "0";
  NewNodes.push(NewNode)
  for (let i = 1; i < LoadNodes.length; i++) {
    let NodeID = i.toString();
    const NewNode = JSON.parse(JSON.stringify(LoadNodes[i]));
    NewNode.id = NodeID;
    if (Stage === "DevLocal") {
      NewNode.data.ID = NodeID;
    }
    NewNodes.push(NewNode);
    if (NewNode.data.LifeCycle === undefined) {
      NewNode.data.LifeCycle = { "IC": [], "CBD": false, "PD": false } //IC = IgnoreChanges, CBD = Create Before Destroy, PD = Prevent Destroy
    }
  }
  for (let i = 0; i < LoadEdges.length; i++) {
    let EdgeID = i.toString();
    const NewEdge = JSON.parse(JSON.stringify(LoadEdges[i]));
    NewEdge.id = EdgeID;
    NewEdge.type = "FloatingEdgeE";
    NewEdge.markerEnd = { type: MarkerType.ArrowClosed, width: 20, height: 20, color: '#000000', };
    NewEdge.sourceHandle = "right";
    NewEdge.targetHandle = "right";
    NewEdges.push(NewEdge);
  }
  return [NewEdges, NewNodes]*/
}

function ListTerraformDomain(GlobalNodes, TerraformNode) {
  const NodeTFID = parseInt(TerraformNode.id);
  const NodeFather = TerraformNode.parentNode;
  //console.log("NodeFather", NodeFather)
  let MainList = FindNodesChieldID(GlobalNodes, NodeFather);
  //console.log("MainList", MainList)
  for (let j = 0; j < MainList.length; j++) {
    const NodeID = parseInt(MainList[j])
    const NodeType = GlobalNodes[NodeID].type;
    //console.log("NodeType", NodeType, NodeID, NodeTFID)
    if (TypeTerraform.includes(NodeType) && NodeID !== NodeTFID) {
      const SubNodeFather = GlobalNodes[NodeID].parentNode;
      const SubList = FindNodesChieldID(GlobalNodes, SubNodeFather);
      //console.log("SubList", SubList)
      const setB = new Set(SubList);
      // Filtra a lista A, removendo os itens que estão em B
      MainList = MainList.filter(item => !setB.has(item));
    }
  }
  const NodesAbove = FindRegionAndCloud(GlobalNodes, NodeFather);
  MainList = MainList.concat(NodesAbove);
  return MainList
}


function CheckIdenticalName(Edges, Nodes, Constraints, ListNodesID) {
  const ListExcluded = ["InfoN", "AZN", "AZAZN", "NullN", "DockerHubN", "RegionN", "CloudN", "GraphN", "TextN", "Discard"]
  let NameError = [];
  let ListProcessed = []
  for (let i = 1; i < Nodes.length; i++) {
    const NodeType = Nodes[i].type;
    let Icon = Constraints.IconsURL + NodeType.slice(0, -1) + ".png";
    if (ListExcluded.includes(NodeType) === false) {
      for (let j = 1; j < Nodes.length; j++) {
        if (ListExcluded.includes(Nodes[j].type) === false) {
          if ((i != j) && !(ListProcessed.includes(j)) && !((Nodes[i].type == "EC2N") && (Nodes[i].data.AS == true))) {
            if (NodeType === Nodes[j].type) {
              let [CloudA, RegionA] = FindRegionAndCloud(Nodes, Nodes[i].id);
              let [CloudB, RegionB] = FindRegionAndCloud(Nodes, Nodes[j].id);
              if (CloudA === CloudB) {
                //console.log("Nodes[i]", Nodes[i].type, Nodes[j].type)
                if (Nodes[i].data.Param[1][1] == Nodes[j].data.Param[1][1] && NodeType !== "InfoN") {
                  //console.log("Mesmo nome achado", Nodes[i].data.Param[1][1], i, j)
                  if ((NodeType === "SBoxN" && ((Nodes[i].data.Param[4][1] !== Nodes[j].data.Param[4][1]) || (Nodes[i].data.Param[1][1].toLowerCase() !== "static"))) ||
                    (NodeType === "VPCN" && (Nodes[i].data.Param[13][1] !== Nodes[j].data.Param[13][1])) || NodeType == "CodePipelineStageN") {
                    //pass
                    //console.log("é VPC ou BOX", NodeType)
                  } else {
                    NameError.push([i, j, Icon, Icon, "have the same name. Change the name of one of them."]);
                    ListProcessed.push(i);
                    //console.log("have the same name. Change the name of one of them.")
                  }
                }
              }
              if (NodeType === "SubnetN") {//check subnet cidr
                if (Nodes[i].data.Param[7][1] === Nodes[j].data.Param[7][1]) {
                  NameError.push([i, j, Icon, Icon, "have the same IPv4 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
                //console.log("Subnet IPv6", Nodes[j].data.Param[10][1])
                if ((Nodes[i].data.Param[10][1] === Nodes[j].data.Param[10][1]) && (Nodes[j].data.Param[10][1] !== "")) {
                  NameError.push([i, j, Icon, Icon, "have the same IPv6 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
              }
              if (NodeType === "VPCN") {//check subnet cidr
                if (Nodes[i].data.Param[3][2][1][1] === Nodes[j].data.Param[3][2][1][1]) {
                  NameError.push([i, j, Icon, Icon, "have the same IPv4 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
                if ((Nodes[i].data.Param[4][2][2][1] === Nodes[j].data.Param[4][2][2][1]) && (Nodes[j].data.Param[4][2][2][1] !== "")) {
                  NameError.push([i, j, Icon, Icon, "have the same IPv6 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
              }
            }
          }
        }
      }
    }

    const SameState = ListaStandard[ResourceLookUp[NodeType]]["GeneralParam"]["SameState"]
    if (SameState !== undefined) {
      /*console.log("SameState", SameState, NodeType)
      const Parts = SameState.split("|")
      console.log("Parts", Parts)
      for (let j = 0; j < Parts.length; j++) {
        const Targets = Parts[j].split(":");
        if (Targets[0].includes("Target")) {
          const List = SearchNodesTarget(Edges, Nodes, Nodes[i], Targets[1]);
          console.log("List Target", List)
          if (List.length > 0) {
            const NodeTargetID = List[0];
            console.log("ListNodeID", ListNodesID, i, NodeTargetID)
            if (ListNodesID.includes(String(i)) && (!ListNodesID.includes(String(NodeTargetID)))) {
              let IconTarget = Constraints.IconsURL + Nodes[NodeTargetID].type.slice(0, -1) + ".png";
              NameError.push([i, parseInt(NodeTargetID), Icon, IconTarget, "They must be in the same State."]);
            }
          }
        }
      }*/
      if (ListNodesID.includes(String(i))) {
        const ListNodes = SearchNodesByLevels(Edges, Nodes, Nodes[i], SameState);
        if (ListNodes.length > 0) {
          console.log("ListNodes level", ListNodes)
          for (let j = 0; j < ListNodes.length; j++) {
            if (!ListNodesID.includes(String(ListNodes[j]))) {
              let IconTarget = Constraints.IconsURL + Nodes[ListNodes[j]].type.slice(0, -1) + ".png";
              NameError.push([i, ListNodes[j], Icon, IconTarget, "They must be in the same State."]);
            }
          }
        }
      }
    }
  }
  console.log("NameError", NameError)
  return NameError;
}
//Corrge o ID incluindo os nodes = null (deletados). é enviado para complitação apenas o nodes não null e no retorno deve-se ajustar o ID incluindo os null
function AdjustID(ID, Nodes) {
  let NullCount = 0;
  for (let i = 1; i < Nodes.length; i++) {
    if (Nodes[i].type == "NullN") {
      NullCount += 1;
    }
    if (i == ID + NullCount) {
      return ID + NullCount;
    }
  }
  return ID;
}

function arraysAreEqual(arr1, arr2) {
  // Verifica se os arrays têm o mesmo comprimento
  if (arr1.length !== arr2.length) {
    return false;
  }
  // Compara os elementos dos arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  // Se passar por todas as verificações, os arrays são iguais
  return true;
}

function GetNodeId() {
  var ID = `${GlobalNodeID++}`
  //console.log("GetNodeId", ID);
  return [ID, false];
}

function UpDateGlobalNodeID(NewGlobalNodeID) {
  GlobalNodeID = NewGlobalNodeID;
}

function getUniqueKey(baseKey, obj) {
  let count = 1;
  let newKey = baseKey;
  while (obj.hasOwnProperty(newKey)) {
    newKey = baseKey + count;
    count++;
  }
  return newKey;
}
//Tranforma o bloco de dados Terraform em uma estrutura JSON
function ToJSONObject(input) {
  // Convertendo em linhas e removendo linhas vazias
  let lines = input.split("\n").filter(line => line.trim() !== '');
  let result = {};
  let stack = [result]; // Stack para lidar com objetos aninhados

  lines.forEach(line => {
    line = line.trim();
    if (line.endsWith('{')) {
      // Início de um novo objeto
      let key = line.split(' ')[0];
      let uniqueKey = getUniqueKey(key, stack[stack.length - 1]);
      let obj = {};
      stack[stack.length - 1][uniqueKey] = obj;
      stack.push(obj);
    } else if (line === '}') {
      // Fim de um objeto
      stack.pop();
    } else {
      // Par chave=valor
      let [key, value] = line.split('=').map(item => item.trim());
      let uniqueKey = getUniqueKey(key, stack[stack.length - 1]);
      // Removendo aspas, se houver
      console.log("key", key)
      if (value && value.startsWith('"') && value.endsWith('"')) {
        value = value.slice(1, -1);
      } else if (value && value.startsWith('[') && value.endsWith(']')) {
        // Lidando com listas
        value = value.slice(1, -1).split(',').map(item => item.trim());
      }
      stack[stack.length - 1][uniqueKey] = value;
    }
  });

  return result;
}


//Extrai o bloco de dados de um recurso Terraform de um arquivo TF (tfContent). ResourceType é o typo de recursos Terraofo
function ExtractTerraformResourceBlock(tfContent, ResourceName, ResourceType) {
  // Constrói uma expressão regular para encontrar o início do recurso.
  const pattern = new RegExp(`resource\\s+"${ResourceType}"\\s+"${ResourceName}"\\s*\\{`);
  const match = pattern.exec(tfContent);
  if (!match) {
    return null;  // Retorna null se o recurso não for encontrado.
  }
  const startIndex = match.index;
  let openBraces = 1; // Já que começamos logo após a chave de abertura.
  let i = startIndex + match[0].length; // Começa a iteração após a chave de abertura.
  // Itera sobre o conteúdo a partir da posição de início.
  while (i < tfContent.length) {
    if (tfContent[i] === '{') {
      openBraces++;
    } else if (tfContent[i] === '}') {
      openBraces--;
    }
    // Se o contador chegar a zero, encontramos o final do bloco.
    if (openBraces === 0) {
      break;
    }
    i++;
  }
  // Retorna a substring do recurso encontrado, excluindo as chaves { e }.
  return tfContent.substring(startIndex + match[0].length, i).trim();
}

function formatTimestamp(timestamp, isLongPeriod) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  if (isLongPeriod) {
    return `${day}/${month} ${hours}h`;
  } else {
    return `${hours}:${minutes}`;
  }
}


/*const fetchCloudWatchMetric = async ({ RoelARN, region, params }) => {
  // Criar um objeto AWS.CloudWatch
  const cloudwatch = new AWS.CloudWatch({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region
  });
  console.log("Params", params, region);
  console.log("accessKeyId", accessKeyId, secretAccessKey);
  const response = await cloudwatch.getMetricData(params).promise();
  // Log the MetricDataResults for inspection
  console.log("Complete CloudWatch Response:", response);
  let formattedData = [];
  if (response.MetricDataResults && response.MetricDataResults.length > 0) {
    response.MetricDataResults.forEach((result, metricIndex) => {
      const timestamps = result.Timestamps;
      const values = result.Values;
      // Use the metric name as the ID, or you can assign custom IDs if you prefer
      const metricId = result.Id.replace('query_', '');
      const isLongPeriod = (timestamps[0] - timestamps[timestamps.length - 1]) > (24 * 60 * 60 * 1000);  // Verifica se o período é maior que um dia
      const metricData = {
        id: metricId,
        data: timestamps.map((timestamp, index) => {
          const formattedTime = formatTimestamp(timestamp, isLongPeriod);
          return { x: formattedTime, y: values[index] };
        }).reverse()
      };
      formattedData.push(metricData);
    });
  }
  return formattedData;
}*/



async function FindDimensionValue(Dimension, TagValue) {
  const ec2 = new AWS.EC2();
  const params = {
    Filters: [
      {
        Name: 'tag:Name',
        Values: [TagValue]
      }
    ]
  };

  if (Dimension.includes("InstanceId")) {
    const response = await ec2.describeInstances(params).promise();
    const instances = response.Reservations.flatMap(reservation => reservation.Instances);
    return instances.map(instance => instance.InstanceId);
  }

  throw new Error(`Dimension ${Dimension} not supported`);
}



function mockGetCostAndUsage(params) {
  return new Promise((resolve, reject) => {
    const mockData = {

      "ResultsByTime": [
        {
          "TimePeriod": {
            "Start": "2023-10-01",
            "End": "2023-10-31"
          },
          "Groups": [
            {
              "Keys": ["Cloudman_State_Tag$State1"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "100.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Cloudman_State_Tag$State2"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "150.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Name$EC2"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "50.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Name$RDS"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "60.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Name$EC2B"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "70.00",
                  "Unit": "USD"
                }
              }
            }
          ],
          "Total": {
            "BlendedCost": {
              "Amount": "430.00",
              "Unit": "USD"
            }
          }
        }
      ]
    };
    resolve(mockData);
  });
}
//Le CostExplorer e salva a resposta em Cloud[8][1] com os dados lidos e o date-time
const getCosts = async (tagKey, tagValue, accessKeyId, secretAccessKey, startDate, endDate, CloudNode) => {
  const costExplorer = new AWS.CostExplorer({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: 'us-east-1'  // ou a região de sua preferência
  });
  const params = {
    TimePeriod: {
      Start: "2023-10-01", // startDate,
      End: "2023-10-30"   // endDate,
    },
    Granularity: 'MONTHLY',
    Metrics: ['BlendedCost'],
    GroupBy: [
      { Type: "TAG", Key: "Name" }
    ]
  };
  console.log("Cost Explorer:", accessKeyId, secretAccessKey, params)
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Os meses começam de 0 (janeiro) até 11 (dezembro)
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
  let allResults = [];
  let Time = getAmericanDateTime();
  try {
    do {
      const data = await costExplorer.getCostAndUsage(params).promise();
      // Adicione os resultados desta página ao array allResults
      allResults.push(...data.ResultsByTime);
      console.log("Dados lidos", [formattedDate, data]);
      // Se houver um NextPageToken, defina-o nos parâmetros para a próxima chamada
      console.log("data.NextPageToken", data.NextPageToken)
      params.NextPageToken = data.NextPageToken;
    } while (params.NextPageToken);  // Continue enquanto houver um NextPageToken
    return [true, [Time, allResults]];
  } catch (err) {
    if (err.code === 'AccessDeniedException') {
      console.error("Erro ao buscar custos: Acesso negado.");
    } else {
      console.error("Erro ao buscar custos:", err.message);
    }
    return [false, ""]; // Retorne algo específico em caso de erro, como `null`.
  }
}

//ReadCloud é usada para ler qualquer recurso de um cloud provider. Pode ser usado para ler diretamente com as credencias de leitura
//ou chamar o backend, caso se opte por usar as credenciais salvas no back end (futura implementação)
async function ReadCloud(Nodes, FunctionType, SourceID, Params) {
  const [Cloud, Region] = FindRegionAndCloud(Nodes, SourceID);
  const CloudID = parseInt(Cloud);
  const accessKeyId = Nodes[CloudID].data.Param[5][1];
  const secretAccessKey = Nodes[CloudID].data.Param[6][1];
  console.log("Params Key", Params.Key)
  if (FunctionType == "GetCurrentCosts") {
    return await getCosts(Params.Key, Params.Value, accessKeyId, secretAccessKey, Params.StartDate, Params.EndDate, Nodes[CloudID]);
  }
  if (FunctionType == "GetStaticCosts") {
    return await getCosts(Params.Key, Params.Value, accessKeyId, secretAccessKey, Params.StartDate, Params.EndDate, Nodes[CloudID]);
  }
}
const getCurrentMonthDates = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  return {
    startDate: startDate.toISOString().split('T')[0],  // formato 'yyyy-mm-dd'
    endDate: endDate.toISOString().split('T')[0]       // formato 'yyyy-mm-dd'
  };
}

function GetCostFromCostExporerResponse(response, key, value) {
  // Verifica se a resposta é válida
  //console.log("response", response)
  if (!Array.isArray(response)) {
    throw new Error('Resposta inválida do Cost Explorer.');
  }
  // Itera sobre os resultados para encontrar a combinação de chave-valor
  for (let timeResult of response) {
    for (let group of timeResult.Groups) {
      if (group.Keys.includes(key + '$' + value)) {
        return parseFloat(group.Metrics.BlendedCost.Amount);
      }
    }
  }
  // Se não encontrar a combinação de chave-valor, retorna null
  return null;
}

function getAmericanDateTime() {
  const now = new Date();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Os meses começam de 0 (janeiro) até 11 (dezembro)
  const day = String(now.getDate()).padStart(2, '0');
  const year = now.getFullYear();
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  return `${month}/${day}/${year} ${hour}:${minute}`;
}

function FindNameIDRouting(Edges, Nodes, FirstNodeID, RoutingList) {
  let CurrentNodeID = FirstNodeID;
  let Name = "";
  for (let i = 0; i < RoutingList.length; i++) {
    let RoutingNodeType = RoutingList[i][0];
    let Routing = RoutingList[i][1];
    let List = [];
    if (Routing === "Source") {
      List = SearchNodesSource(Edges, Nodes, Nodes[CurrentNodeID], RoutingNodeType);
    } else {
      List = SearchNodesTarget(Edges, Nodes, Nodes[CurrentNodeID], RoutingNodeType);
    }
    if (List.length > 0) {
      CurrentNodeID = parseInt(List[0], 10);
      Name = Nodes[CurrentNodeID].data.Param[1][1];
    } else {
      return [FirstNodeID, ""];
    }
  }
  return [CurrentNodeID, Name];
}

function awsUrlEncode(str) {
  return encodeURIComponent(str)
    .replace(/%2F/g, '*2f')    // '/' para '*2f'
    .replace(/%2C/g, '*2c')    // ',' para '*2c'
    .replace(/%7B/g, '*7b')    // '{' para '*7b'
    .replace(/%7D/g, '*7d')    // '}' para '*7d'
    .replace(/%20/g, '*20')    // ' ' para '*20'
    .replace(/%3A/g, '*3A')    // ':' para '*3A'
    .replace(/%27/g, "'")      // Apostrofo permanece como '
    .replace(/%21/g, '!')      // '!' permanece como '!'
    .replace(/%28/g, '(')      // '(' permanece como '('
    .replace(/%29/g, ')')      // ')' permanece como ')'
    .replace(/%7E/g, '~')      // '~' permanece como '~'
    .replace(/%2A/g, '*')      // '*' permanece como '*'
    .replace(/%/g, '*');        // Qualquer '%' restante é substituído por '*'
}
/*
function generateCloudWatchUrl(GlobalEdges, GlobalNodes, LocalNodeID) {
  console.log("Entrando em generateCloudWatchUrl para NodeID:", LocalNodeID);

  const X = getMetricsParams(GlobalEdges, GlobalNodes, LocalNodeID);
  console.log("Resultado de getMetricsParams:", X);

  if (X && X.MetricsByNamespace) {
    const region = X.RegionName;
    const statistic = 'Average'; // Ajuste conforme necessário
    const period = X.Period; // Utilizando o valor de X.Period

    console.log("MetricsByNamespace:", X.MetricsByNamespace);

    // Inicializa um array para conter todas as definições de métricas
    let allMetrics = [];
    let firstNamespace = null;
    let firstDimensions = null;

    // Itera sobre os namespaces
    Object.keys(X.MetricsByNamespace).forEach(namespace => {
      const metricsArray = X.MetricsByNamespace[namespace];
      console.log(`Processando namespace: ${namespace}, metricsArray:`, metricsArray);

      // Limita a 4 métricas
      metricsArray.slice(0, 4).forEach((metric, index) => {
        const metricName = metric.Metric;
        const dimensions = metric.Dimensions;
        console.log(`Processando métrica: ${metricName}, dimensões:`, dimensions);

        // Armazena o primeiro namespace e dimensions para o queryParam
        if (!firstNamespace) {
          firstNamespace = namespace;
        }
        if (!firstDimensions && dimensions.length > 0) {
          firstDimensions = dimensions;
        }

        // Constrói a definição da métrica com apóstrofo antes de cada token
        let metricDef = [`'${namespace}`, `'${metricName}`];

        dimensions.forEach(dimension => {
          metricDef.push(`'${dimension.Name}`);
          metricDef.push(`'${dimension.Value}`);
        });

        // Adiciona o 'id' à métrica
        const idDef = `(id~'m${index + 1})`;

        // Envolve a métrica com parênteses e separa os elementos com '~'
        const metricExpression = `(~${metricDef.join('~')}~${idDef})`;
        allMetrics.push(metricExpression);
      });
    });

    console.log("Todas as expressões de métricas:", allMetrics);

    if (allMetrics.length === 0) {
      console.log("Nenhuma métrica encontrada para construir a URL.");
      return null;
    }

    // Construir a estrutura correta com til extra antes das métricas
    const graphObject = `~(metrics~(~${allMetrics.join('')})~view~'timeSeries` +
      `~stacked~false~region~'${region}` +
      `~stat~'${statistic}` +
      `~period~${period})`;

    console.log("Parâmetro 'graph' antes da codificação:", graphObject);

    // Codifica o parâmetro 'graph' usando a função awsUrlEncode
    const encodedGraphParam = awsUrlEncode(graphObject);

    console.log("Parâmetro 'graph' final:", encodedGraphParam);
    // Retorna o parâmetro 'graph' codificado e o 'queryParam' concatenados
    return `${encodedGraphParam}`;

  } else {
    console.log("Parâmetros de métricas inválidos retornados de getMetricsParams.");
    return null;
  }
}*/

function generateCloudWatchUrl(GlobalEdges, GlobalNodes, LocalNodeID) {
  console.log("Entrando em generateCloudWatchUrl para NodeID:", LocalNodeID);

  const X = getMetricsParams(GlobalEdges, GlobalNodes, LocalNodeID);
  console.log("Resultado de getMetricsParams:", X);

  if (X && X.MetricsByNamespace) {
    const region = X.RegionName;
    const period = X.Period; // Utilizando o valor de X.Period

    // Cálculo de startDuration baseado em X.StartTime
    let startDuration = '-PT6H'; // Padrão
    if (X.StartTime) {
      try {
        // Parse do StartTime como Date
        const startTimeLocal = new Date(X.StartTime);
        if (isNaN(startTimeLocal)) {
          throw new Error(`X.StartTime inválido: ${X.StartTime}`);
        }

        // Converter StartTime para UTC
        const startTimeUTC = new Date(startTimeLocal.toISOString());

        // Obter o tempo atual em UTC
        const endTimeUTC = new Date();

        // Calcular a diferença em milissegundos
        const diffMs = endTimeUTC - startTimeUTC;

        if (diffMs <= 0) {
          console.warn(`X.StartTime (${X.StartTime}) está no futuro ou igual a endTime. Usando padrão '-PT6H'.`);
        } else {
          // Converter diferença para horas
          const diffHours = diffMs / (1000 * 60 * 60);

          // Arredondar para a hora mais próxima
          const roundedHours = Math.round(diffHours);

          // Garantir que a duração mínima seja de 1 hora
          const finalHours = roundedHours > 0 ? roundedHours : 1;

          startDuration = `-PT${finalHours}H`;
        }
      } catch (error) {
        console.warn(`Erro ao processar X.StartTime: ${error.message}. Usando padrão '-PT6H'.`);
      }
    }

    const endDuration = 'P0D'; // Sempre 'P0D' representando o momento atual
    const resourceName = X.ResourceName && typeof X.ResourceName === 'string' ? X.ResourceName : 'DefaultResourceName';

    console.log("MetricsByNamespace:", X.MetricsByNamespace);

    // Inicializa um array para conter todas as definições de métricas
    let allMetrics = [];

    // Itera sobre os namespaces
    Object.keys(X.MetricsByNamespace).forEach(namespace => {
      const metricsArray = X.MetricsByNamespace[namespace];
      console.log(`Processando namespace: ${namespace}, metricsArray:`, metricsArray);

      if (metricsArray.length === 0) return; // Pula se não houver métricas

      // Itera pelas métricas, limitando a 4
      metricsArray.slice(0, 4).forEach((metric, index) => {
        const metricName = metric.Metric;
        const dimensions = metric.Dimensions;
        const validStat = metric.ValidStatistics;
        console.log(`Processando métrica: ${metricName}, dimensões:`, dimensions, `ValidStatistics: ${validStat}`);

        let metricDef = [];
        if (index === 0) {
          // Primeira métrica no grupo, inclui namespace e dimensões
          metricDef.push(`'${namespace}`);
          metricDef.push(`'${metricName}`);
          dimensions.forEach(dimension => {
            metricDef.push(`'${dimension.Name}`);
            metricDef.push(`'${dimension.Value}`);
          });
        } else {
          // Métricas subsequentes, substitui namespace e dimensões por '.'
          metricDef.push(`'.`);
          metricDef.push(`'${metricName}`);
          // Substitui cada par Nome/Valor das dimensões por '.'
          dimensions.forEach(() => {
            metricDef.push(`'.`);
            metricDef.push(`'.`);
          });
        }

        // Validação de ValidStatistics
        const validStatsAllowed = ['Average', 'Maximum', 'Minimum', 'Sum', 'SampleCount'];
        const stat = validStatsAllowed.includes(validStat) ? validStat : 'Average';
        if (!validStatsAllowed.includes(validStat)) {
          console.warn(`ValidStatistics inválido para a métrica ${metricName}: ${validStat}. Usando 'Average'.`);
        }

        // Adiciona o 'id' e 'stat' à métrica
        const idDef = `(id~'m${index + 1}~stat~'${stat})`;

        // Envolve a métrica com parênteses e separa os elementos com '~'
        const metricExpression = `(~${metricDef.join('~')}~${idDef})`;

        allMetrics.push(metricExpression);
      });
    });

    console.log("Todas as expressões de métricas:", allMetrics);

    if (allMetrics.length === 0) {
      console.log("Nenhuma métrica encontrada para construir a URL.");
      return null;
    }

    // Construir a estrutura correta do 'graphObject' sem parênteses extras
    const metricsExpression = `(~${allMetrics.join('~')})`;
    // Inclui os parâmetros globais: view, stacked, region, period, start, end, title
    const graphObject = `~(metrics~${metricsExpression}~view~'timeSeries~stacked~false~region~'${region}~period~${period}~start~'${startDuration}~end~'${endDuration}~title~'${resourceName})`;
    console.log("Parâmetro 'graph' antes da codificação:", graphObject);
    // Codifica o parâmetro 'graph' usando a função awsUrlEncode
    const encodedGraphParam = awsUrlEncode(graphObject);
    console.log("Parâmetro 'graph' final:", encodedGraphParam);
    // Retorna o parâmetro 'graph' codificado e o 'queryParam' concatenados
    return `${encodedGraphParam}}`;

  } else {
    console.log("Parâmetros de métricas inválidos retornados de getMetricsParams.");
    return null;
  }
}









function openConsole(GlobalEdges, GlobalNodes, LocalNodeID, NodeType, ConsoleLinkResource) {
  try {
    if (ConsoleLinkResource !== null) {
      let ConsoleUrl = ConsoleLinkResource;
      const NodesWithSufix = AdjustSufixName(GlobalEdges, GlobalNodes, LocalNodeID, true);
      let NodeName = NodesWithSufix.data.Param[1][1];
      if (NodeType == "SSMParameterN") { NodeName = GlobalNodes[LocalNodeID].data.Param[2][1]; }
      const NodeNameB = GlobalNodes[LocalNodeID].data.Param[2][1];
      let CloudID, RegionID, RG, RGName, RegionName = "";
      if (NodeType.startsWith("AZ")) {
        if (NodeType === "AZResourceGroupN") {
          RG = GlobalNodes[LocalNodeID].id;
          CloudID = GlobalNodes[LocalNodeID].parentNode;
          //ConsoleUrl = ConsoleUrl.replace("${Cons}", "portal.azure.com/#");
        } else {
          [CloudID, RegionID, RG] = FindRegionAndCloud(GlobalNodes, LocalNodeID);
        }
        const User = "cloudmanpro111gmail";
        RGName = GlobalNodes[parseInt(RG)].data.Param[1][1];
        ConsoleUrl = ConsoleUrl.replace("${User}", User);
        ConsoleUrl = ConsoleUrl.replace("${RGName}", RGName);

      } else { //AWS
        [CloudID, RegionID] = FindRegionAndCloud(GlobalNodes, LocalNodeID);
        RegionName = GlobalNodes[parseInt(RegionID)].data.Param[2][2];
        if (NodeType === "ACMN") {
          let TargetList = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[LocalNodeID], "CFDistributionN");
          if (TargetList.length > 0) {
            RegionName = 'us-east-1';
          }
        }
        console.log("RegionID", RegionID, RegionName)
        ConsoleUrl = ConsoleUrl.replace("${Region}", RegionName).replace("${Region}", RegionName);
        ConsoleUrl = ConsoleUrl.replace("${Cons}", "console.aws.amazon.com");
      }
      let Account = GlobalNodes[parseInt(CloudID)].data.Param[3][1];
      ConsoleUrl = ConsoleUrl.replace("${Account}", Account);
      ConsoleUrl = ConsoleUrl.replace("${NameB}", NodeNameB);
      console.log("NodeType", NodeType)
      if (NodeType == "ECSServiceN") {
        let List = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[LocalNodeID], "ECSClusterN");
        let ClusterID = parseInt(List[0]);
        let ClusterName = GlobalNodes[ClusterID].data.Param[1][1];
        console.log("NodeType", NodeType, List, ClusterName)
        ConsoleUrl = ConsoleUrl.replace("${ClusterName}", ClusterName);
      }
      if (NodeType === "GraphN") {
        const encodedGraphParam = generateCloudWatchUrl(GlobalEdges, GlobalNodes, LocalNodeID);
        ConsoleUrl += `${encodedGraphParam}`;
        console.log("encodedGraphParam", encodedGraphParam)
      }
      ConsoleUrl = "https://" + ConsoleUrl.replace("${Name}", NodeName);
      console.log("ConsoleUrlB", ConsoleUrl)
      console.log("NodeName", NodeName)
      window.open(ConsoleUrl, LocalNodeID);



    }
  } catch (error) {
    //console.log("erro")
  }
}

function compareMetricDataQueries(metricDataQueries) {
  for (let i = 0; i < metricDataQueries.length; i++) {
    for (let j = i + 1; j < metricDataQueries.length; j++) {
      if (areMetricDataQueriesEqual(metricDataQueries[i], metricDataQueries[j])) {
        return { Equal: true, Index1: i + 1, Index2: j + 1 };
      }
    }
  }
  return { Equal: false };
}

function areMetricDataQueriesEqual(query1, query2) {
  return query1.MetricStat.Metric.MetricName === query2.MetricStat.Metric.MetricName &&
    query1.MetricStat.Stat === query2.MetricStat.Stat &&
    JSON.stringify(query1.MetricStat.Metric.Dimensions) === JSON.stringify(query2.MetricStat.Metric.Dimensions);
}

async function findInstancesByTags(tagValues, accessKeyId, secretAccessKey, RegionName) {
  // Configura as credenciais e a região da AWS
  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: RegionName
  });
  const ec2 = new AWS.EC2();
  const params = {
    Filters: [
      {
        Name: 'tag:Name',
        Values: tagValues,
      },
    ],
  };
  const response = await ec2.describeInstances(params).promise();
  console.log("response", response);
}

function TestDuplicateEdges(Edges) {
  let NewEdges = [];
  let ListEdges = [];
  for (let i = 0; i < Edges.length; i++) {
    let EdgePair = Edges[i].source + ":" + Edges[i].target;
    if (ListEdges.includes(EdgePair)) {
      console.log("Encontrou Edge duplicada.", EdgePair, ListEdges)
    } else {
      ListEdges.push(EdgePair);
      NewEdges.push(Edges[i]);
    }

  }
  return NewEdges;
}

function CalculateScale(width, ScaleMax) {
  let scale;
  scale = 0.12 + (width * 0.0002);
  if (scale > ScaleMax) { scale = ScaleMax }

  return scale;
}

function FindIPv6BlockIndex(Nodes, SubnetListID) {
  let ListIPv6_Block_Index = [];
  for (let i = 0; i < SubnetListID.length; i++) {
    let IPv6_Block_Index = Nodes[SubnetListID[i]].data.Param[16][1];
    console.log("IPv6_Block_Index", IPv6_Block_Index, i)
    ListIPv6_Block_Index.push(parseInt(IPv6_Block_Index));
  }
  console.log("ListIPv6_Block_Index", ListIPv6_Block_Index)
  ListIPv6_Block_Index.sort(function (a, b) {
    return a - b;
  });
  let NewIndex = 0;
  for (let i = 0; i < ListIPv6_Block_Index.length + 1; i++) {
    console.log("i", i)
    if (ListIPv6_Block_Index[i] !== i) {
      NewIndex = i.toString();
      break;
    }
  }
  return NewIndex;
}

function findEdgeLabel(edges, sourceID, targetID) {
  for (let i = 0; i < edges.length; i++) {
    if (edges[i].source === String(sourceID) && edges[i].target === String(targetID)) {
      try {
        let label = edges[i]["label"];
        let ctrl = "";
        let labelInfo = "";
        if (label.includes("|")) {
          const parts = label.split("|");
          ctrl = parts[0].trim();
          labelInfo = parts[1];
        } else {
          labelInfo = label;
        }
        return { ctrl, labelInfo };
      } catch {
        return { ctrl: "", labelInfo: "" };
      }
    }
  }
  return { ctrl: "", labelInfo: "" };
}

function AdjustSubnetCIDR(edges, nodes, VPCID) {
  const CIDRIndex = 7;
  console.log("VPCID", VPCID, nodes[VPCID])
  let NodeList = FindNodesEdgesChield(nodes, edges, nodes[VPCID])[0];
  //console.log("NodeList", NodeList);
  let VPCCIDR = nodes[VPCID].data.Param[3][2][1][1];
  //console.log("VPCCIDR", VPCCIDR, VPCCIDRRange)
  let ListSubnetCIDR = [];
  let SubnetList = [];
  //console.log("SubnetCIDR inicial", SubNetCIDR)
  for (let i = 0; i < NodeList.length; i++) {
    let NodeType = NodeList[i].type;
    if (TypeSubnet.includes(NodeType)) {
      let NodeID = parseInt(NodeList[i].id);
      let SubnetCIDR = nodes[NodeID].data.Param[CIDRIndex][1]
      ListSubnetCIDR.push(SubnetCIDR)
      let Overlap = checkCIDROverlap(SubnetCIDR, VPCCIDR)
      console.log("NodeType", NodeType, NodeID, SubnetCIDR, Overlap)
      if (!Overlap || ListSubnetCIDR.includes(SubnetCIDR)) {
        let CIDRBase = VPCCIDR.split("/")[0] + "/" + SubnetCIDR.split("/")[1]
        nodes[NodeID].data.Param[CIDRIndex][1] = CIDRBase;
        SubnetList.push(NodeID);
      }
      /*for (let j = 0; j < nodes.length; j++) {
        if (nodes[j].id === NodeID) {
          let SubnetCIDR = nodes[j].data.Param[CIDRIndex][1]
          console.log("CIDR Subnet", SubnetCIDR)
          let Overlap = checkCIDROverlap(SubnetCIDR, VPCCIDR)
          if (!Overlap) {
            nodes[j].data.Param[CIDRIndex][1] = "0.0.0.0/0";
            SubnetList.push(j);
          }
        }
        break;
      }*/

    }
  }
  ListSubnetCIDR = [];
  console.log("SubnetList", SubnetList)
  for (let i = 0; i < SubnetList.length; i++) {
    let SubNetCIDR = nodes[SubnetList[i]].data.Param[CIDRIndex][1];
    let NewSubnetCidr = nextIPCIDR(SubNetCIDR, ListSubnetCIDR);
    ListSubnetCIDR.push(NewSubnetCidr);
    nodes[SubnetList[i]].data.Param[CIDRIndex][1] = NewSubnetCidr;
    console.log("New CIDR", SubNetCIDR, NewSubnetCidr, ListSubnetCIDR)
  }
}

function MaskString(str) {
  if (str.length > 4) {
    let start = str.substring(0, 1);
    let end = str.substring(str.length - 3);
    let masked = '*'.repeat(str.length - 4);
    return start + masked + end;
  }
  return str;
}

function SearchSubnetHead(Edges, Nodes, Index) {
  let ListSubnet = SearchNodesSource(Edges, Nodes, Nodes[Index], "AZSubnetN");
  if (ListSubnet.length === 0) {
    return Index
  } else {
    let SubnetIDHead;
    let SubnetID0 = parseInt(ListSubnet[0]);
    SubnetIDHead = SearchSubnetHead(Edges, Nodes, parseInt(SubnetID0))
    console.log("SubnetIDHead", SubnetIDHead)
    return SubnetIDHead
  }
}
function SearchSecurityGroupHead(Edges, Nodes, Index) {
  let ListSubnet = SearchNodesSource(Edges, Nodes, Nodes[Index], "SecurityGroupN");
  if (ListSubnet.length === 0) {
    return Index
  } else {
    let SubnetIDHead;
    let SubnetID0 = parseInt(ListSubnet[0]);
    SubnetIDHead = SearchSecurityGroupHead(Edges, Nodes, parseInt(SubnetID0))
    console.log("SGIDHead", SubnetIDHead)
    return SubnetIDHead
  }
}

function UpdateDataNodes(Nodes) {
  for (let i = 0; i < Nodes.length; i++) {
    let NodeParam = Nodes[i].data.Param;
    let NodeType = Nodes[i].type;
    let LSParam = ListaStandard[ResourceLookUp[NodeType]].ListParam;
    let LSParamLength = LSParam.length + 1;
    console.log("NodeParam", NodeType, NodeParam.length, LSParamLength);
    let Dif = LSParamLength - NodeParam.length;
    if (LSParamLength > NodeParam.length) {
      for (let j = 0; j < Dif; j++) {
        let Index = LSParamLength - Dif + j - 1;
        let NewParam = [LSParam[Index].Index, LSParam[Index].Value, "", ""];
        Nodes[i].data.Param.push(NewParam)
        console.log("NewParem", NewParam)
      }
      console.log("New NodeParam", NodeType, Nodes[i]);
    }
    if (Nodes[i].data.LifeCycle === undefined) {
      Nodes[i].data.LifeCycle = { "IC": [], "CBD": false, "PD": false } //IC = IgnoreChanges, CBD = Create Before Destroy, PD = Prevent Destroy
    }
  }
  return Nodes
}
function IsEC2AutoScale(Edges, Nodes, Index) {
  let ASGList = SearchNodesSource(Edges, Nodes, Nodes[parseInt(Index)], "ASGN");
  ASGList = ASGList.concat(SearchNodesSource(Edges, Nodes, Nodes[parseInt(Index)], "EKSNodeGroupN"));
  return ASGList;
}

function generateRandomSuffix(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let randomSuffix = '';
  for (let i = 0; i < length; i++) {
    randomSuffix += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return randomSuffix;
}

function GenerateRandomizedBucketName(bucketName) {
  const maxLength = 63;
  const randomSuffixMaxLength = maxLength - bucketName.length;
  const randomSuffix = generateRandomSuffix(randomSuffixMaxLength);
  return bucketName + '-' + randomSuffix;
}

function ValidateArn(arn) {
  // Regex para validar o ARN de uma IAM role cross-account
  const regex = /^arn:aws:iam::\d{12}:role\/[\w+=,.@-]+$/;
  if (regex.test(arn)) {
    console.log("O ARN é válido.");
    return true;
  } else {
    console.log("O ARN é inválido.");
    return false;
  }
}

function MinXYBox(nodes, ID,) {
  let XMax = -1;
  let YMax = -1;
  let XMin = 99999999;
  let YMin = 99999999;
  let X, Y;
  //console.log("nodes", nodes)
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].parentNode == ID) {
      //nodes[i].position.x += DeltaX;
      X = nodes[i].position.x;
      console.log("NodeType", nodes[i].type)
      console.log("X", X, nodes[i].width)
      if (X > XMax) { XMax = X + nodes[i].width; }
      if (X < XMin) { XMin = X; }
      Y = nodes[i].position.y;
      console.log("Y", Y)
      if (Y > YMax) { YMax = Y + nodes[i].height; }
      if (Y < YMin) { YMin = Y; }
    }
  }
  //const IntID = parseInt(ID);
  //const ParentID = parseInt(nodes[IntID].parentNode);
  //const MaxWidth = nodes[ParentID].width - nodes[IntID].position.x;
  //const MaxHeight = nodes[ParentID].height - nodes[IntID].position.y;
  //const MinWidth = X - XMin;
  //const MinHeight = Y - YMin;
  //console.log("MinWidth, MinHeight, MaxWidth, MaxHeight", MinWidth, MinHeight, MaxWidth, MaxHeight)
  console.log("XMin, YMin, XMax, YMax", XMin, YMin, XMax, YMax)
  return [XMin, YMin, XMax, YMax]
}

function ExtremePositionBox(nodes, ID, Direction) {
  let XMax = -1;
  let YMax = -1;
  let XMin = 99999999;
  let YMin = 99999999;
  let X, Y;
  //console.log("nodes", nodes)
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].parentNode == ID) {
      //nodes[i].position.x += DeltaX;
      X = nodes[i].position.x;
      console.log("NodeType", nodes[i].type)
      console.log("X", X, nodes[i].width)
      if (X > XMax) { XMax = X + nodes[i].width; }
      if (X < XMin) { XMin = X; }
      Y = nodes[i].position.y;
      console.log("Y", Y)
      if (Y > YMax) { YMax = Y + nodes[i].height; }
      if (Y < YMin) { YMin = Y; }
    }
  }
  console.log("XMin, YMin, XMax, YMax", XMin, YMin, XMax, YMax)
  return [XMin, YMin, XMax, YMax]
}

function mirrorNodes(nodes, parentNode, direction, Constraints) {
  console.log("parentNode", parentNode, direction);
  const parentWidth = parentNode.width;
  const parentHeight = parentNode.height;
  console.log("parentWidth", parentWidth, parentHeight);
  const ListNode = FindNodesChieldOneLevelID(nodes, parentNode.id);
  console.log("ListNode", ListNode);
  let NodeID;
  for (let i = 0; i < ListNode.length; i++) {
    if (direction === 'vertical') {
      NodeID = ListNode[i];
      nodes[NodeID].position.x = parentWidth - (nodes[NodeID].position.x + nodes[NodeID].width);
    } else {
      NodeID = ListNode[i];
      nodes[NodeID].position.y = parentHeight - (nodes[NodeID].position.y + nodes[NodeID].height);
    }
    let NodeType = nodes[NodeID].type;
    if (Constraints.ListBoxes.includes(NodeType)) {
      mirrorNodes(nodes, nodes[NodeID], direction, Constraints)
    }
  }
}
//Verifica restrições para uma cópia ou drop de recurso em um box. Exemplo, por-se ter apenas um Terraform node por box.
function BoxRestrictions(Nodes, NodeType, ParentNodeID) {
  //1) Apenas 1 Terraform node por box
  let ListTerrraformType = ["TerraformN", "TerraformBackendS3N"];
  let Error = "";
  if (ListTerrraformType.includes(NodeType)) {
    let ListNodes = FindNodesChieldOneLevelID(Nodes, ParentNodeID);
    for (let i = 0; i < ListNodes.length; i++) {
      let ChildNodeType = Nodes[ListNodes[i]].type;
      if (ListTerrraformType.includes(ChildNodeType)) {
        Error = "There can only be one Terraform $$TerraformN$$ node per box.";
      }
    }
  }
  if (NodeType === "IGWN") {
    let ListNodes = FindNodesChieldOneLevelID(Nodes, ParentNodeID);
    for (let i = 0; i < ListNodes.length; i++) {
      let ChildNodeType = Nodes[ListNodes[i]].type;
      if (ChildNodeType === "IGWN") {
        Error = "There can only be one IGW $$IGWN$$ node per VPC.";
      }
    }
  }
  return Error;
}
function FindTerraformHead(Edges, Nodes, NodeID) {

}

//Descobre a rede terraform a partir de qualquer box. Usado em Stage Codepipeline
//Retorna a lista de estados Terraform por dependencia e o Terraform Backend, se existir e flag indicando loop, se houver
function DiscoveryTerraformNetwork(Edges, Nodes, NodeID) {
  let LoopList = [];
  let FirstTerraformIntID = 0;
  let BackEndTerraformIntID = 0;
  let ListNodes = FindNodesChieldID(Nodes, NodeID);//Todos os nodes do box referente ao stage
  //console.log("ListNodes", ListNodes)
  for (let i = 0; i < ListNodes.length; i++) {
    let ChildNodeType = Nodes[ListNodes[i]].type;
    if (TypeTerraform.includes(ChildNodeType)) {
      FirstTerraformIntID = parseInt(ListNodes[i]);
      //console.log("FirstTerraformIntID", FirstTerraformIntID)
      break;
    }
  }
  if (FirstTerraformIntID === 0) { return [[], 0, ""] }
  if (detectLoop(Edges, Nodes, FirstTerraformIntID)) { return [[], 0, true] }
  //Procura Terrraform top da hierarquia
  while (true) {
    //console.log(" Nodes[FirstTerraformIntID]", Nodes[FirstTerraformIntID])
    let ListFather = SearchNodesSource(Edges, Nodes, Nodes[FirstTerraformIntID], "all");
    //console.log("ListFather", ListFather)
    if (ListFather.length > 0) {
      FirstTerraformIntID = parseInt(ListFather[0]);
      if (Nodes[FirstTerraformIntID].type === "TerraformBackendS3N") {
        break;
      }
      if (LoopList.includes(FirstTerraformIntID)) { return [[], 0, true]; }
      LoopList.push(FirstTerraformIntID)
    } else {
      break;
    }
  }
  //Achou primeiro Terraform da cadeia
  let ListStates = [];
  LoopList = [FirstTerraformIntID.toString()];
  //console.log("FirstTerraformIntID", FirstTerraformIntID, Nodes[FirstTerraformIntID].type)
  if (Nodes[FirstTerraformIntID].type === "TerraformBackendS3N") {
    BackEndTerraformIntID = FirstTerraformIntID;
  }
  let List = SearcheStateChild(Edges, Nodes, FirstTerraformIntID, ListNodes, [], 0, [], [FirstTerraformIntID.toString()])
  ListStates = ListStates.concat(List[0]);
  let ListLevel = List[2];
  let HasLoop = List[3];
  const combinado = ListStates.map((id, index) => ({ id, nivel: ListLevel[index] }));
  combinado.sort((a, b) => a.nivel - b.nivel);
  const lastOccurrenceMap = new Map();
  combinado.forEach((item, index) => {
    lastOccurrenceMap.set(item.id, index);
  });
  const uniqueCombinado = combinado.filter((item, index) => lastOccurrenceMap.get(item.id) === index);
  ListStates = uniqueCombinado.map(objeto => objeto.id);
  //console.log("ListStates", ListStates);
  ListStates.unshift(FirstTerraformIntID.toString());
  //console.log("ListStates", ListStates)
  let ListName = [];
  for (let i = 0; i < ListStates.length; i++) {
    ListName.push(Nodes[parseInt(ListStates[i])].data.Param[1][1]);
  }
  if (BackEndTerraformIntID === NodeID) {
    BackEndTerraformIntID = 0;
  }
  //console.log("ListName", ListName);
  return [ListStates, BackEndTerraformIntID, HasLoop];
}

function SearcheStateChild(Edges, Nodes, TerraformIntID, ListNodes, ListState, Level, ListLevel, LoopList) {
  Level += 1;
  let NextListState = [];
  //console.log("NodeName", Nodes[TerraformIntID].data.Param[1][1])
  let ListChild = SearchNodesTarget(Edges, Nodes, Nodes[TerraformIntID], "TerraformN");
  for (let i = 0; i < ListChild.length; i++) {
    let ChildIntID = ListChild[i];
    let ChildID = ChildIntID.toString();
    //console.log("ChildID", ChildID)
    if (LoopList.includes(ChildID)) {
      //console.log("LoopList lopp", LoopList);
      //return [[], [], [], true]
    }
    LoopList.push(ChildID);
    //console.log("LoopList", LoopList);
    NextListState = NextListState.concat(SearcheStateChild(Edges, Nodes, ChildIntID, ListNodes, ListState, Level, ListLevel, LoopList));
    if (NextListState[3]) { return [[], [], [], true] }
    if (!ListState.includes(ChildID)) {
      if (ListNodes.includes(ChildID)) {
        //console.log("push", Nodes[ChildIntID].data.Param[1][1], ChildID)
        ListState.push(ChildID);
        ListLevel.push(Level);
      }
    } else {
      //console.log("Ajuste das depend")
      ListState.push(ChildID);
      ListLevel.push(Level);
    }
  }
  //console.log("ListState", ListState)
  ListChild = NextListState[0] || [];
  //console.log("ListChildL2", ListChild)
  for (let i = 0; i < ListChild.length; i++) {
    let ChildIntID = ListChild[i];
    let ChildID = ChildIntID.toString();
    if ((!ListState.includes(ChildID)) && (ListNodes.includes(ChildID))) {
      ListState.push(ChildID);
      ListLevel.push(Level);
    }
  }
  //console.log("ListState", ListState, ListLevel)
  return [ListState, NextListState, ListLevel, false];
}

function detectLoop(Edges, Nodes, startNodeID) {
  // Conjunto para manter os nós no caminho atual
  let currentPath = new Set();
  // Conjunto para manter todos os nós visitados
  let visitedNodes = new Set();
  // Função recursiva de DFS para percorrer a árvore e detectar loops
  function dfs(nodeID) {
    // Se o nó já está no caminho atual, detectamos um loop
    if (currentPath.has(nodeID)) {
      //console.log("Loop detected at node:", nodeID);
      return true;
    }
    // Adicionar o nó ao caminho atual e ao conjunto de nós visitados
    currentPath.add(nodeID);
    visitedNodes.add(nodeID);
    // Obter a lista de nós filhos conectados ao nó atual
    let childNodes = SearchNodesTarget(Edges, Nodes, Nodes[nodeID], "TerraformN");
    // Percorrer cada nó filho e realizar a busca em profundidade (DFS)
    for (let childID of childNodes) {
      if (dfs(childID)) {
        return true; // Loop detectado em um dos filhos
      }
    }
    // Remover o nó do caminho atual após visitar todos os filhos
    currentPath.delete(nodeID);
    return false;
  }
  // Iniciar a busca em profundidade (DFS) a partir do nó inicial
  return dfs(startNodeID);
}

function FindBackendStorage(Edges, Nodes, TFID, IsCodePipeline = true) {
  //console.log("TFID", TFID)
  if (TFID !== 0) {
    const BoxParentID = parseInt(Nodes[TFID].parentNode);
    //console.log("BoxParentID", BoxParentID)
    const ListNodes = FindNodesChieldID(Nodes, BoxParentID);
    //console.log("ListNodes", ListNodes)
    let StorageID = 0, DBID = 0, RepoID = 0, BuildID = 0;
    let ErrorMSG = "";
    const Storagelist = ["S3N"];
    const DBList = ["DynamoDBN"];
    const RepoList = ["GitHubN"];
    const BuildList = ["CodeBuildN"]
    for (let i = 0; i < ListNodes.length; i++) {
      let NodeID = parseInt(ListNodes[i]);
      let NodeType = Nodes[NodeID].type;
      //console.log("NodeType", NodeType, NodeID)
      if (Storagelist.includes(NodeType)) {
        if (StorageID !== 0) { ErrorMSG = "It shoud have only one S3 in the backend box." }
        //console.log("s3", StorageID)
        StorageID = NodeID;
      }
      if (DBList.includes(NodeType)) {
        if (DBID !== 0) { ErrorMSG = "It shoud have only one DynamoDB in the backend box." }
        DBID = NodeID;
      }
      if (RepoList.includes(NodeType)) {
        if (RepoID !== 0) { ErrorMSG = "It shoud have only one Repository in the backend box." }
        RepoID = NodeID;
      }
      if (BuildList.includes(NodeType)) {
        //if (BuildID !== 0) { ErrorMSG = "It shoud have only one Compute icon to run Terraform in the backend box." }
        const SourceList = SearchNodesSource(Edges, Nodes, Nodes[NodeID], "CodeBuildSourceN");
        if (IsCodePipeline) {
          if (SourceList.length === 0) {
            BuildID = NodeID;
          }
        } else {
          if (SourceList.length !== 0) {
            BuildID = NodeID;
          }
        }
      }
    }
    console.log("StorageID, DBID, ErrorMSG func", StorageID, DBID, BuildID, IsCodePipeline)
    return [StorageID, DBID, RepoID, BuildID, ErrorMSG];
  } else {
    return [0, 0, 0, 0, ""];
  }
}

//retorna o ID do head, o level e a lista de nodes intermediários entre o node correte e o head
//Se NodeTypeHead === NodeType, retorna o NodeHead que não possui nenhum source
// se for diferente, retorna o primeiro nodehead encontrado
function FindNodeSourceHead(Edges, Nodes, NodeTypeHead, NodeType, NodeIntID, Level = -1, Direction = "src") {
  //console.log("NodeIntID", NodeIntID, NodeType);
  let ListSources = [];
  let SourceList;
  while (true) {
    //console.log("Level", Level);
    // Verifica se NodeTypeHead é igual a NodeType
    if (NodeTypeHead === NodeType) {
      // Busca iterativamente até não encontrar mais nenhuma fonte
      if (Direction === "src") {
        SourceList = SearchNodesSource(Edges, Nodes, Nodes[NodeIntID], NodeType);
      } else {
        SourceList = SearchNodesTarget(Edges, Nodes, Nodes[NodeIntID], NodeType);
      }
      //console.log("SourceList Iterative", SourceList);
      if (SourceList.length > 0) {
        NodeIntID = parseInt(SourceList[0]);
        ListSources.push(NodeIntID);
        Level += 1;
        if (Level > 50) {
          //console.log("Break loop");
          return [0, 0, []];
        }
      } else {
        // Considera o nó atual como o head
        return [NodeIntID, Level, ListSources];
      }
    } else {
      // Lógica existente para NodeTypeHead diferente de NodeType
      if (Direction === "src") {
        SourceList = SearchNodesSource(Edges, Nodes, Nodes[NodeIntID], NodeTypeHead);
      } else {
        SourceList = SearchNodesTarget(Edges, Nodes, Nodes[NodeIntID], NodeTypeHead);
      }
      //console.log("SourceList Head", SourceList);
      if (SourceList.length > 0) {
        return [parseInt(SourceList[0]), Level, ListSources];
      } else {
        if (Direction === "src") {
          SourceList = SearchNodesSource(Edges, Nodes, Nodes[NodeIntID], NodeType);
        } else {
          SourceList = SearchNodesTarget(Edges, Nodes, Nodes[NodeIntID], NodeType);
        }
        //console.log("SourceList no Head", SourceList);
        if (SourceList.length > 0) {
          NodeIntID = parseInt(SourceList[0]);
          ListSources.push(NodeIntID);
          Level += 1;
          if (Level > 50) {
            //console.log("Break loop");
            return [0, 0, []];
          }
        } else {
          return [0, 0, []];
        }
      }
    }
  }
}


function FindNodeNonGraphNHead(edges, nodes, currentNodeID) {
  try {
    // Verificar se o node atual é do tipo GraphN, se não for, retornar seu ID
    if (nodes[currentNodeID].type !== 'GraphN') {
      return currentNodeID;
    }
    // Encontrar os nodes fontes conectados ao node atual
    let sourceList = SearchNodesSource(edges, nodes, nodes[currentNodeID]);
    // Se a lista de fontes estiver vazia, retornar 0
    if (sourceList.length === 0) {
      return 0;
    }
    // Recursivamente verificar o primeiro node da lista de fontes
    return FindNodeNonGraphNHead(edges, nodes, parseInt(sourceList[0]));


  } catch (error) {
    //pass
  }
}


function compareVersions(version1, version2) {
  // Divide as versões em partes usando split e converte em arrays de números inteiros
  let v1Parts = version1.split('.').map(Number);
  let v2Parts = version2.split('.').map(Number);
  // Verifica se cada parte contém apenas números
  if (!validateVersionParts(v1Parts) || !validateVersionParts(v2Parts)) {
    return -2;
  }
  // Completa os arrays com zeros para garantir que ambos tenham o mesmo tamanho
  let maxLength = Math.max(v1Parts.length, v2Parts.length);
  v1Parts = padArray(v1Parts, maxLength);
  v2Parts = padArray(v2Parts, maxLength);
  // Compara cada parte numericamente
  for (let i = 0; i < maxLength; i++) {
    if (v1Parts[i] > v2Parts[i]) {
      return 1;
    } else if (v1Parts[i] < v2Parts[i]) {
      return -1;
    }
  }
  return 0; // Se forem iguais
}

// Função auxiliar para validar se todas as partes são números
function validateVersionParts(parts) {
  for (let part of parts) {
    if (isNaN(part)) {
      return false;
    }
  }
  return true;
}
// Função auxiliar para preencher o array com zeros até o tamanho especificado
function padArray(array, length) {
  while (array.length < length) {
    array.push(0);
  }
  return array;
}

function AdjustSubnetCIDRAfterPaste(Edges, Nodes, NewNodes) {
  let ListVPC = [];
  for (let i = 0; i < NewNodes.length; i++) {
    if (TypeSubnet.includes(NewNodes[i].type)) {
      console.log("TypeSubnet", NewNodes[i].type, NewNodes[i], NewNodes[i].id)
      let VPCID = GetVPCParent(Nodes, parseInt(NewNodes[i].id))
      ListVPC.push(VPCID);
    }
  }
  ListVPC = [...new Set(ListVPC)];
  console.log("ListVPC", ListVPC)
  for (let i = 0; i < ListVPC.length; i++) {
    AdjustSubnetCIDR(Edges, Nodes, ListVPC[i]);
  }
}

//compara dois objetos se são iguais
function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}

const reorderNodesAndEdges = (nodes, edges, MoveID, SelectedNodeID) => {
  const adjustedNodes = JSON.parse(JSON.stringify(nodes));
  const orderedNodes = [];
  const orderedEdges = [];
  const nodeMap = new Map(adjustedNodes.map(node => [node.id, node]));
  const idMap = new Map(); // Map to keep track of new IDs

  // Adiciona o nó de configuração fixo na posição 0
  const configNodeId = 'Config';
  const configNode = nodeMap.get(configNodeId);
  if (configNode) {
    orderedNodes.push(configNode);
    idMap.set(configNodeId, '0'); // O nó de configuração mantém o ID '0'
  } else {
    console.error(`Config node (id: ${configNodeId}) is missing.`);
    return { nodes: [], edges: [], newModeID: null, newSelectedNodeID: null };
  }

  // Função recursiva para ordenar a árvore e atualizar os IDs
  const orderTree = (node, currentId) => {
    const newId = currentId.toString();
    idMap.set(node.id, newId); // Atualiza o ID do nó
    const newNode = { ...node, id: newId };
    if (node.parentNode === 0) {
      newNode.parentNode = 0; // Garante que os nós raiz tenham parentNode igual a 0
    } else if (newNode.parentNode !== null && newNode.parentNode !== undefined) {
      newNode.parentNode = idMap.get(newNode.parentNode); // Atualiza o parentNode para o novo ID
    }
    orderedNodes.push(newNode);
    let nextId = currentId + 1;
    nodeMap.forEach(child => {
      if (child.parentNode === node.id) {
        nextId = orderTree(child, nextId); // Recursivamente ordena os filhos
      }
    });
    return nextId;
  };

  // Iniciar a ordenação a partir dos nós raiz (parentNode igual a 0)
  nodeMap.forEach(node => {
    if (node.parentNode === 0) {
      orderTree(node, orderedNodes.length);
    }
  });

  // Reordenar as edges de acordo com os novos IDs dos nós
  edges.forEach(edge => {
    const newSource = idMap.get(edge.source);
    const newTarget = idMap.get(edge.target);
    if (newSource !== undefined && newTarget !== undefined) {
      orderedEdges.push({ ...edge, source: newSource, target: newTarget });
    }
  });

  // Verifica o novo ID do MoveID e SelectedNodeID após a reordenação
  const newMoveID = idMap.get(MoveID);
  const newSelectedNodeID = idMap.get(SelectedNodeID);

  if (!newMoveID) {
    console.error(`MoveID (${MoveID}) não encontrado após a reordenação.`);
  }

  if (!newSelectedNodeID) {
    console.error(`SelectedNodeID (${SelectedNodeID}) não encontrado após a reordenação.`);
  }

  return { nodes: orderedNodes, edges: orderedEdges, MoveID: newMoveID, SelectedNodeID: newSelectedNodeID };
};


function UpdateRoute53BlueGreen(R53ID, NewGreenPercent) {
  console.log("R53ID, NewGreenPercent", R53ID, NewGreenPercent)

}

function AdjustSufixName(GlobalEdges, GlobalNodes, NodeID, IsSingleNode = false) {
  let ListBoxesSufix = [];
  let NodesWithSufix = JSON.parse(JSON.stringify(GlobalNodes));
  let [CloudNode, Region] = FindRegionAndCloud(GlobalNodes, NodeID);
  for (let i = 1; i < NodesWithSufix.length; i++) {
    let [Cloud, Region] = FindRegionAndCloud(GlobalNodes, i);
    let NodeType = NodesWithSufix[i].type;
    if (NodeType === "SSMParameterN" && NodesWithSufix[i].data.Param[2][1] === "") {
      NodesWithSufix[i].data.Param[2][1] = NodesWithSufix[i].data.Param[1][1];
    }
    //console.log("CloudNode", CloudNode, Cloud, NodeType, i)
    if (Cloud !== CloudNode && !(TypeCloud.includes(NodeType))) {//apgaga dados de todos os nodes de outra cloud, menos a propria 
      //NodesWithSufix[i].data.Param = "";
      NodesWithSufix[i].type = "Discard";
    }
    if (NodeType === "GraphN") { //discarta de for Graph
      NodesWithSufix[i].data.Param = [[], ["", "Graph"]];  // Exemplo de inicialização
      NodesWithSufix[i].data.Param[1][1] = "Graph";  // Acessando e modificando o valor
      NodesWithSufix[i].type = "Discard";
    }
    if (TypeVPC.includes(NodeType)) {
      //console.log("Node type vpc", NodesWithSufix[i].data.Param)
      if (NodesWithSufix[i].data.Param[13][1] !== "") {//adiciona sufix no caso de VPC
        const Sufix = NodesWithSufix[i].data.Param[13][1].toLowerCase();
        //NodesWithSufix[i].data.Param[1][1] += "-" + Sufix;
        ListBoxesSufix.push([i, Sufix]);
      }

    }
    if (NodeType === "SBoxN") {
      const ParentID = parseInt(NodesWithSufix[i].parentNode);
      if (NodesWithSufix[ParentID].type === "SBoxN" || NodesWithSufix[ParentID].type === "VPCN") {
        const ParentName = NodesWithSufix[ParentID].data.Param[1][1].toLowerCase();
        const NodeIndex = NodesWithSufix[ParentID].type === "SBoxN" ? 4 : 13;
        //console.log("NodeIndex", NodeIndex)
        let SufixFather = "";
        if (NodesWithSufix[ParentID].type === "SBoxN") {
          SufixFather = NodesWithSufix[ParentID].data.Param[NodeIndex][1].toString().toLowerCase();
        }
        const ListStage = SearchNodesSource(GlobalEdges, NodesWithSufix, NodesWithSufix[ParentID], "CodePipelineStageN");
        let StageID = 0;
        let SufixChild = "";
        if (ListStage.length > 0) {
          StageID = parseInt(ListStage[0]);
          let StageName = NodesWithSufix[StageID]?.data?.Param[1][1] || "";
          StageName = StageName.toLowerCase();
          //console.log("ListStage sufix  *******", StageName, ListStage, StageID, NodeIndex)
          if (StageName !== "test" && StageName !== "dev") {
            SufixChild = NodesWithSufix[i].data.Param[4][1].toString().toLowerCase();
            if (SufixChild !== "" && SufixFather !== "") { SufixChild = "-" + SufixChild; }
          }
        }
        //console.log("Sufix child", SufixChild, SufixFather)
        if (!ParentName.includes("version") && !SufixFather.includes(".")) {
          ListBoxesSufix.push([i, SufixFather + SufixChild]);
        }
      }
    }
  }
  //console.log("ListBoxesSufix", ListBoxesSufix)
  const ExcludedList = ["GraphN", "Discard", "CodePipelineStageN", "CogSchemaN"];
  for (let i = 0; i < ListBoxesSufix.length; i++) {
    let BoxID = ListBoxesSufix[i][0];
    //console.log("Level", Level, NodesWithSufix[BoxID].type)
    let Sufix = ListBoxesSufix[i][1];
    //console.log("NodesWithSufix", NodesWithSufix)
    let ListNodesSufix = FindNodesChieldID(NodesWithSufix, BoxID.toString());
    //console.log("ListNodes", ListNodesSufix)
    for (let j = 0; j < ListNodesSufix.length; j++) {
      let Index = parseInt(ListNodesSufix[j]);
      let NodeTypeInsideBox = NodesWithSufix[Index].type;
      //console.log("Index", Index, NodeTypeInsideBox)
      if (!TypeAZ.includes(NodeTypeInsideBox) && !(ExcludedList.includes(NodeTypeInsideBox)) && Sufix !== "") {
        NodesWithSufix[Index].data.Param[1][1] = NodesWithSufix[Index].data.Param[1][1] + "-" + Sufix;
        if (NodeTypeInsideBox === "SSMParameterN") {
          NodesWithSufix[Index].data.Param[2][1] = NodesWithSufix[Index].data.Param[2][1] + "-" + Sufix;
        }
        if (NodeTypeInsideBox === "R53ZoneN") {
          if (NodesWithSufix[Index].data.Param[2][1] === "") {
            NodesWithSufix[Index].data.Param[2][1] = Sufix;
          } else {
            //NodesWithSufix[Index].data.Param[2][1] = NodesWithSufix[Index].data.Param[2][1] + "-" + Sufix;
          }
        }
      }
    }
  }
  //for (let i = 1; i < NodesWithSufix.length; i++) {//sufix lambda edge inclindo o nome do param store
  //let NodeType = NodesWithSufix[i].type;
  //if (NodeType === "LambdaN") {
  //console.log("Lambda NAme", NodesWithSufix[i].data.Param[1][1])
  //const ListCF = SearchNodesTarget(GlobalEdges, NodesWithSufix, NodesWithSufix[i], "CFCBehaviorN");
  //if (ListCF.length > 0) {
  //console.log("Achou CF")
  //const ListParam = SearchNodesSource(GlobalEdges, NodesWithSufix, NodesWithSufix[i], "SSMParameterN");
  //if (ListParam.length > 0) {
  //  const ParamID = parseInt(ListParam[0]);
  //console.log("Achou Param", NodesWithSufix[ParamID].data.Param[1][1])
  //  NodesWithSufix[i].data.Param[1][1] += "_env_" + NodesWithSufix[ParamID].data.Param[2][1];
  //console.log("Novo nome", NodesWithSufix[i].data.Param[1][1])
  //}
  //}

  //}
  //}
  if (IsSingleNode) {
    return NodesWithSufix[NodeID]
  } else {
    return NodesWithSufix
  }
}

function updateTerraformDependencies(TFID, GlobalEdges, GlobalNodes, GlobalEdgesLocal) {
  function edgeExists(source, target, edges) {
    return edges.some(edge => edge.source === source && edge.target === target);
  }
  //Varre ancestrais
  let currentLevel = [TFID];
  let nextLevel = [];
  while (currentLevel.length > 0) {
    for (let i = 0; i < currentLevel.length; i++) {
      let RefID = currentLevel[i];
      let ListSourceTF = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[RefID], "TerraformN");
      for (let j = 0; j < ListSourceTF.length; j++) {
        let TFSourceID = ListSourceTF[j];
        if (!edgeExists(TFSourceID.toString(), RefID.toString(), GlobalEdgesLocal) && RefID === TFID) {
          GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": TFSourceID.toString(), "target": RefID.toString(), "label": "" });
        }
        // Adiciona TFSourceID ao próximo nível para processar na próxima iteração
        nextLevel.push(TFSourceID);
        // Adiciona edges para todos os ancestrais do TFSourceID
        let AncestorQueue = [TFSourceID];
        while (AncestorQueue.length > 0) {
          let AncestorID = AncestorQueue.shift();
          let ListAncestorTF = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[AncestorID], "TerraformN");
          if (ListAncestorTF.length > 0) {
            for (let k = 0; k < ListAncestorTF.length; k++) {
              let TFA = ListAncestorTF[k];
              if (!edgeExists(TFA, RefID.toString(), GlobalEdgesLocal) && RefID === TFID) {
                GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": TFA.toString(), "target": RefID.toString(), "label": "" });
              }
              AncestorQueue.push(TFA);
            }
          }
        }
      }
    }
    // Passa para o próximo nível
    currentLevel = nextLevel;
    nextLevel = [];
  }
  //varre descendentes
  currentLevel = [TFID];
  nextLevel = [];
  while (currentLevel.length > 0) {
    for (let i = 0; i < currentLevel.length; i++) {
      let RefID = currentLevel[i];
      let ListTargetTF = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[RefID], "TerraformN");
      for (let j = 0; j < ListTargetTF.length; j++) {
        let TFTargetID = ListTargetTF[j];
        if (!edgeExists(RefID.toString(), TFTargetID.toString(), GlobalEdgesLocal) && RefID === TFID) {
          GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": RefID.toString(), "target": TFTargetID.toString(), "label": "" });
        }
        // Adiciona TFTargetID ao próximo nível para processar na próxima iteração
        nextLevel.push(TFTargetID);
        // Adiciona edges para todos os descendentes do TFTargetID
        let DescendantQueue = [TFTargetID];
        while (DescendantQueue.length > 0) {
          let DescendantID = DescendantQueue.shift();
          let ListDescendantTF = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[DescendantID], "TerraformN");
          if (ListDescendantTF.length > 0) {
            for (let k = 0; k < ListDescendantTF.length; k++) {
              let TFD = ListDescendantTF[k];
              if (!edgeExists(RefID.toString(), TFD.toString(), GlobalEdgesLocal) && RefID === TFID) {
                GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": RefID.toString(), "target": TFD.toString(), "label": "" });
              }
              DescendantQueue.push(TFD);
            }
          }
        }
      }
    }
    // Passa para o próximo nível
    currentLevel = nextLevel;
    nextLevel = [];
  }
}

// Função para formatar os números do eixo Y
const formatTick = value => {
  if (value < 100) {
    return value.toFixed(2);
  } else if (value < 1000) {
    return value.toFixed(1);
  }
  let suffix = '';
  let divisor = 1;
  if (value < 9999) {
    return value.toFixed(0);
  } else if (value >= 9999 && value < 9999000) {
    suffix = 'K';
    divisor = 1000;
  } else if (value >= 9999000 && value < 9999000000) {
    suffix = 'M';
    divisor = 1000000;
  } else if (value >= 9999000000) {
    suffix = 'G';
    divisor = 1000000000;
  }
  let formattedNumber = value / divisor;
  return `${Number(formattedNumber.toFixed(1)).toString().replace(/\.0$/, '')}${suffix}`;
};


/*async function createAndCaptureChartSequentially(metricData, Grid, Legends, intervalInSeconds, LastUpdate, width = 384, height = 288) { //Nivo
  return new Promise(async (resolve, reject) => {
    const container = document.createElement('div');
    container.style.width = `${384}px`;
    container.style.height = `${236}px`;
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    document.body.appendChild(container);
 
    const intervalToText = (interval) => {
      const intervals = {
        3600: "1 Hour",
        10800: "3 Hours",
        43200: "12 Hours",
        86400: "1 Day",
        259200: "3 Days",
        604800: "1 Week",
        1209600: "2 Weeks",
        2592000: "1 Month",
        5184000: "2 Months",
        10368000: "4 Months"
      };
      return intervals[interval] || '';
    };
 
    const intervalText = intervalToText(intervalInSeconds);
 
    // Calcular os valores de grade com base nos dados
    const xValues = metricData[0].data.map(d => d.x);
    const yValues = metricData.flatMap(series => series.data.map(d => d.y));
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);
 
    // Definir intervalos de grade
    const yInterval = Math.ceil((yMax - yMin) / 10);
    const gridYValues = Array.from({ length: 11 }, (_, i) => yMin + i * yInterval);
 
    // Definir valores de tick para o eixo X em um intervalo específico (por exemplo, a cada 5 pontos)
    const tickInterval = Math.ceil(xValues.length / 10);
    const gridXValues = xValues.filter((_, index) => index % tickInterval === 0);
 
    const lineChart = (
      <Line
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "black",
                strokeWidth: 0.5
              }
            },
            ticks: {
              line: {
                stroke: "black"
              },
              text: {
                fontSize: '9px', // Reduzido em 45%
              }
            },
            legend: {
              text: {
                fontSize: '11px', // Reduzido em 45%
              }
            }
          },
          legends: {
            text: {
              fontSize: '11px', // Reduzido em 45%
            }
          }
        }}
        gridXValues={Grid ? gridXValues : []}
        gridYValues={Grid ? gridYValues : []}
        animate={false}
        motionStiffness={0}
        data={metricData}
        width={384}
        height={280}
        margin={{ top: 30, right: 15, bottom: 116, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: gridXValues,
          tickColor: "black",
          domain: { color: "black" },
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 0,
          legendPosition: 'middle',
          tickValues: gridYValues,
          tickColor: 'black',
          domain: { color: 'black' },
          format: formatTick
        }}
        colors={['#f52030', '#1c28f0', '#f9d423', '#10b332']}
        pointSize={3}
        pointBorderWidth={1}
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={Legends ? [{
          anchor: 'bottom',
          direction: 'column',
          margin: { top: 30, right: 0, bottom: 10, left: 0 },
          justify: false,
          translateX: -45,
          translateY: 55 + (metricData.length - 1) * 4,
          itemsSpacing: 7,
          itemWidth: 300,
          itemHeight: 5,
          itemDirection: 'left-to-right',
          itemTextColor: '#000',
          symbolSize: 7,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }] : []}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          // Custom layer for annotations
          ({ innerWidth, innerHeight }) => (
            <g transform={`translate(270, 178)`}>
              <text
                textAnchor="middle"
                style={{
                  fill: 'rgba(0, 0, 0)',
                  fontSize: 28,
                }}
              >
                {intervalText}
              </text>
            </g>
          ),
          ({ innerWidth, innerHeight }) => (
            <g transform={`translate(270, 194)`}>
              <text
                textAnchor="middle"
                style={{
                  fill: 'rgba(0, 0, 0)',
                  fontSize: 8,
                }}
              >
                Last Update: {LastUpdate}
              </text>
            </g>
          )
        ]
        }
      />
    );
    const root = createRoot(container);
    root.render(lineChart);
    // Usar MutationObserver para monitorar mudanças no DOM
    const observer = new MutationObserver(async (mutationsList, observer) => {
      // Se houver qualquer mutação nos nós filhos
      if (mutationsList.some(mutation => mutation.type === 'childList' || mutation.type === 'attributes')) {
        try {
          const canvas = await html2canvas(container, { logging: false });
          const imageSrc = canvas.toDataURL();
          if (container.parentNode) {
            document.body.removeChild(container);
          }
          resolve(imageSrc);
        } catch (error) {
          if (container.parentNode) {
            document.body.removeChild(container);
          }
          reject(error);
        } finally {
          observer.disconnect(); // Desconecta o observador após a captura
        }
      }
    });
    // Configurar o observador para monitorar mudanças no container
    observer.observe(container, { attributes: true, childList: true, subtree: true });
  });
}*/


// main.js
function createWorker() {
  const workerCode = `
  self.onmessage = async function (e) {
      console.log('Worker: Recebendo dados...', e.data);
  
      const { width, height } = e.data;
  
      try {
          // Criar o OffscreenCanvas com dimensões corretas
          const canvas = new OffscreenCanvas(width, height);
          const ctx = canvas.getContext('2d');
  
          console.log('Worker: OffscreenCanvas criado com dimensões:', width, height);
  
          // Limpar o canvas com fundo branco
          ctx.fillStyle = '#ffffff';
          ctx.fillRect(0, 0, width, height);
  
          console.log('Worker: Fundo branco aplicado.');
  
          // Desenhar quadrados coloridos para teste
          const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'orange'];
          const squareSize = width / 10;
  
          for (let i = 0; i < colors.length; i++) {
              ctx.fillStyle = colors[i];
              ctx.fillRect(i * squareSize * 2, i * squareSize * 2, squareSize * 2, squareSize * 2);
          }
  
          console.log('Worker: Quadrados coloridos desenhados.');
  
          // Converter o canvas para uma imagem PNG
          const blob = await canvas.convertToBlob({ type: 'image/png' });
          console.log('Worker: Imagem PNG convertida.');
  
          // Enviar o blob de volta para a thread principal
          self.postMessage({ blob });
          console.log('Worker: Blob enviado para a thread principal.');
      } catch (error) {
          console.error('Worker: Erro ao processar imagem:', error);
          self.postMessage({ error: error.message });
      }
  };
`;
  const blob = new Blob([workerCode], { type: 'application/javascript' });
  const url = URL.createObjectURL(blob);
  return new Worker(url);
}

function createAndCaptureChartSequentially(metricData, Grid = true, Legends = true, intervalInSeconds, LastUpdate, width = 600, height = 400) {
  return new Promise((resolve, reject) => {
    //try {
    /**
     * Formata o valor de y com sufixos para números grandes e pequenos.
     * @param {number} y - O valor de y a ser formatado.
     * @returns {string} - O valor formatado como string.
     */
    function formatYValue(y) {
      const absY = Math.abs(y);

      const largeSuffixes = [
        { value: 1e18, symbol: 'E' }, // Exa
        { value: 1e15, symbol: 'P' }, // Peta
        { value: 1e12, symbol: 'T' }, // Tera
        { value: 1e9, symbol: 'G' },  // Giga
        { value: 1e6, symbol: 'M' },  // Mega
        { value: 1e3, symbol: 'k' }   // Kilo
      ];

      const smallSuffixes = [
        { value: 1e-3, symbol: 'm' }, // Mili
        { value: 1e-6, symbol: 'μ' }, // Micro
        { value: 1e-9, symbol: 'n' }, // Nano
        { value: 1e-12, symbol: 'p' },// Pico
        { value: 1e-15, symbol: 'f' },// Femto
        { value: 1e-18, symbol: 'a' } // Atto
      ];

      // Formatação para números grandes com sufixos
      for (let i = 0; i < largeSuffixes.length; i++) {
        if (absY >= largeSuffixes[i].value) {
          return (y / largeSuffixes[i].value).toFixed(2) + largeSuffixes[i].symbol;
        }
      }

      // Formatação para números pequenos com sufixos
      if (absY < 1 && absY !== 0) {
        for (let i = 0; i < smallSuffixes.length; i++) {
          if (absY >= smallSuffixes[i].value) {
            return (y / smallSuffixes[i].value).toFixed(2) + smallSuffixes[i].symbol;
          }
        }
      }

      // Formatação padrão com duas casas decimais
      return y.toFixed(2);
    }

    function intervalToText(interval) {
      const intervals = {
        3600: "1 Hour",
        21600: "6 Hours",
        43200: "12 Hours",
        86400: "1 Day",
        259200: "3 Days",
        604800: "1 Week",
        1209600: "2 Weeks",
        2592000: "1 Month",
        5184000: "2 Months",
        10368000: "4 Months"
      };
      return intervals[interval] || '';
    }
    // Remover o último ponto de cada série se y = 0
    metricData.forEach(series => {
      if (series.data && series.data.length > 0) {
        const lastPoint = series.data[series.data.length - 1];
        if (lastPoint.y === 0) { // Verifica se o último ponto tem y = 0
          series.data.pop(); // Remove o último ponto
          //console.log(`Último ponto removido da série ${series.id}:`, lastPoint);
        }
      }
    });
    // Criar o canvas
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    // Limpar o canvas com fundo branco
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, width, height);
    // Definir margens e dimensões do gráfico
    const margin = { top: 30, right: 15, bottom: 116, left: 50 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom + 27;
    // Extrair valores de x e y de metricData
    const xValues = metricData[0].data.map(d => d.x);
    const yValues = metricData.flatMap(series => series.data.map(d => d.y));
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);
    // Criar escalas para x e y
    const xScale = (x) => {
      const index = xValues.indexOf(x);
      if (index === -1) return null;
      const xStep = chartWidth / (xValues.length - 1);
      return margin.left + index * xStep;
    };
    const yScale = (y) => {
      return margin.top + chartHeight - ((y - yMin) / (yMax - yMin)) * chartHeight;
    };
    // Desenhar linhas de grade se Grid for verdadeiro
    if (Grid) {
      // Linhas de grade horizontais (eixo Y)
      ctx.strokeStyle = '#dddddd';
      ctx.lineWidth = 0.5;
      const numYGridLines = 10;
      const yInterval = (yMax - yMin) / numYGridLines;
      for (let i = 0; i <= numYGridLines; i++) {
        const yValue = yMin + i * yInterval;
        const y = yScale(yValue);
        ctx.beginPath();
        ctx.moveTo(margin.left, y);
        ctx.lineTo(margin.left + chartWidth, y);
        ctx.stroke();
      }
      // Linhas de grade verticais (eixo X)
      const tickInterval = Math.ceil(xValues.length / 10);
      const gridXValues = xValues.filter((_, index) => index % tickInterval === 0);
      gridXValues.forEach((xValue) => {
        const x = xScale(xValue);
        ctx.beginPath();
        ctx.moveTo(x, margin.top);
        ctx.lineTo(x, margin.top + chartHeight);
        ctx.stroke();
      });
    }
    // Desenhar eixos
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    // Eixo X
    ctx.beginPath();
    ctx.moveTo(margin.left, margin.top + chartHeight);
    ctx.lineTo(margin.left + chartWidth, margin.top + chartHeight);
    ctx.stroke();
    // Eixo Y
    ctx.beginPath();
    ctx.moveTo(margin.left, margin.top);
    ctx.lineTo(margin.left, margin.top + chartHeight);
    ctx.stroke();
    // Desenhar ticks e labels do eixo Y
    ctx.fillStyle = 'black';
    ctx.font = '9px Arial';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'middle';
    const numYTicks = 10;
    const yTickInterval = (yMax - yMin) / numYTicks;
    for (let i = 0; i <= numYTicks; i++) {
      const yValue = yMin + i * yTickInterval;
      const y = yScale(yValue);
      // Ticks
      ctx.beginPath();
      ctx.moveTo(margin.left - 5, y);
      ctx.lineTo(margin.left, y);
      ctx.stroke();
      // Labels com formatação
      ctx.fillText(formatYValue(yValue), margin.left - 10, y);
    }
    // Desenhar ticks e labels do eixo X
    ctx.fillStyle = 'black';
    ctx.font = '9px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';
    const xTickInterval = Math.ceil(xValues.length / 10);
    const xTickValues = xValues.filter((_, index) => index % xTickInterval === 0);
    xTickValues.forEach((xValue) => {
      const x = xScale(xValue);
      // Ticks
      ctx.beginPath();
      ctx.moveTo(x, margin.top + chartHeight);
      ctx.lineTo(x, margin.top + chartHeight + 5);
      ctx.stroke();
      // Labels
      ctx.fillText(xValue, x, margin.top + chartHeight + 5);
    });
    // Desenhar as linhas de dados
    const colors = ['#f52030', '#1c28f0', '#f9d423', '#10b332'];
    metricData.forEach((series, seriesIndex) => {
      ctx.strokeStyle = colors[seriesIndex % colors.length];
      ctx.lineWidth = 2;
      ctx.beginPath();
      let started = false; // Flag para iniciar a linha apenas com dados válidos
      series.data.forEach((point, pointIndex) => {
        if (point.y === null) return; // Ignorar pontos sem dados
        const x = xScale(point.x);
        const y = yScale(point.y);
        if (!started) {
          ctx.moveTo(x, y);
          started = true;
        } else {
          ctx.lineTo(x, y);
        }
      });
      ctx.stroke();
    });
    // Desenhar os pontos dos dados
    metricData.forEach((series, seriesIndex) => {
      ctx.fillStyle = colors[seriesIndex % colors.length];
      series.data.forEach((point) => {
        if (point.y === null) return; // Ignorar pontos sem dados
        const x = xScale(point.x);
        const y = yScale(point.y);
        ctx.beginPath();
        ctx.arc(x, y, 3, 0, 2 * Math.PI);
        ctx.fill();
      });
    });
    // Desenhar o texto do intervalo e da última atualização
    const intervalText = intervalToText(intervalInSeconds);
    ctx.fillStyle = 'black';
    ctx.font = '30px Arial'; // Aumentado em 2px
    ctx.textAlign = 'right'; // Alinhado à direita
    ctx.textBaseline = 'bottom';
    ctx.fillText(intervalText, width - 10, height - 30); // Margem de 10px à direita
    ctx.font = '10px Arial'; // Aumentado em 2px
    ctx.fillText('Last Update: ' + LastUpdate, width - 10, height - 10); // Margem de 10px à direita
    // Desenhar legendas se Legends for verdadeiro
    if (Legends) {
      const legendYStart = margin.top + chartHeight + 20;
      const legendSpacing = 15;
      const legendX = margin.left;
      ctx.font = '13px Arial'; // Aumentado em 2px
      ctx.textAlign = 'left';
      ctx.textBaseline = 'top';
      metricData.forEach((series, seriesIndex) => {
        const y = legendYStart + seriesIndex * legendSpacing;
        // Desenhar símbolo da legenda
        ctx.fillStyle = colors[seriesIndex % colors.length];
        ctx.beginPath();
        ctx.arc(legendX, y + 5, 5, 0, 2 * Math.PI);
        ctx.fill();
        // Desenhar texto da legenda
        ctx.fillStyle = 'black';
        ctx.fillText(series.id, legendX + 15, y);
      });
    }
    const imageSrc = canvas.toDataURL('image/png');
    resolve(imageSrc);
  });
}


const Time = {
  "0": 3600,
  "1": 21600,
  "2": 43200,
  "3": 86400,
  "4": 259200,
  "5": 604800,
  "6": 1209600,
  "7": 2592000,
  "8": 5184000,
  "9": 10368000
};

const SamplePeriods = [
  60, 300, 600, 1200, 3600, 8400, 16800, 36000, 72000, 144000
];

function adjustTimestamps(metricsArray, intervalInSeconds, samplePeriodInSeconds, endTimeString) {
  if (!Array.isArray(metricsArray) || metricsArray.length === 0) {
    console.error('Formato de dados de métrica inválido:', metricsArray);
    return [];
  }
  //console.log("adjustTimestamps", intervalInSeconds, ":", samplePeriodInSeconds, ":", endTimeString, ":", metricsArray)
  const numberOfPoints = Math.floor(intervalInSeconds / samplePeriodInSeconds);
  const LONG_INTERVAL_THRESHOLD = 86400; // 1 dia em segundos
  const isLongInterval = intervalInSeconds > LONG_INTERVAL_THRESHOLD;
  const endTime = new Date(endTimeString);
  const startTime = new Date(endTime.getTime() - intervalInSeconds * 1000);

  return metricsArray.map(metric => {
    if (!metric.data || !Array.isArray(metric.data)) {
      return null;
    }

    // Inicializa o array ajustado com y=0
    const adjustedData = new Array(numberOfPoints).fill(0).map((_, i) => {
      const time = new Date(startTime.getTime() + i * samplePeriodInSeconds * 1000);
      let formattedTime = '';
      if (isLongInterval) {
        const month = String(time.getMonth() + 1).padStart(2, '0');
        const day = String(time.getDate()).padStart(2, '0');
        const hours = String(time.getHours()).padStart(2, '0');
        const minutes = String(time.getMinutes()).padStart(2, '0');
        formattedTime = `${month}-${day}-${hours}:${minutes}`;
      } else {
        const hours = String(time.getHours()).padStart(2, '0');
        const minutes = String(time.getMinutes()).padStart(2, '0');
        formattedTime = `${hours}:${minutes}`;
      }
      return { x: formattedTime, y: 0 };
    });

    // Mapeia os valores reais nos pontos ajustados
    metric.data.forEach(point => {
      if (point.x && typeof point.x === 'string') {
        const pointTime = new Date(point.x);
        const index = Math.floor((pointTime - startTime) / (samplePeriodInSeconds * 1000));
        if (index >= 0 && index < adjustedData.length) {
          adjustedData[index].y = point.y;
        }
      }
    });

    // **Remover o último ponto**
    if (adjustedData.length > 0) {
      adjustedData.pop(); // Remove o último elemento do array
    }

    return {
      id: metric.id,
      data: adjustedData
    };
  }).filter(metric => metric !== null);
}


/*function MakeCWParams(Edges, Nodes, NodeID) {
  let SourceID = FindNodeNonGraphNHead(Edges, Nodes, NodeID);
  const NodesWithSufix = AdjustSufixName(Edges, Nodes, SourceID, true);
  const SourceName = NodesWithSufix.data.Param[1][1];
 
  let IntervalSource = Nodes[NodeID].data.Param[3][2][1][2][1][1];
  let IntervalTime = Time[IntervalSource] * 1000;
  const intervalInSeconds = Time[IntervalSource];
 
  let SamplePeriodInSeconds = SamplePeriods[IntervalSource];
  Nodes[NodeID].data.MaxCounter = SamplePeriodInSeconds / 60;
  let [Cloud, Region] = FindRegionAndCloud(Nodes, NodeID);
  let RegionName = Nodes[Region].data.Param[2][2];
  let RoleARN = Nodes[Cloud].data.Param[5][1];
  let JSONMetrics = ListaStandard[ResourceLookUp["GraphN"]]["GeneralParam"]["ListMetrics"];
  let MetricsArray = [];
  let IDExtract;
  let LinkedName, JSONVars, subObject;
  let Metric, NewJSONDimensions;
  for (let i = 0; i < 4; i++) {
    let Metrics = Nodes[NodeID].data.Param[3][2][1][2][2 + i][2][1][1];
    console.log("Metrics", Metrics)
    let IsEnable = Nodes[NodeID].data.Param[3][2][1][2][2 + i][2][2][1];
    if (IsEnable) {
      Metric = Metrics["Metric"];
      let InputNodeType = Metrics.InputNodeType;
      if (InputNodeType === "NamespaceN") {
        IDExtract = "Custom"
        NewJSONDimensions = [];
        subObject = Nodes[SourceID].data.Metrics;
      } else {
        subObject = JSONMetrics[InputNodeType];
        let IDExtract;
        IDExtract = subObject.IDExtract;
      }
      console.log("subObject", subObject)
      const secondKey = Object.keys(subObject)[0];
      const secondSubObject = subObject[secondKey];
      JSONVars = secondSubObject[Metric];
      const JSONDimensions = JSONVars['Dimensions'];
      console.log("JSONDimensions", JSONDimensions)
      NewJSONDimensions = JSON.parse(JSON.stringify(JSONDimensions))
      // Garantir que Dimensions seja uma lista de dicionários
      if (i == 0) {
        let LinkedID;
        if (JSONDimensions.length == 2) {
          const Value = JSONDimensions[0].Value.split(":");
          // Criar cópias independentes usando a cópia profunda do Value
          let LinkedTypeList = [];
          if (Value.length > 2) {
            LinkedTypeList = Value[2].split(",");
            console.log(LinkedTypeList);
          } else {
            console.log("Value[2] does not exist.");
          }
          const Operation = Value[1];
          // Agora você pode modificar JSONDimensions[0].Value sem impactar LinkedType e Operation
          NewJSONDimensions[0].Value = Value[0];
          console.log("LinkedTypeList", LinkedTypeList, Operation, Value[0])
          for (let j = 0; j < LinkedTypeList.length; j++) {
            const LinkedType = LinkedTypeList[j];
            if (Operation === "Source") {
              let ListSource = SearchNodesSource(Edges, Nodes, Nodes[SourceID], LinkedType);
              if (ListSource.length > 0) {
                LinkedID = parseInt(ListSource[0])
                LinkedName = Nodes[LinkedID].data.Param[1][1];
                console.log("LinkedName", LinkedName)
                break;
              }
            }
            if (Operation === "Target") {
              let ListTarget = SearchNodesTarget(Edges, Nodes, Nodes[SourceID], LinkedType);
              if (ListTarget.length > 0) {
                LinkedID = parseInt(ListTarget[0])
                LinkedName = Nodes[LinkedID].data.Param[1][1];
                console.log("LinkedName", LinkedName)
                break;
              }
            }
            if (Operation === "HeadSource") {
              LinkedID = FindNodeSourceHead(Edges, Nodes, LinkedType, "all", SourceID)[0];
              break;
            }
          }
          console.log("LinkedID", LinkedID)
          if (LinkedTypeList.length > 0) {
            LinkedName = Nodes[LinkedID].data.Param[1][1];
          }
        }
      }
      if (!Array.isArray(NewJSONDimensions)) {
        NewJSONDimensions = [NewJSONDimensions];
      }
      let ReplaceNameSpace = false;
      let ValidStatistics;
      if (InputNodeType === "CWMetricFilterN") {
        ValidStatistics = Metrics["ValidStatistics"];
        NewJSONDimensions = [];
      } else {
        ValidStatistics = Metrics["ValidStatistics"] || JSONVars['ValidStatistics'][0];
        ReplaceNameSpace = JSONVars["ReplaceNameSpace"];
      }
      console.log("ReplaceNameSpace", ReplaceNameSpace)
      let metricItem = {
        Metric: Metric,
        ValidStatistics: ValidStatistics,
        NameSpace: ReplaceNameSpace ? ReplaceNameSpace : Metrics["NameSpace"], // Check if ReplaceNameSpace is not null
        Dimensions: NewJSONDimensions,
        isEnabled: IsEnable
      };
      let ListRemove = [];
      for (let j = 0; j < NewJSONDimensions.length; j++) {
        let Value = NewJSONDimensions[j].Value;
        let Key = NewJSONDimensions[j].Name;
 
        if (Value === "$Var") {
          let NewValue = Metrics[Key];
          metricItem.Dimensions[j]["Value"] = NewValue;
          if (NewValue === "All") {
            ListRemove.push(j);
          }
        }
      }
      // Remover dimensões desnecessárias
      for (let j = ListRemove.length; j > 0; j--) {
        metricItem.Dimensions.splice(ListRemove[j - 1], 1);
      }
      MetricsArray.push(metricItem);
    }
  }
  var EnabledMetrics = MetricsArray.filter(metric => metric.isEnabled);
  let Unit = JSONVars?.Units || "";
  Unit = String(Unit).replace("/", "_");
  var ParamMetrics = {
    StartTime: new Date(Date.now() - IntervalTime).toISOString(),
    EndTime: new Date().toISOString(),
    MetricDataQueries: EnabledMetrics.map((metric, index) => {
      const PreNewID = "query_" + metric.Metric + (metric.ValidStatistics ? "_" + metric.ValidStatistics : "") + "_" + Unit;
      const newID = PreNewID.replace(/[^a-zA-Z0-9_]/g, '_');
      let query = {
        Id: newID,
        MetricStat: {
          Metric: {
            Namespace: metric["NameSpace"],
            MetricName: metric.Metric
          },
          Period: SamplePeriodInSeconds,
          Stat: metric.ValidStatistics
        }
      };
 
      if (metric.Dimensions) {
        query.MetricStat.Metric.Dimensions = metric.Dimensions;
      }
      return query;
    })
  };
  console.log("ParamMetrics", ParamMetrics)
  return [RoleARN, RegionName, ParamMetrics, SourceName, intervalInSeconds, SamplePeriodInSeconds, IDExtract, LinkedName];
}*/

/*function MakeCWParamsBatch(Edges, Nodes, NodeID) {
  let SourceID = FindNodeNonGraphNHead(Edges, Nodes, NodeID);
  if (SourceID === undefined || SourceID === null) {
    console.error("SourceID não encontrado.");
    return { RoleARN: null, RegionName: "", SourceName: "", ParamMetricsList: [], Interval: null };
  }
  let ARN = Nodes[SourceID].data?.ARN;
  if (!ARN) {
    console.error("ARN não encontrado.");
    return { RoleARN: null, RegionName: "", SourceName: "", ParamMetricsList: [], Interval: null };
  }
  const NodesWithSufix = AdjustSufixName(Edges, Nodes, SourceID, true);
  const SourceName = NodesWithSufix.data?.Param?.[1]?.[1] || "";
  let [Cloud, Region] = FindRegionAndCloud(Nodes, NodeID);
  if (Cloud === undefined || Region === undefined) {
    console.error("Cloud ou Region não encontrados.");
    return { RoleARN: null, RegionName: "", SourceName: "", ParamMetricsList: [], Interval: null };
  }
  let LinkedID;
  let RegionName = Nodes[Region].data.Param?.[2]?.[2] || "";
  let RoleARN = Nodes[Cloud].data.Param?.[5]?.[1] || "";
  if (!RoleARN) {
    console.error("RoleARN não encontrado.");
    return { RoleARN: null, RegionName: "", SourceName: "", ParamMetricsList: [], Interval: null };
  }
  // Preparação das métricas
  let JSONMetrics = ListaStandard?.[ResourceLookUp["GraphN"]]?.["GeneralParam"]?.["ListMetrics"];
  //console.log("JSONMetrics:", JSONMetrics);
  if (!JSONMetrics) {
    return { RoleARN: null, RegionName: "", SourceName: "", ParamMetricsList: [], Interval: null };
  }
  const TagName = Nodes[SourceID].data.Param?.[1]?.[1] || "";
  const GraphTimeIndex = Nodes[NodeID].data.Param[3][2][1][2][1][1];
  // Verificar se GraphTimeIndex está definido e válido
  if (GraphTimeIndex === undefined || !Time.hasOwnProperty(GraphTimeIndex)) {
    //console.error("GraphTimeIndex inválido ou não definido.");
    return { RoleARN: null, RegionName: "", SourceName: "", ParamMetricsList: [], Interval: null };
  }
  const intervalIndex = GraphTimeIndex.toString();
  const IntervalTime = Time[intervalIndex] * 1000; // Convertendo para milissegundos
  const intervalInSeconds = Time[intervalIndex];
  const SamplePeriodInSeconds = SamplePeriods[intervalIndex];
  if (Nodes[NodeID].data) {
    Nodes[NodeID].data.MaxCounter = SamplePeriodInSeconds / 60;
    //console.log("Atualizado MaxCounter:", Nodes[NodeID].data.MaxCounter);
  } else {
    //console.warn("Nodes[NodeID].data não está definido.");
  }
  let currentMetricsArray = [];
  for (let i = 0; i < 4; i++) {
    let Metrics = Nodes[NodeID].data.Param?.[3]?.[2]?.[1]?.[2]?.[2 + i]?.[2]?.[1]?.[1];
    let IsEnable = Nodes[NodeID].data.Param?.[3]?.[2]?.[1]?.[2]?.[2 + i]?.[2]?.[2]?.[1];
    if (!IsEnable) continue;
    let Metric = Metrics["Metric"];
    let InputNodeType = Metrics.InputNodeType;
    //console.log(`Métrica ${i} - InputNodeType:`, InputNodeType);
    let subObject, IDExtract;
    let NewJSONDimensions;
    if (InputNodeType === "NamespaceN") {
      IDExtract = "Custom";
      NewJSONDimensions = [];
      subObject = Nodes[SourceID].data.Metrics;
      //console.log("InputNodeType é 'NamespaceN', subObject:", subObject);
    } else {
      subObject = JSONMetrics[InputNodeType];
      IDExtract = subObject?.IDExtract || { delimiter: '/', positions: [-1] };
      //console.log(`InputNodeType é '${InputNodeType}', subObject:`, subObject);
    }
    if (!subObject) {
      console.warn(`subObject para InputNodeType '${InputNodeType}' não encontrado.`);
      continue; // Pular para a próxima métrica
    }
    const secondKey = Object.keys(subObject)[0];
    const secondSubObject = subObject[secondKey];
    let JSONVars = secondSubObject?.[Metric];
    //console.log(`JSONVars para Métrica '${Metric}':`, JSONVars);
    if (!JSONVars) {
      console.warn(`JSONVars para Métrica '${Metric}' não encontrado.`);
      continue; // Pular para a próxima métrica
    }
    const JSONDimensions = JSONVars['Dimensions'];
    //console.log("JSONDimensions:", JSONDimensions);
    NewJSONDimensions = JSONDimensions ? JSON.parse(JSON.stringify(JSONDimensions)) : [];
    //console.log("NewJSONDimensions após cópia:", NewJSONDimensions);
    let LinkedName = null;
    // Obtenção do LinkedName se necessário
    if (i === 0) {
      //console.log("Processando LinkedName para a primeira métrica.");
      if (JSONDimensions.length === 2) {
        const Value = JSONDimensions[0].Value.split(":");
        //console.log("Value após split:", Value);
        let LinkedTypeList = [];
        if (Value.length > 2) {
          LinkedTypeList = Value[2].split(",");
          //console.log("LinkedTypeList:", LinkedTypeList);
        } else {
          //console.log("Value[2] não existe.");
        }
        const Operation = Value[1];
        NewJSONDimensions[0].Value = Value[0];
        //console.log("Operação:", Operation, "LinkedTypeList:", LinkedTypeList, "Value[0]:", Value[0]);
        for (let j = 0; j < LinkedTypeList.length; j++) {
          const LinkedType = LinkedTypeList[j];
          //console.log(`Processando LinkedType: ${LinkedType} com Operação: ${Operation}`);
          if (Operation === "Source") {
            let ListSource = SearchNodesSource(Edges, Nodes, Nodes[SourceID], LinkedType);
            //console.log("ListSource:", ListSource);
            if (ListSource.length > 0) {
              LinkedID = parseInt(ListSource[0]);
              LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
              //console.log("LinkedName (Source):", LinkedName);
              break;
            }
          }
          if (Operation === "Target") {
            let ListTarget = SearchNodesTarget(Edges, Nodes, Nodes[SourceID], LinkedType);
            //console.log("ListTarget:", ListTarget);
            if (ListTarget.length > 0) {
              LinkedID = parseInt(ListTarget[0]);
              LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
              //console.log("LinkedName (Target):", LinkedName);
              break;
            }
          }
          if (Operation === "HeadSource") {
            let HeadSources = FindNodeSourceHead(Edges, Nodes, LinkedType, "all", SourceID);
            console.log("HeadSources:", HeadSources);
            if (HeadSources.length > 0) {
              LinkedID = parseInt(HeadSources[0]);
              LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
              console.log("LinkedName (HeadSource):", LinkedName);
              break;
            }
          }
        }
 
        console.log("LinkedID:", LinkedID);
        if (LinkedTypeList.length > 0 && LinkedID !== undefined) {
          LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
          //console.log("LinkedName final:", LinkedName);
        }
      } else {
        //console.log("JSONDimensions.length !== 2, pulando LinkedName.");
      }
    }
 
    if (!Array.isArray(NewJSONDimensions)) {
      NewJSONDimensions = [NewJSONDimensions];
      //console.log("Convertido NewJSONDimensions para array:", NewJSONDimensions);
    }
    let ReplaceNameSpace = false;
    let ValidStatistics;
    if (InputNodeType === "CWMetricFilterN") {
      ValidStatistics = Metrics["ValidStatistics"];
      NewJSONDimensions = [];
      //console.log("InputNodeType é 'CWMetricFilterN', ValidStatistics:", ValidStatistics);
    } else {
      ValidStatistics = Metrics["ValidStatistics"] || JSONVars['ValidStatistics']?.[0];
      ReplaceNameSpace = JSONVars["ReplaceNameSpace"] || false;
      //console.log("ValidStatistics:", ValidStatistics, "ReplaceNameSpace:", ReplaceNameSpace);
    }
    let metricItem = {
      Metric: Metric,
      ValidStatistics: ValidStatistics,
      NameSpace: ReplaceNameSpace ? ReplaceNameSpace : Metrics["NameSpace"],
      Dimensions: NewJSONDimensions,
      isEnabled: IsEnable,
      Unit: JSONVars?.Units || "" // Adiciona 'Unit' ao metricItem
    };
    //console.log("metricItem antes de substituições:", metricItem);
    let ListRemove = [], ResourceID;
    for (let j = 0; j < NewJSONDimensions.length; j++) {
      let Value = NewJSONDimensions[j].Value;
      let Key = NewJSONDimensions[j].Name;
      //console.log(`Substituindo placeholder em Dimensions[${j}]: Key=${Key}, Value=${Value}`);
      // Substituição dos placeholders diretamente
      if (Value === "$Var") {
        let NewValue = Metrics[Key];
        metricItem.Dimensions[j]["Value"] = NewValue;
        if (NewValue === "All") {
          ListRemove.push(j);
        }
      } else if (Value.includes("$ID")) {
        if (LinkedID) {
          if (j == 0) {
            const ARNLinked = Nodes[LinkedID].data.ARN;
            ResourceID = extractResourceIdFromArn(ARNLinked, IDExtract);
          } else {
            ResourceID = extractResourceIdFromArn(ARN, IDExtract);
          }
        } else {
          ResourceID = extractResourceIdFromArn(ARN, IDExtract);
        }
        metricItem.Dimensions[j]["Value"] = ResourceID;
      } else if (Value === "$Name") {
        if (NewJSONDimensions.length > 1 && LinkedName) {
          if (j === 0) {
            metricItem.Dimensions[j]["Value"] = LinkedName;
          } else {
            metricItem.Dimensions[j]["Value"] = TagName;
          }
        } else {
          metricItem.Dimensions[j]["Value"] = TagName;
        }
      } else if (Value === "$name") {
        if (NewJSONDimensions.length > 1 && LinkedName) {
          if (j === 0) {
            metricItem.Dimensions[j]["Value"] = LinkedName;
          } else {
            metricItem.Dimensions[j]["Value"] = TagName.toLowerCase();
          }
        } else {
          metricItem.Dimensions[j]["Value"] = TagName.toLowerCase();
        }
      }
    }
    if (ListRemove.length > 0) {
      for (let j = ListRemove.length; j > 0; j--) {
        const indexToRemove = ListRemove[j - 1];
        metricItem.Dimensions.splice(indexToRemove, 1);
      }
    }
    // Criação do Novo ID Único com prefixo <NodeID><i>_
    const idOriginal = `query_${metricItem.Metric}${metricItem.ValidStatistics ? `_${metricItem.ValidStatistics}` : ""}_${metricItem.Unit}`;
    const PreNewID = `c${NodeID}${i}_${idOriginal}`;
    const newID = PreNewID.replace(/[^a-zA-Z0-9_]/g, '_');
    metricItem.newID = newID;
    currentMetricsArray.push(metricItem);
  }
  // Filtrar métricas habilitadas para o intervalo atual
  const EnabledMetrics = currentMetricsArray.filter(metric => metric.isEnabled);
  // Definir Unit com base nas métricas habilitadas
  let Unit = EnabledMetrics.length > 0 ? EnabledMetrics[0].Unit : "";
  Unit = String(Unit).replace("/", "_");
  const ParamMetrics = {
    StartTime: new Date(Date.now() - IntervalTime).toISOString(),
    EndTime: new Date().toISOString(),
    MetricDataQueries: EnabledMetrics.map((metric, index) => {
      const queryId = metric.newID;
      const query = {
        Id: queryId, // Substituição pelo novo ID
        MetricStat: {
          Metric: {
            Namespace: metric["NameSpace"],
            MetricName: metric.Metric,
            Dimensions: metric.Dimensions
          },
          Period: SamplePeriodInSeconds,
          Stat: metric.ValidStatistics
        }
      };
      return query;
    })
  };
  // Adicionar ParamMetrics atual à lista
  const ParamMetricsList = [{
    IntervalIndex: intervalIndex,
    IntervalInSeconds: intervalInSeconds,
    SamplePeriodInSeconds: SamplePeriodInSeconds,
    ParamMetrics: ParamMetrics
  }];
  return { RoleARN: RoleARN, RegionName: RegionName, SourceName: SourceName, ParamMetricsList: ParamMetricsList, Interval: GraphTimeIndex };
}*/


function extractNodeIndex(metricId) {
  //console.log("metricId", metricId);
  const parts = metricId.split('_');
  if (parts.length < 2) return null;

  // Remove o primeiro caractere ('c') e o último caractere do nodeIdStr
  const nodeIdStr = parts[0].slice(1, -1); // Exemplo: "c541" → "54"
  const nodeIndex = parseInt(nodeIdStr, 10);
  //console.log("nodeIndex", nodeIndex);

  return isNaN(nodeIndex) ? null : nodeIndex;
}

async function UpdateMetrics(Edges, Nodes, setNodes, ShoudIncrement, ShowGraph, GlobalShowGraphFirstTime, NodeID = 0) {
  console.log("UpdateMetrics")
  //try {
  let ListNodes = []; // Lista para armazenar os índices dos nodes que serão processados
  if (ShowGraph || GlobalShowGraphFirstTime) {
    // Itera sobre todos os nodes para identificar quais devem ser processados
    if (NodeID == 0) {
      for (let i = 0; i < Nodes.length; i++) {
        let NodeType = Nodes[i].type;
        if (NodeType === "GraphN") {
          const Selected = Nodes[i].selected || Nodes[i].data.img === "" || Nodes[i].data.img === undefined;
          let Counter = Nodes[i].data.Counter || 0;
          let MaxCounter = Nodes[i].data.MaxCounter || 0;
          // Condição para determinar se o node deve ser processado
          if ((((Counter >= MaxCounter) && (MaxCounter > 3)) || ((Counter >= 5) && (MaxCounter <= 3))) || Selected || (MaxCounter === 0)) {
            ListNodes.push(i); // Armazena o índice do node
            Nodes[i].data.Counter = 0; // Reseta o contador

          } else {
            if (ShoudIncrement) {
              Counter += 1;
              Nodes[i].data.Counter = Counter;
            }
          }
        }
      }
    } else {
      ListNodes = [NodeID]
    }
    console.log("ListNodes", ListNodes);
    // Inicializa o dicionário para classificar as métricas
    let classifiedMetrics = {};
    // Percorre cada node selecionado e classifica as métricas
    for (let nodeIndex of ListNodes) {
      const ParamMetrics = MakeCWParamsBatch(Edges, Nodes, nodeIndex);
      // Verifica se ParamMetrics é válido e contém ParamMetricsList
      if (ParamMetrics && ParamMetrics.ParamMetricsList && ParamMetrics.ParamMetricsList.length > 0) {
        const { RoleARN, RegionName, ParamMetricsList, Interval, SamplePeriodInSeconds } = ParamMetrics;
        // Inicializa as chaves no dicionário se ainda não existirem
        if (!classifiedMetrics[RoleARN]) {
          classifiedMetrics[RoleARN] = {};
        }
        if (!classifiedMetrics[RoleARN][RegionName]) {
          classifiedMetrics[RoleARN][RegionName] = {};
        }
        if (!classifiedMetrics[RoleARN][RegionName][Interval]) {
          classifiedMetrics[RoleARN][RegionName][Interval] = {
            StartTime: "",
            EndTime: "",
            MetricDataQueries: []
          };
        }
        // Atualiza StartTime e EndTime se ainda não estiverem definidos
        if (!classifiedMetrics[RoleARN][RegionName][Interval].StartTime && ParamMetricsList.length > 0) {
          classifiedMetrics[RoleARN][RegionName][Interval].StartTime = ParamMetricsList[0].ParamMetrics.StartTime;
          classifiedMetrics[RoleARN][RegionName][Interval].EndTime = ParamMetricsList[0].ParamMetrics.EndTime;
        }
        // Adiciona as MetricDataQueries à lista correspondente
        classifiedMetrics[RoleARN][RegionName][Interval].MetricDataQueries.push(
          ...ParamMetricsList.map(pm => pm.ParamMetrics.MetricDataQueries).flat()
        );
      }
    }
    let nodeMetricsMap = {};
    // Itera sobre cada combinação de RoleARN, RegionName e Interval
    for (const [RoleARN, regions] of Object.entries(classifiedMetrics)) {
      for (const [RegionName, intervals] of Object.entries(regions)) {
        for (const [Interval, data] of Object.entries(intervals)) {
          const payload = {
            StartTime: data.StartTime,
            EndTime: data.EndTime,
            MetricDataQueries: data.MetricDataQueries
          };
          const raw = [14, payload, RoleARN, RegionName];
          console.log("raw", raw)
          const Resp = await CallAPI(APIPricing, raw, true);
          let metricData = Resp.body;
          // Validação dos dados retornados
          console.log("metricData", metricData)
          if (typeof metricData !== 'object') {
            continue; // Pula para a próxima iteração se os dados forem inválidos
          }

          if (metricData.Resp !== undefined) {
            metricData = metricData.Resp; // Ajusta conforme a estrutura da resposta da API
          }
          // Ajusta os timestamps das métricas
          if (!Time[Interval] || !SamplePeriods[Interval]) {
            //console.error(`Time ou SamplePeriods para Intervalo ${Interval} não estão definidos.`);
            continue;
          }
          metricData = adjustTimestamps(metricData, Time[Interval], SamplePeriods[Interval], data.EndTime);
          console.log("metricData adjustTimestamps", metricData)
          // Itera sobre os resultados das métricas e popula nodeMetricsMap
          if (Array.isArray(metricData)) {
            for (const result of metricData) {
              const metricId = result.id; // 'id' original com prefixo
              const nodeIndex = extractNodeIndex(metricId);
              if (nodeIndex === null) {
                console.warn(`Não foi possível extrair nodeIndex do metricId: ${metricId}`);
                continue; // Pula para a próxima métrica se não puder extrair o nodeIndex
              }
              if (!nodeMetricsMap[nodeIndex]) {
                nodeMetricsMap[nodeIndex] = [];
              }
              nodeMetricsMap[nodeIndex].push(result);
            }
          }
        }
      }
    }
    console.log("nodeMetricsMap", nodeMetricsMap);
    // Itera sobre cada nodeIndex para gerar e atribuir gráficos
    for (const [nodeIndexStr, metrics] of Object.entries(nodeMetricsMap)) {
      const nodeIndex = parseInt(nodeIndexStr, 10);
      if (isNaN(nodeIndex) || nodeIndex < 0 || nodeIndex >= Nodes.length) {
        console.warn(`nodeIndex inválido: ${nodeIndexStr}`);
        continue; // Pula para o próximo node se o nodeIndex for inválido
      }
      // **Remover o prefixo até o primeiro '_' dos IDs das métricas**
      const metricsWithoutPrefix = metrics.map(metric => ({
        ...metric,
        id: metric.id.substring(metric.id.indexOf('_') + 1) // Remove tudo antes do primeiro '_'
      }));
      const metricDataForChart = metricsWithoutPrefix; // Passa as métricas sem o prefixo
      const node = Nodes[nodeIndex];
      const Grid = true; // Pode ajustar conforme a necessidade
      const Legends = true; // Pode ajustar conforme a necessidade
      const LastUpDate = formatDate(new Date());
      const GraphTimeIndex = node.data.Param[3][2][1][2][1][1];
      const intervalInSeconds = Time[GraphTimeIndex];
      const SamplePeriodInSeconds = SamplePeriods[GraphTimeIndex];
      // try {
      console.log("metricDataForChart", metricDataForChart)
      const imageSrc = await createAndCaptureChartSequentially(metricDataForChart, Grid, Legends, intervalInSeconds, LastUpDate);
      node.data.img = imageSrc;
      setNodes(prevNodes => {
        const newNodes = [...prevNodes];
        newNodes[nodeIndex] = {
          ...prevNodes[nodeIndex],
          data: {
            ...prevNodes[nodeIndex].data,
            img: imageSrc
          }
        };
        return newNodes;
      });
      //} catch (chartError) {
      //  console.error(`Erro ao criar o gráfico para nodeIndex ${nodeIndex}:`, chartError);
      //}
    }
  }
  //} catch (error) {
  //  console.error("Erro na função UpdateMetrics:", error);
  //}
}

function getMetricsParams(Edges, Nodes, NodeID) {
  const SourceID = FindNodeNonGraphNHead(Edges, Nodes, NodeID);
  if (SourceID === undefined || SourceID === null) {
    console.error("SourceID not found.");
    return null;
  }
  let ARN = Nodes[SourceID].data?.ARN;
  //if (!ARN) {
  //  console.error("ARN not found.");
  // return null;
  //}
  const NodesWithSufix = AdjustSufixName(Edges, Nodes, SourceID, true);
  const SourceName = NodesWithSufix.data?.Param?.[1]?.[1] || "";
  let [Cloud, Region] = FindRegionAndCloud(Nodes, NodeID);
  if (Cloud === undefined || Region === undefined) {
    console.error("Cloud or Region not found.");
    return null;
  }
  let RegionName = Nodes[Region].data.Param?.[2]?.[2] || "";
  let RoleARN = Nodes[Cloud].data.Param?.[5]?.[1] || "";
  if (!RoleARN) {
    console.error("RoleARN not found.");
    return null;
  }
  let JSONMetrics = ListaStandard?.[ResourceLookUp["GraphN"]]?.["GeneralParam"]?.["ListMetrics"];
  if (!JSONMetrics) {
    return null;
  }
  const GraphTimeIndex = Nodes[NodeID].data.Param[3][2][1][2][1][1];
  if (GraphTimeIndex === undefined || !Time.hasOwnProperty(GraphTimeIndex)) {
    return null;
  }
  const intervalIndex = GraphTimeIndex.toString();
  const IntervalTime = Time[intervalIndex] * 1000; // Convert to milliseconds
  const SamplePeriodInSeconds = SamplePeriods[intervalIndex];
  if (Nodes[NodeID].data) {
    Nodes[NodeID].data.MaxCounter = SamplePeriodInSeconds / 60;
  }
  let currentMetricsArray = [], LinkedID;
  for (let i = 0; i < 4; i++) {
    let Metrics = Nodes[NodeID].data.Param?.[3]?.[2]?.[1]?.[2]?.[2 + i]?.[2]?.[1]?.[1];
    let IsEnable = Nodes[NodeID].data.Param?.[3]?.[2]?.[1]?.[2]?.[2 + i]?.[2]?.[2]?.[1];
    if (!IsEnable) continue;

    let Metric = Metrics["Metric"];
    let InputNodeType = Metrics.InputNodeType;
    let subObject, IDExtract;
    let NewJSONDimensions;
    if (InputNodeType === "NamespaceN") {
      IDExtract = "Custom";
      NewJSONDimensions = [];
      subObject = Nodes[SourceID].data.Metrics;
    } else {
      subObject = JSONMetrics[InputNodeType];
      IDExtract = subObject?.IDExtract || { delimiter: '/', positions: [-1] };
    }
    if (!subObject) {
      console.warn(`subObject for InputNodeType '${InputNodeType}' not found.`);
      continue; // Skip to the next metric
    }
    const secondKey = Object.keys(subObject)[0];
    const secondSubObject = subObject[secondKey];
    let JSONVars = secondSubObject?.[Metric];
    if (!JSONVars) {
      console.warn(`JSONVars for Metric '${Metric}' not found.`);
      continue; // Skip to the next metric
    }
    const JSONDimensions = JSONVars['Dimensions'];
    NewJSONDimensions = JSONDimensions ? JSON.parse(JSON.stringify(JSONDimensions)) : undefined;
    let LinkedName = null;
    // Obtain LinkedName if necessary
    if (JSONDimensions) {
      if (i === 0) {
        if (JSONDimensions.length === 2) {
          const Value = JSONDimensions[0].Value.split(":");
          let LinkedTypeList = [];
          if (Value.length > 2) {
            LinkedTypeList = Value[2].split(",");
          }
          const Operation = Value[1];
          NewJSONDimensions[0].Value = Value[0];
          for (let j = 0; j < LinkedTypeList.length; j++) {
            const LinkedType = LinkedTypeList[j];
            if (Operation === "Source") {
              let ListSource = SearchNodesSource(Edges, Nodes, Nodes[SourceID], LinkedType);
              if (ListSource.length > 0) {
                LinkedID = parseInt(ListSource[0]);
                //LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
                break;
              }
            }
            if (Operation === "Target") {
              let ListTarget = SearchNodesTarget(Edges, Nodes, Nodes[SourceID], LinkedType);
              if (ListTarget.length > 0) {
                LinkedID = parseInt(ListTarget[0]);
                //LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
                break;
              }
            }
            if (Operation === "HeadSource") {
              let HeadSources = FindNodeSourceHead(Edges, Nodes, LinkedType, "all", SourceID);
              if (HeadSources.length > 0) {
                LinkedID = parseInt(HeadSources[0]);
                //LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
                break;
              }
            }
          }
          //if (LinkedTypeList.length > 0 && LinkedID !== undefined) {
          //  LinkedName = Nodes[LinkedID].data.Param?.[1]?.[1];
          //}
          if (LinkedID) {
            const SufixNewNode = AdjustSufixName(Edges, Nodes, LinkedID, true);
            LinkedName = SufixNewNode.data.Param[1][1];
          }
        }
      }
    }
    //if (!Array.isArray(NewJSONDimensions)) {
    //  NewJSONDimensions = [NewJSONDimensions];
    //}
    let ReplaceNameSpace = false;
    let ValidStatistics;
    let NameSpace;
    let Unit;
    if (InputNodeType === "CWMetricFilterN") {
      ValidStatistics = Metrics["ValidStatistics"];
      NewJSONDimensions = [];
    } else {
      ValidStatistics = Metrics["ValidStatistics"] || JSONVars['ValidStatistics']?.[0];
      ReplaceNameSpace = JSONVars["ReplaceNameSpace"] || false;
    }
    NameSpace = ReplaceNameSpace ? ReplaceNameSpace : Metrics["NameSpace"];
    Unit = JSONVars?.Units || "";
    let metricItem = {
      Metric: Metric,
      ValidStatistics: ValidStatistics,
      NameSpace: NameSpace,
      isEnabled: IsEnable,
      Unit: Unit
    };
    console.log("metricItem a", metricItem)
    let ListRemove = [], ResourceID;
    console.log("NewJSONDimensions", NewJSONDimensions)
    if (NewJSONDimensions) {
      console.log("NewJSONDimensions B", NewJSONDimensions)
      metricItem.Dimensions = NewJSONDimensions;

      for (let j = 0; j < NewJSONDimensions.length; j++) {
        let Value = NewJSONDimensions[j].Value;
        let Key = NewJSONDimensions[j].Name;
        // Direct substitution of placeholders
        if (Value === "$Var") {
          let NewValue = Metrics[Key];
          metricItem.Dimensions[j]["Value"] = NewValue;
          if (NewValue === "All") {
            ListRemove.push(j);
          }
        } else if (Value.includes("$ID")) {
          if (LinkedID) {
            if (j == 0) {
              const ARNLinked = Nodes[LinkedID].data.ARN;
              ResourceID = extractResourceIdFromArn(ARNLinked, IDExtract);
            } else {
              ResourceID = extractResourceIdFromArn(ARN, IDExtract);
            }
          } else {
            ResourceID = extractResourceIdFromArn(ARN, IDExtract);
          }
          metricItem.Dimensions[j]["Value"] = ResourceID;
        } else if (Value === "$Name") {
          if (NewJSONDimensions.length > 1 && LinkedName) {
            if (j === 0) {
              metricItem.Dimensions[j]["Value"] = LinkedName;
            } else {
              metricItem.Dimensions[j]["Value"] = SourceName;
            }
          } else {
            metricItem.Dimensions[j]["Value"] = SourceName;
          }
        } else if (Value === "$name") {
          if (NewJSONDimensions.length > 1 && LinkedName) {
            if (j === 0) {
              metricItem.Dimensions[j]["Value"] = LinkedName;
            } else {
              metricItem.Dimensions[j]["Value"] = SourceName.toLowerCase();
            }
          } else {
            metricItem.Dimensions[j]["Value"] = SourceName.toLowerCase();
          }
        }
      }

      if (ListRemove.length > 0) {
        for (let j = ListRemove.length; j > 0; j--) {
          const indexToRemove = ListRemove[j - 1];
          metricItem.Dimensions.splice(indexToRemove, 1);
        }
      }
    }
    console.log("metricItem z", metricItem)
    currentMetricsArray.push(metricItem);
  }
  const StartTime = new Date(Date.now() - IntervalTime).toISOString();
  const EndTime = new Date().toISOString();
  // Group metrics by Namespace
  let MetricsByNamespace = {};
  currentMetricsArray.forEach(metric => {
    const namespace = metric.NameSpace;
    if (!MetricsByNamespace[namespace]) {
      MetricsByNamespace[namespace] = [];
    }
    MetricsByNamespace[namespace].push(metric);
  });
  return {
    RoleARN: RoleARN,
    RegionName: RegionName,
    SourceName: SourceName,
    StartTime: StartTime,
    EndTime: EndTime,
    Period: SamplePeriodInSeconds,
    MetricsByNamespace: MetricsByNamespace,
    Interval: GraphTimeIndex,
    ResourceName: SourceName
  };
}

function MakeCWParamsBatch(Edges, Nodes, NodeID) {
  const metricsParams = getMetricsParams(Edges, Nodes, NodeID);
  if (!metricsParams) {
    return { RoleARN: null, RegionName: "", SourceName: "", ParamMetricsList: [], Interval: null };
  }

  const {
    RoleARN,
    RegionName,
    SourceName,
    StartTime,
    EndTime,
    Period,
    MetricsByNamespace,
    Interval
  } = metricsParams;
  console.log("metricsParams xxx ", metricsParams)

  // Build ParamMetricsList
  let ParamMetricsList = [];

  Object.keys(MetricsByNamespace).forEach(namespace => {
    const metricsArray = MetricsByNamespace[namespace];
    // Filter enabled metrics
    const EnabledMetrics = metricsArray.filter(metric => metric.isEnabled);
    // Define Unit based on enabled metrics
    let Unit = EnabledMetrics.length > 0 ? EnabledMetrics[0].Unit : "";
    Unit = String(Unit).replace("/", "_");

    const ParamMetrics = {
      StartTime: StartTime,
      EndTime: EndTime,
      MetricDataQueries: EnabledMetrics.map((metric, index) => {
        // Create a unique ID with prefix <NodeID><i>_
        const idOriginal = `query_${metric.Metric}${metric.ValidStatistics ? `_${metric.ValidStatistics}` : ""}_${metric.Unit}`;
        const PreNewID = `c${NodeID}${index}_${idOriginal}`;
        const newID = PreNewID.replace(/[^a-zA-Z0-9_]/g, '_');
        metric.newID = newID;

        const query = {
          Id: newID,
          MetricStat: {
            Metric: {
              Namespace: metric.NameSpace,
              MetricName: metric.Metric,
              ...(metric.Dimensions && { Dimensions: metric.Dimensions }) // Inclui Dimensions apenas se existir
            },
            Period: Period,
            Stat: metric.ValidStatistics
          }
        };

        return query;
      })
    };

    // Add current ParamMetrics to the list
    ParamMetricsList.push({
      IntervalIndex: Interval.toString(),
      IntervalInSeconds: Time[Interval.toString()],
      SamplePeriodInSeconds: SamplePeriods[Interval.toString()],
      ParamMetrics: ParamMetrics
    });
  });

  return {
    RoleARN: RoleARN,
    RegionName: RegionName,
    SourceName: SourceName,
    ParamMetricsList: ParamMetricsList,
    Interval: Interval
  };
}


function extractResourceIdFromArn(resourceArn, IDExtract) {
  const delimiter = IDExtract.delimiter || '/';
  let positions = IDExtract.positions || [-1];

  if (!Array.isArray(positions)) {
    positions = [positions];
  }

  const finalArnPart = resourceArn.split(':').pop();
  const segments = finalArnPart.split(delimiter);

  const extractedParts = positions.map(position => {
    if (position < 0) {
      return segments[segments.length + position];
    }
    return segments[position];
  });

  const resourceId = extractedParts.join('/');
  return resourceId;
}

async function fetchAndProcessMetrics(Edges, Nodes, NodeID, GlobalToken, setNodes) {
  let SourceID = FindNodeNonGraphNHead(Edges, Nodes, NodeID);
  let ARN = Nodes[SourceID].data.ARN;
  if (!ARN) {
    const ListBlockNodes = [String(SourceID)]
    const [CloudID, RegionID] = FindRegionAndCloud(Nodes, String(NodeID));
    let RoleARN = Nodes[CloudID].data.Param[5][1];
    const NodesWithSufix = AdjustSufixName(Edges, Nodes, NodeID);
    const [RespStatus, GlobalStatus] = await UpdateStatus(Edges, Nodes, NodesWithSufix, ListBlockNodes, [], RoleARN)
    if (GlobalStatus[2]) {
      ARN = GlobalStatus[3];
      Nodes[SourceID].data.Deployed = true;
    }
  }
  UpdateMetrics(Edges, Nodes, setNodes, true, true, false, NodeID)
  console.log("passou aqui")
}

async function CallAPI(APIName, raw, isJSON = false) {
  const maxAttempts = 5; // Número máximo de tentativas
  let attempt = 0;
  let delay = 1000; // Tempo inicial de espera (1 segundo)
  while (attempt < maxAttempts) {
    try {
      attempt++;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const GlobalToken = sessionStorage.getItem("GlobalToken");
      myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw), // Convertendo o array para JSON
        redirect: 'follow'
      };
      const response = await fetch(APIName, requestOptions);
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
        // Verifica se o status é 500 para aplicar lógica de retry específica
        if (response.status === 500) {
          console.log(`Tentativa ${attempt} falhou com status 500. Tentando novamente após ${delay / 1000} segundos...`);
        }
      } else {
        if (isJSON) {
          const resultJson = await response.json();
          //console.log("Result (JSON):", resultJson);
          return resultJson;
        } else {
          const resultText = await response.text();
          //console.log("Result (Text):", resultText);
          return resultText;
        }
      }
    } catch (error) {
      console.error(`Error on attempt ${attempt}:`, error);
    }
    // Espera um intervalo crescente antes de tentar novamente (exponential backoff)
    await new Promise(resolve => setTimeout(resolve, delay));
    delay *= 2; // Duplica o tempo de espera a cada tentativa
  }
  console.log(`Todas as ${maxAttempts} tentativas falharam. Retornando null.`);
  return null; // Retorna null se todas as tentativas falharem
}



// Função para adicionar zero à esquerda em números menores que 10
function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

// Função para formatar a data em UTC
function formatDateUTC(date) {
  const year = date.getUTCFullYear().toString().slice(-2); // Últimos 2 dígitos do ano
  const day = padTo2Digits(date.getUTCDate());
  const month = padTo2Digits(date.getUTCMonth() + 1); // Meses são baseados em zero
  const hour = padTo2Digits(date.getUTCHours());
  const minute = padTo2Digits(date.getUTCMinutes());
  return `${year}/${day}/${month} ${hour}:${minute}`;
}

function formatDate(date, useUTC = false) {
  const year = useUTC ? date.getUTCFullYear().toString().slice(-2) : date.getFullYear().toString().slice(-2);
  const day = useUTC ? padTo2Digits(date.getUTCDate()) : padTo2Digits(date.getDate());
  const month = useUTC ? padTo2Digits(date.getUTCMonth() + 1) : padTo2Digits(date.getMonth() + 1); // Meses são baseados em zero
  const hour = useUTC ? padTo2Digits(date.getUTCHours()) : padTo2Digits(date.getHours());
  const minute = useUTC ? padTo2Digits(date.getUTCMinutes()) : padTo2Digits(date.getMinutes());

  return `${year}/${day}/${month} ${hour}:${minute}`;
}
const calculateAbsolutePosition = (nodes, node) => {
  let x = 0;
  let y = 0;
  let currentNode = node;
  while (true) {
    x += currentNode.position.x;
    y += currentNode.position.y;
    let ParentID = parseInt(currentNode.parentNode)
    if (ParentID === 0) {
      break;
    }
    currentNode = nodes[ParentID];
  }
  return { x, y };
};

const checkNodeVisibility = (nodes, transform, OnlyGraph = true) => {
  try {
    const { x: translateX, y: translateY, zoom } = transform;

    // Pré-calcula os tamanhos de viewport para os dois fatores
    const viewportWidthFactor92 = window.innerWidth * 0.92 / zoom;
    const viewportHeightFactor92 = window.innerHeight * 0.92 / zoom;
    const viewportWidthFactor110 = window.innerWidth * 1.1 / zoom;
    const viewportHeightFactor110 = window.innerHeight * 1.1 / zoom;

    let IsVisibleFactor92 = [];
    let IsVisibleFactor110 = [];

    for (let i = 1; i < nodes.length; i++) {
      const node = nodes[i];
      const NodeType = node.type;

      // Descarte antecipado de nodes que não interessam
      if (NodeType === "NullN" || (NodeType !== "GraphN" && OnlyGraph)) {
        IsVisibleFactor92[i] = false;
        IsVisibleFactor110[i] = false;
        continue;
      }

      // Descarte de nodes sem largura ou altura
      if (!node.width || !node.height) {
        IsVisibleFactor92[i] = false;
        IsVisibleFactor110[i] = false;
        continue;
      }

      // Cálculo da posição absoluta
      const { x, y } = calculateAbsolutePosition(nodes, node);
      const nodeX = x + translateX / zoom;
      const nodeY = y + translateY / zoom;
      const nodeWidth = node.width;
      const nodeHeight = node.height;

      // Calcula os limites do node
      const LeftTop = nodeX;
      const RightTop = nodeX + nodeWidth;
      const LeftBottom = nodeY;
      const RightBottom = nodeY + nodeHeight;

      // Verifica visibilidade para fator 0.92
      IsVisibleFactor92[i] = LeftTop < viewportWidthFactor92 && RightTop > 0 && LeftBottom < viewportHeightFactor92 && RightBottom > 0;

      // Verifica visibilidade para fator 1.1
      IsVisibleFactor110[i] = LeftTop < viewportWidthFactor110 && RightTop > 0 && LeftBottom < viewportHeightFactor110 && RightBottom > 0;
    }

    return { IsVisibleFactor92, IsVisibleFactor110 };

  } catch (error) {
    // Tratamento de erro: assume que todos os nodes são visíveis em caso de falha
    let IsVisibleError = Array(nodes.length).fill(true);
    return { IsVisibleFactor92: IsVisibleError, IsVisibleFactor110: IsVisibleError };
  }
};


async function refreshToken() {
  const RefreshToken = sessionStorage.getItem("RefreshToken");
  console.log("RefreshToken", RefreshToken)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
  const response = await fetch(APIAuth, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ "code": "RefreshToken", "refresh_token": RefreshToken })
  });
  const authData = await response.json();
  const parsedBody = JSON.parse(authData.body);
  console.log("parsedBody", parsedBody);
  const currentTime = new Date().getTime();
  const accessExpiresAt = currentTime + (parsedBody.access_expires_in - 90) * 1000;
  sessionStorage.setItem('AccessExpiresAt', accessExpiresAt);
  const globalToken = parsedBody.access_token;
  sessionStorage.setItem("GlobalToken", globalToken);
  console.log("new Token", globalToken);
}
let isAlertShown = false;

async function checkAndRefreshToken(Edges, Nodes, Stage, GlobalCognitoSub, GlobalToken) {
  const currentTime = new Date().getTime();
  const AccessExpiresAt = parseInt(sessionStorage.getItem('AccessExpiresAt'), 10);
  const RefreshExpiresAt = parseInt(sessionStorage.getItem('RefreshExpiresAt'), 10);
  //console.log("currentTime", currentTime, RefreshExpiresAt, AccessExpiresAt);
  if (currentTime > RefreshExpiresAt) {
    // Refresh token has expired, user needs to log in again
    if (!isAlertShown) {
      isAlertShown = true;
      alert('Session expired. Please log in again.');
      await Save(Edges, Nodes, GlobalCognitoSub, GlobalToken);
      window.location.replace('https://cloudman.pro');
    }
    return false;
  }

  if (currentTime > AccessExpiresAt) {
    console.log("?Novo Token");
    await refreshToken();
  }

  return sessionStorage.getItem('GlobalToken');
}

//não mais usada - remover
function modifyTerraformCode(code, bucketName, userId, stateName, region, BackendType) {
  code = code.replace(/shared_credentials_files.*?\n/gm, '');
  // Configuração do backend
  const backendConfig = `
  backend "s3" {
      bucket = "${bucketName}"
      key    = "states/${stateName}/terraform.tfstate"
      region = "${region}"
  }
  `;
  // Verificar e adicionar o backend 's3' no bloco 'terraform'
  const terraformBlockRegex = /(terraform\s*\{)/;
  const terraformBlockMatch = code.match(terraformBlockRegex);
  if (terraformBlockMatch) {
    const insertPosition = terraformBlockMatch.index + terraformBlockMatch[0].length;
    code = code.slice(0, insertPosition) + backendConfig + code.slice(insertPosition);
    //console.log("Backend 's3' added to the existing Terraform block.");
  } else {
    //console.log("No Terraform block found; please check the Code input.");
  }
  return code;
}

function FindAllNetworkNodeIDs(Edges, Nodes, TFID) {
  let allNodeIDs = [TFID]; // Lista para armazenar todos os IDs
  function recurse(currentNodeID) {
    // Usar SearchNodesTarget para encontrar todos os nodes filhos do node atual
    let targetList = SearchNodesTarget(Edges, Nodes, Nodes[currentNodeID], "TerraformN");
    // Iterar sobre cada target encontrado
    targetList.forEach(targetID => {
      let intTargetID = parseInt(targetID, 10); // Converter o ID para inteiro
      allNodeIDs.push(intTargetID); // Adicionar o ID à lista
      // Recursão para continuar a busca nos filhos
      recurse(intTargetID);
    });
  }
  recurse(TFID); // Começar a busca com o node raiz (TFID)
  return allNodeIDs; // Retornar a lista com todos os IDs encontrados
}

function FindStatesList(Edges, Nodes, BoxID, ListTerraform) {
  let ListStatesID = [];
  let ListStates = [];
  console.log("BoxID", BoxID)
  //encontra todos os nodes terraform dentro do box que delimita o stage
  console.log("ListTerraform", ListTerraform, BoxID)
  let IntListTerraform = [];
  // percorre todos os nodes terraform encontrados e encontra a rede até um backend e coloca em LocalListStatesID
  for (let i = 0; i < ListTerraform.length; i++) {
    IntListTerraform.push(parseInt(ListTerraform[i]))
  }
  for (let i = 0; i < IntListTerraform.length; i++) {
    let TFID = IntListTerraform[i];
    console.log("TFID", TFID)
    if (!ListStatesID.includes(TFID)) {
      let LoopCount = 0;
      while (true) {
        let ListFather = SearchNodesSource(Edges, Nodes, Nodes[TFID], "TerraformN");
        if (ListFather.length > 0) {
          TFID = parseInt(ListFather[0]);
          LoopCount += 1;
          if (LoopCount > 50) {
            break
          }
        } else {
          break;
        }
      }
      let List = FindAllNetworkNodeIDs(Edges, Nodes, TFID);
      ListStatesID = ListStatesID.concat(List);
      console.log("List", List)
    }
  }
  const NodesWithSufix = AdjustSufixName(Edges, Nodes, ListStatesID[0]);
  console.log("NodesWithSufix", NodesWithSufix)
  ListStatesID = ListStatesID.filter(item => IntListTerraform.includes(item));
  ListStatesID = [...new Set(ListStatesID)];
  console.log("ListStatesID", ListStatesID)
  for (let i = 0; i < ListStatesID.length; i++) {
    console.log("Passou aqui ", i)
    ListStates.push(NodesWithSufix[ListStatesID[i]].data.Param[1][1]);
  }
  console.log("ListStates Pre, ListStatesID", ListStates, ListStatesID)
  return [ListStates, ListStatesID];
}

function MakeStageStateFilesList(Edges, Nodes, BoxStageID, OldEdgesNodes, NewEdgesNodes, Stage) {
  let ListStates, ListStatesID, Version;
  let ListStatesBlue = [], ListStatesIDBlue = [];
  let ListTerraform;
  if (Stage === "test") { //Se for test não há blue green então deve encontra a lista de states dentro do box Stage
    ListTerraform = FindNodesChieldID(Nodes, BoxStageID, "TerraformN")
    console.log("ListTerraform", ListTerraform, BoxStageID)
    const List = FindStatesList(Edges, Nodes, BoxStageID, ListTerraform);
    ListStates = List[0];
    ListStatesID = List[1];
    const ListBoxVersion = FindNodesChieldID(Nodes, BoxStageID, "SBoxN")
    for (let i = 0; i < ListBoxVersion.length; i++) {
      const BoxVersionID = parseInt(ListBoxVersion[i]);
      const BoxVersionName = Nodes[BoxVersionID].data.Param[1][1].toLowerCase();
      if (BoxVersionName.includes("version")) {
        Version = Nodes[BoxVersionID].data.Param[4][1];
      }
    }
    console.log("PAssou xx test")
  }
  else {// Se não for test, então há blue e green e deve encontrar a List terraform dentro de cada box (blue e green)
    const ListBoxesBlueGreen = FindNodesChieldID(Nodes, BoxStageID, "SBoxN");
    console.log("ListBoxes", ListBoxesBlueGreen)
    for (let i = 0; i < ListBoxesBlueGreen.length; i++) {
      const BoxBlueID = parseInt(ListBoxesBlueGreen[i]);
      const BoxBlueName = Nodes[BoxBlueID].data.Param[1][1].toLowerCase();
      console.log("BoxBlueName", BoxBlueName)
      const BlueGreen = Nodes[BoxBlueID]?.data?.BlueGreen || "";
      console.log("BlueGreen", BlueGreen, BoxBlueID)
      if (BoxBlueName.includes("version")) {
        console.log("Encontrou um version")
        if (BlueGreen === "Blue") {
          console.log("Encontrou um Blue")
          ListTerraform = FindNodesChieldID(Nodes, BoxBlueID, "TerraformN")
          console.log("ListTerraform", ListTerraform)
          const List = FindStatesList(Edges, Nodes, BoxBlueID, ListTerraform);
          ListStatesBlue = List[0];
          ListStatesIDBlue = List[1];
          console.log("PAssou xx blue", List)
          break;
        }
      }
    }
    for (let i = 0; i < ListBoxesBlueGreen.length; i++) {
      const BoxGreenID = parseInt(ListBoxesBlueGreen[i]);
      const BoxGreenName = Nodes[BoxGreenID].data.Param[1][1].toLowerCase();
      console.log("BoxGreenName", BoxGreenName)
      const BlueGreen = Nodes[BoxGreenID]?.data?.BlueGreen || "Green";
      if (BoxGreenName.includes("version")) {
        Version = Nodes[BoxGreenID].data.Param[4][1];
        if (BlueGreen === "Green") {
          const ListTerraform = FindNodesChieldID(Nodes, BoxGreenID, "TerraformN")
          const List = FindStatesList(Edges, Nodes, BoxGreenID, ListTerraform);
          ListStates = List[0];
          ListStatesID = List[1];
          console.log("PAssou xx green", List)
          break;
        }
      }
    }
  }
  console.log("ListStates, ListStatesID", ListStates, ListStatesID)
  console.log("OldEdgesNodes, NewEdgesNodes", OldEdgesNodes, NewEdgesNodes);
  const OldNodes = OldEdgesNodes[0];
  const NewNodes = NewEdgesNodes[1];
  let ListTargetNew = [], ListTargetOld = [];
  let ListLambda = [];
  let ListS3 = [];
  for (let i = 0; i < NewNodes.length; i++) {
    console.log("NewNodes", NewNodes[i]);
    const NewNodeID = parseInt(NewNodes[i].id);
    console.log("NewNodeID", NewNodeID)
    const NewNodeType = NewNodes[i].type;
    console.log("NewNodeType", NewNodeType)
    if (NewNodeType === "CopyN") {
      ListTargetNew = ListTargetNew.concat(SearchNodesTarget(Edges, Nodes, NewNodes[i], "all"));
      ListTargetOld = ListTargetOld.concat(SearchNodesTarget(Edges, Nodes, OldNodes[i], "all"));
    }
  }
  ListTargetNew = [...new Set(ListTargetNew)];
  ListTargetOld = [...new Set(ListTargetOld)];
  for (let i = 0; i < ListTargetNew.length; i++) {
    const NewNodeID = parseInt(ListTargetNew[i]);
    console.log("NewNodeID", NewNodeID)
    const NewNodeType = Nodes[NewNodeID].type;
    console.log("NewNodeType", NewNodeType)
    const SufixNewNode = AdjustSufixName(Edges, Nodes, NewNodeID, true);
    const NewNodeName = SufixNewNode.data.Param[1][1];
    const OldNodeID = parseInt(ListTargetOld[i]);
    const SufixOldNode = AdjustSufixName(Edges, Nodes, OldNodeID, true);
    const OldNodeName = SufixOldNode.data.Param[1][1];
    const [CloudOldID, RegionOldID] = FindRegionAndCloud(Nodes, Nodes[OldNodeID].id);
    const RegionNameOld = Nodes[parseInt(RegionOldID)].data.Param[2][2];
    const CloudNameOld = Nodes[parseInt(CloudOldID)]["data"]["Param"][3][1];
    const [CloudNewID, RegionNewID] = FindRegionAndCloud(Nodes, Nodes[NewNodeID].id);
    const RegionNameNew = Nodes[parseInt(RegionNewID)].data.Param[2][2];
    const CloudNameNew = Nodes[parseInt(CloudNewID)]["data"]["Param"][3][1];
    if (NewNodeType === "LambdaN") {
      ListLambda.push([NewNodeName, RegionNameNew, CloudNameNew])
    }
    if (NewNodeType === "S3N") {
      //ListS3.push([[OldNodeName, RegionNameOld, CloudNameOld], [NewNodeName, RegionNameNew, CloudNameNew]])
      ListS3.push([NewNodeName, RegionNameNew, CloudNameNew])
    }
  }
  console.log("")
  console.log("ListTargetNew", ListTargetNew, ListTargetOld)
  let ListCopy = new Set();
  ListCopy.IsTest = Stage === "test";
  ListCopy.ListStates = ListStates;
  ListCopy.ListStatesBlue = ListStatesBlue;
  ListCopy.ListLambda = ListLambda;
  ListCopy.ListS3 = ListS3;
  ListCopy.Approved = true;
  ListCopy.Version = Version;
  return [ListCopy, ListStatesID, ListStatesIDBlue];
}

function FindParams(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage) {
  //const BoxID = parseInt(GlobalNodes[StageID].parentNode);
  let TFFound = false;
  const BoxStageID = ListBoxFunc(GlobalEdges, GlobalNodes, StageID)[0];
  console.log("BoxStageID", BoxStageID, StageID)
  const ListChild = FindNodesChieldID(GlobalNodes, BoxStageID);
  console.log("FindNodesChieldID", ListChild)
  const [CloudID, RegionID, RG] = FindRegionAndCloud(GlobalNodes, StageID);
  const RoleARN = GlobalNodes[parseInt(CloudID)].data.Param[5][1];
  const RegionName = GlobalNodes[parseInt(RegionID)].data.Param[2][2];
  for (let i = 0; i < ListChild.length; i++) {
    const TFNode = GlobalNodes[parseInt(ListChild[i])];
    if (TFNode.type === "TerraformN") {
      const TFID = parseInt(TFNode.id);
      //if (TFNode.parentNode === BoxStageID.toString()) {
      const CPName = GlobalNodes[CPHeadID].data.Param[1][1];
      const [ListStates, BackendID, Loop] = DiscoveryTerraformNetwork(GlobalEdges, GlobalNodes, parseInt(TFID));
      const [StorageID, DBID, RepoID, BuildID, BackendErrorMSG] = FindBackendStorage(GlobalEdges, GlobalNodes, BackendID);
      console.log("StorageID", StorageID)
      if (StorageID === 0) {
        const TFName = GlobalNodes[TFID].data.Param[1][1];
        alert(`O node Terraform ${TFName} não está conectado a um backend Terraform.`)
        return [0, RoleARN, RegionName, "", ""]
      }
      const BucketName = GlobalNodes[StorageID].data.Param[1][1];
      return [BoxStageID, RoleARN, RegionName, BucketName, CPName]
      // }
    }
  }
  if (!TFFound) {
    console.log("Terraform Node nao encontrado!", CPHeadID, StageID)
    //alert("Terraform Node nao encontrado!")
    return [0, RoleARN, RegionName, "", ""]
  }
}

async function isActionInProgress(stageName, actionName, pipelineData, States) {
  // Verifica se pipelineData está definido e contém stageStates
  if (!pipelineData || !pipelineData.stageStates) {
    console.error('pipelineData is undefined or does not contain stageStates');
    return [false, ""];
  }
  console.log("pipelineData.stageStates:", pipelineData.stageStates);
  // Encontra o estágio correspondente ao `stageName`
  const stage = pipelineData.stageStates.find(stage => stage.stageName === stageName);
  // Verifica se o estágio foi encontrado
  console.log("Stage", stage, stageName)
  if (!stage) {
    console.error(`Stage ${stageName} not found`);
    return [false, ""];
  }
  console.log(`Stage found: ${stageName}`, stage);
  // Encontra a ação correspondente ao `actionName`
  const action = stage.actionStates.find(action => action.actionName === actionName);
  // Verifica se a ação foi encontrada
  if (!action) {
    console.error(`Action ${actionName} not found in stage ${stageName}`);
    return [false, ""];
  }
  console.log(`Action found: ${actionName}`, action);
  // Verifica se existe um latestExecution
  if (!action.latestExecution) {
    console.error(`Action ${actionName} found but has no latestExecution`);
    return [false, ""];
  }
  // Verifica se o status da ação é "InProgress"
  if (States.includes(action.latestExecution.status)) {
    return [true, action.latestExecution.token];
  }
  // Se não estiver "InProgress", retorna false
  return [false, ""];
}

function ListBoxFunc(GlobalEdges, GlobalNodes, StageID) {
  let ListBox = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageID], "SBoxN");
  ListBox = ListBox.concat(SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageID], "VPCN"));
  return ListBox;
}

async function FindCodePipelineStatus(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage, ActionName, Status = ["InProgress", "Succeeded"]) {
  const PipelineID = GlobalNodes[CPHeadID].data.PipelineID;
  console.log(" here", PipelineID, StageID);
  const [BoxStageID, RoleARN, RegionName, BucketName, CPName] = FindParams(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage);
  // Return immediately if BoxStageID is not valid
  if (BoxStageID === 0) {
    return [false, "", "", "", ""];
  }
  // Prepare parameters for API call
  let raw = [7, CPName, RoleARN, RegionName, PipelineID];
  try {
    const Resp = await CallAPI(APIPricing, raw, true);
    // Check if the response is null or undefined
    if (!Resp || !Resp.body) {
      console.error("API response is null or body is missing:", Resp);
      return [false, "", "", "", ""];
    }
    const PipelineData = Resp.body;
    console.log("PipelineData", PipelineData, Stage);
    // Handle the action progress status
    const [Progressing, Token] = await isActionInProgress(Stage, ActionName, PipelineData, Status);
    return [Progressing, Token, CPName, RoleARN, RegionName];
  } catch (error) {
    // Log the error and return a default array
    console.error("Error in FindCodePipelineStatus:", error);
    return [false, "", "", "", ""];
  }
}


async function WaitForApprovalAction(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage, setStateMachine, ActionName, NextAction, Status, StatusB = ["InProgress", "Succeeded", "Failed"]) {
  // Aguardar um tempo antes de continuar
  //await new Promise(resolve => setTimeout(resolve, 7000));
  // Verificar se o polling deve ser interrompido
  let StopPolling = GlobalNodes[StageID]?.data?.StopPolling || false;
  const PipelineID = GlobalNodes[CPHeadID].data.PipelineID;
  console.log("WaitForApprovalAction PipelineID", PipelineID, ActionName, StageID, StopPolling);
  // Chamar FindCodePipelineStatus e verificar se retorna um array
  const FindCodePipelineStatusResp = await FindCodePipelineStatus(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage, ActionName, StatusB);
  // Verificar se o retorno é um array
  if (!Array.isArray(FindCodePipelineStatusResp)) {
    console.error("FindCodePipelineStatus returned a non-iterable:", FindCodePipelineStatusResp);
    return [false, "", "", "", ""];  // Retornar valores padrão em caso de erro
  }
  const [Progressing, Token, CPName, RoleARN, RegionName] = FindCodePipelineStatusResp;
  // Se estiver progredindo, executar a ação de aprovação
  if (Progressing) {
    let raw = [8, CPName, RoleARN, RegionName, Token, Stage, ActionName, Status];
    const Resp = await CallAPI(APIPricing, raw, true);
    // Atualizar o estado da máquina de estado
    GlobalNodes[StageID].data.StateMachine = NextAction;
    //Save(GlobalEdges, GlobalNodes);
    // Atualizar a máquina de estado se o polling não foi interrompido
    setStateMachine(NextAction);
    console.log("Saiu do WaitForApprovalAction", Progressing, ActionName, Stage);
    return [Progressing, Token, CPName, RoleARN, RegionName];
  }
  // Retornar valores padrão se não estiver progredindo
  return [false, "", "", "", ""];
}


async function WaitForBuildAction(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage, setStateMachine, ActionName, NextAction) {
  // Check if polling should be stopped
  let StopPolling = GlobalNodes[StageID]?.data?.StopPolling || false;
  const PipelineID = GlobalNodes[CPHeadID].data.PipelineID;
  console.log("WaitForBuildAction PipelineID", PipelineID, ActionName, StageID);
  // Call FindCodePipelineStatus and check if it returns an array
  let FindCodePipelineStatusResp = await FindCodePipelineStatus(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage, ActionName, ["Succeeded", "Failed"]);
  // Check if the response is iterable
  if (!Array.isArray(FindCodePipelineStatusResp)) {
    console.error("FindCodePipelineStatus returned a non-iterable:", FindCodePipelineStatusResp);
    return [false, "", "", "", ""];  // Return or handle appropriately if not iterable
  }
  const [Progressing, Token, CPName, RoleARN, RegionName] = FindCodePipelineStatusResp;
  // Check if Progressing is true to proceed
  if (Progressing) {
    await checkPipelineStatusAndOpenUrl(GlobalNodes, StageID, CPName, RoleARN, RegionName, PipelineID, Stage, ActionName, StopPolling);
    console.log("Succeed build");
    GlobalNodes[StageID].data.StateMachine = NextAction;
    //Save(GlobalEdges, GlobalNodes);
    // Update the state machine only if polling is still active
    setStateMachine(NextAction);
    console.log("Saiu do WaitForBuildAction", Progressing, ActionName, Stage);
  }

  return [Progressing, "", "", "", ""];
}


async function checkPipelineStatusAndOpenUrl(GlobalNodes, StageID, CPName, RoleARN, RegionName, PipelineID, Stage, actionName, attempt = 1) {
  // Aguarda um tempo antes de fazer a chamada
  await new Promise(resolve => setTimeout(resolve, 3000 + (attempt - 1) * 3000));
  console.log(`Passou 5s - Tentativa ${attempt}`);

  // Verifica se o polling foi interrompido
  let StopPolling = GlobalNodes[StageID]?.data?.StopPolling || false;
  if (StopPolling) {
    console.log("Polling interrompido pelo usuário.");
    return null;
  }

  // Cria o array `raw` com os parâmetros necessários
  let raw = [7, CPName, RoleARN, RegionName, PipelineID];

  // Chama a API e espera a resposta
  const Resp = await CallAPI(APIPricing, raw, true);
  console.log("Resp de checkPipelineStatusAndOpenUrl", Resp);

  // Verifica se a resposta é null
  if (Resp === null) {
    console.log("A API retornou null. Tentando novamente...");
    if (attempt < 10) {
      return checkPipelineStatusAndOpenUrl(GlobalNodes, StageID, CPName, RoleARN, RegionName, PipelineID, Stage, actionName, attempt + 1);
    } else {
      console.log(`Desistindo após ${attempt} tentativas.`);
      return null;
    }
  }

  // Verifica se pipelineData e stageStates estão definidos
  const pipelineData = Resp.body;
  if (!pipelineData || !pipelineData.stageStates) {
    console.error("pipelineData ou stageStates está indefinido.");
    return null;
  }

  console.log("pipelineData de checkPipelineStatusAndOpenUrl", pipelineData);

  // Procura dentro do estágio cujo nome é igual à variável `Stage`
  const targetStage = pipelineData.stageStates.find(stg => stg.stageName.toLowerCase() === Stage.toLowerCase());
  if (targetStage) {
    console.log("Stage encontrado:", targetStage.stageName);

    // Itera pelas ações dentro do estágio encontrado
    const targetAction = targetStage.actionStates.find(action => action.actionName.toLowerCase().trim() === actionName.toLowerCase().trim());
    if (targetAction && targetAction.latestExecution && targetAction.latestExecution.externalExecutionUrl) {
      let externalExecutionUrl = targetAction.latestExecution.externalExecutionUrl;
      console.log("Abrindo URL:", externalExecutionUrl);
      return externalExecutionUrl;  // Retorna a URL aberta, se necessário
    } else {
      console.log(`Ação ${actionName} não encontrada ou não possui uma URL externa.`);
    }
  } else {
    console.log(`Estágio ${Stage} não encontrado no pipeline.`);
  }

  // Se não encontrou a URL e ainda há tentativas restantes
  if (attempt < 10) {
    console.log(`Tentando novamente... (Tentativa ${attempt + 1})`);
    StopPolling = GlobalNodes[StageID]?.data?.StopPolling || false;
    if (StopPolling) {
      console.log("Polling interrompido pelo usuário.");
      return null;
    }
    return checkPipelineStatusAndOpenUrl(GlobalNodes, StageID, CPName, RoleARN, RegionName, PipelineID, Stage, actionName, attempt + 1);
  } else {
    console.log(`Desistindo após ${attempt} tentativas.`);
    return null;
  }
}


async function Commit(GlobalEdges, GlobalNodes, CPHeadID, StageID, LocalTFID) {
  let [BoxStageID, RoleARN, RegionName, BucketName, CPName] = FindParams(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage);
  if (BoxStageID !== 0) {
    let Code = GlobalNodes[LocalTFID].data.CodeGenerated;
    let UserID = ""
    const [BECloudID, BERegionID, RG] = FindRegionAndCloud(GlobalNodes, CPHeadID);
    const BERegion = GlobalNodes[parseInt(BERegionID)].data.Param[2][2];
    const NewStateName = AdjustSufixName(GlobalEdges, GlobalNodes, LocalTFID, true).data.Param[1][1];
    console.log("NewStateName", NewStateName)
    Code = Code.replace(/shared_credentials_files.*?\n/gm, '');
    //const NewCode = modifyTerraformCode(Code, BucketName, UserID, NewStateName, BERegion)
    const raw = [3, Code, RoleARN, BucketName, NewStateName]
    const Resp = await CallAPI(APIPricing, raw);
    return Resp;
  }
};

//function FindTerraformIDFromNodeID(Edges, Nodes, NodeID) {
function VerifyTerraformDeployed(Nodes, NodeID) {//retorna false se não pode deletar e true se pode, e na segunda posição se é box
  const NodeType = Nodes[NodeID].type;
  if (TypeTerraform.includes(NodeType)) {
    const LastDeployWith = Nodes[NodeID].data?.LastDeployWith || '';
    return [(LastDeployWith === ""), false];
  }
  let BoxID, IsBox, Deployed = false;
  if ((TypeCloud.includes(NodeType)) || (TypeRegion.includes(NodeType)) || (TypeVPC.includes(NodeType)) || (TypeSubnet.includes(NodeType))
    || (TypeSBox.includes(NodeType)) || (TypeSecurityGroup.includes(NodeType))) {
    BoxID = parseInt(Nodes[NodeID].parentNode);
    IsBox = true;
  } else {
    BoxID = NodeID;
    IsBox = false;
  }
  if (IsBox) {
    const ListChild = FindNodesChieldID(Nodes, BoxID, "all");
    for (let i = 0; i < ListChild.length; i++) {
      const TFNodeID = parseInt(ListChild[i]);
      const TFNodeType = Nodes[TFNodeID].type;
      if (TypeTerraform.includes(TFNodeType)) {
        const LastDeployWith = Nodes[TFNodeID].data?.LastDeployWith || '';
        if (LastDeployWith !== "") {
          return [false, true];
        }

      }
    }
    return [true, true]
  } else {
    //if (Nodes[NodeID].parentNode === BoxID.toString()) {
    return [false, false];
    //}
  }
}

async function findActionInProgress(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage, Status = ["InProgress", "Succeeded", "Failed", "Abandoned"]) {
  const PipelineID = GlobalNodes[CPHeadID].data.PipelineID;
  console.log("Pipeline ID:", PipelineID, "Stage ID:", StageID);

  const [BoxStageID, RoleARN, RegionName, BucketName, CPName] = FindParams(GlobalEdges, GlobalNodes, CPHeadID, StageID, Stage);

  if (BoxStageID !== 0) {
    let raw = [7, CPName, RoleARN, RegionName, PipelineID];
    const Resp = await CallAPI(APIPricing, raw, true);
    if (Resp === null) {
      return ["", "", []];
    } else {
      console.log("Response:", Resp);
      const PipelineData = Resp.body;
      console.log("Pipeline Data:", PipelineData, "Stage:", Stage);

      // Verifica se pipelineData está definido e contém stageStates
      if (!PipelineData || !PipelineData.stageStates) {
        console.error('Pipeline data is undefined or does not contain stageStates');
        return ["", "", []];
      }

      // Encontra o estágio correspondente ao `Stage`
      const stage = PipelineData.stageStates.find(stage => stage.stageName === Stage);

      // Verifica se o estágio foi encontrado
      if (!stage) {
        console.error(`Stage ${Stage} not found`);
        return ["", "", []];
      }

      const urls = [];
      let currentActionName = "";
      let currentActionStatus = "";

      // Itera sobre as ações dentro do estágio
      for (let i = 0; i < stage.actionStates.length; i++) {
        const action = stage.actionStates[i];

        if (action.latestExecution) {
          // Coleta todas as URLs se existirem
          if (action.latestExecution.externalExecutionUrl) {
            urls.push(action.latestExecution.externalExecutionUrl);
          }

          // Atualiza a ação corrente e o estado
          if (Status.includes(action.latestExecution.status)) {
            currentActionName = action.actionName;
            currentActionStatus = action.latestExecution.status;
          }

          // Se a ação estiver em Idle ou sem execução, para de procurar
          if (!action.latestExecution.status || action.latestExecution.status === "Idle") {
            break;
          }
        } else {
          // Se a ação não tem execução, consideramos como não iniciada
          break;
        }

        // Se for a última ação, mantém a ação e estado atual
        if (i === stage.actionStates.length - 1) {
          currentActionName = action.actionName;
          currentActionStatus = action.latestExecution.status;
        }
      }

      // Retorna a ação corrente e o estado dela, junto com todas as URLs coletadas
      return [currentActionName, currentActionStatus, urls];
    }
  } else {
    return ["", "", []];
  }
}

function FindAllEdgesFromNodeID(Edges, NodeID) {
  let ListEdges = [];
  NodeID = NodeID.toString();
  for (let i = 0; i < Edges.length; i++) {
    if (Edges[i].target === NodeID || Edges[i].source === NodeID) {
      ListEdges.push(Edges[i])
    }
  }
  return ListEdges
}

function FindStageBoxAbove(Edges, Nodes, NodeID) {

  let CountLoop = 0;
  while (true) {
    let NodeType = Nodes[NodeID].type;
    if (TypeVPC.includes(NodeType) || TypeSBox.includes(NodeType)) {
      let ListStage = SearchNodesSource(Edges, Nodes, Nodes[NodeID], "CodePipelineStageN");
      if (ListStage.length > 0) {
        return NodeID;
      }
    }
    if (TypeCloud.includes(NodeType)) {
      return 0;
    }
    NodeID = parseInt(Nodes[NodeID].parentNode);

    if (CountLoop > 50) {
      return 0
    }
    CountLoop++
  }
}

function CheckEdgeAttributeDuplicated(Edges, Nodes, NodeID, Direction, OtherSideNodeType, ListError) {
  let List, LocalLabel;
  if (Direction === "Source") {
    List = SearchNodesSource(Edges, Nodes, Nodes[NodeID], OtherSideNodeType);
  } else {
    List = SearchNodesTarget(Edges, Nodes, Nodes[NodeID], OtherSideNodeType);
  }
  console.log("List", List)
  let LabelList = [], NodeList = {};
  for (let l = 0; l < List.length; l++) {
    const OtherSideNodeID = parseInt(List[l])
    if (Direction === "Target") {
      LocalLabel = findEdgeLabel(Edges, NodeID, OtherSideNodeID).ctrl;
    } else {
      LocalLabel = findEdgeLabel(Edges, OtherSideNodeID, NodeID).ctrl;
    }
    console.log("LocalLabel ", LocalLabel, NodeID, OtherSideNodeID)
    if (LabelList.includes(LocalLabel)) {
      console.log("erro duplicidade edge")
      if (NodeList["FisrtTime"]) {
        const OldOtherSideNodeID = NodeList[LocalLabel];
        NodeList["FisrtTime"] = false;
        ListError.push([NodeID, `The edge attribute "${LocalLabel}" is duplicated. Each attribute must be unique.`, OldOtherSideNodeID])
      }
      ListError.push([NodeID, `The edge attribute "${LocalLabel}" is duplicated. Each attribute must be unique.`, OtherSideNodeID])
    } else {
      LabelList.push(LocalLabel)
      NodeList[LocalLabel] = OtherSideNodeID;
      NodeList["FisrtTime"] = true;
    }
  }
}

function CheckEdgeAttribute(Edges, Nodes, ListError) {
  let ListNodesEdges = [];
  for (let i = 0; i < Edges.length; i++) {
    const Label = Edges[i].label;
    if (Label.includes("|")) {
      const SourceID = parseInt(Edges[i].source)
      const TargetID = parseInt(Edges[i].target)
      //console.log("SourceID", SourceID, TargetID)
      if (Label.includes("?|")) {
        ListError.push([SourceID, ': You should double-click on edge "?" between these nodes to select an option.', TargetID])
      } else {
        let SourceType = Nodes[SourceID].type;
        let ListEdgesAttributesSource = [];
        if (!ListNodesEdges.includes(SourceID)) {
          ListEdgesAttributesSource = ListaStandard[parseInt(ResourceLookUp[SourceType])]["ListEdgesAttributes"] || [];
          if (ListEdgesAttributesSource.length > 0) {
            ListNodesEdges.push(SourceID)
            for (let j = 0; j < ListEdgesAttributesSource.length; j++) {
              const ListNodes = ListEdgesAttributesSource[j][0];
              const Direction = ListEdgesAttributesSource[j][2];
              const Unique = ListEdgesAttributesSource[j][3];
              console.log("Unique", Unique)
              if (Unique) {
                console.log("ListNodes", ListNodes, Direction)
                for (let k = 0; k < ListNodes.length; k++) {
                  const OtherSideNodeType = ListNodes[k];
                  CheckEdgeAttributeDuplicated(Edges, Nodes, SourceID, Direction, OtherSideNodeType, ListError)
                }
              }
            }
          }
        }
        let TargetType = Nodes[TargetID].type;
        ListEdgesAttributesSource = [];
        if (!ListNodesEdges.includes(TargetID)) {
          ListEdgesAttributesSource = ListaStandard[parseInt(ResourceLookUp[TargetType])]["ListEdgesAttributes"] || [];
          if (ListEdgesAttributesSource.length > 0) {
            ListNodesEdges.push(TargetID)
            for (let j = 0; j < ListEdgesAttributesSource.length; j++) {
              const ListNodes = ListEdgesAttributesSource[j][0];
              const Direction = ListEdgesAttributesSource[j][2];
              const Unique = ListEdgesAttributesSource[j][3];
              console.log("ListNodes", ListNodes, Direction)

              if (Unique) {
                for (let k = 0; k < ListNodes.length; k++) {
                  const OtherSideNodeType = ListNodes[k];
                  CheckEdgeAttributeDuplicated(Edges, Nodes, TargetID, Direction, OtherSideNodeType, ListError)
                }
              }
            }
          }
        }
      }
    }
  }
}

// Função para calcular a curva Bézier
function cubicBezier(t, p0, p1, p2, p3) {
  const u = 1 - t;
  const tt = t * t;
  const uu = u * u;
  const uuu = uu * u;
  const ttt = tt * t;
  return (uuu * p0) + (3 * uu * t * p1) + (3 * u * tt * p2) + (ttt * p3);
}
// Função para animar o movimento do viewport para um node específico com curva Bézier
function animateNodeTransition(GlobalNodes, GlobalTransform, reactFlowInstance, nodeId) {
  // Obter a posição absoluta do node
  const nodePosition = calculateAbsolutePosition(GlobalNodes, GlobalNodes[nodeId]);
  // Obtém a transformação atual do viewport (posição x, y e zoom)
  const { x: startX, y: startY, zoom: startZoom } = GlobalTransform;
  // Zoom final desejado
  const endZoom = 1.2; // Zoom final
  const zoomDuration = 1000; // Duração da animação de zoom em milissegundos
  // Deslocamento de 15% da largura da tela para a esquerda
  const offsetX = window.innerWidth * 0.11;
  // Calcula a nova posição final do viewport com base no zoom final
  const endX = (window.innerWidth / 2) - nodePosition.x * endZoom - offsetX; // Desloca 15% para a esquerda
  const endY = (window.innerHeight / 2) - nodePosition.y * endZoom;
  let startTime = null;
  // Definindo os pontos de controle da curva Bézier para translação e zoom
  const p0 = 0, p1 = 0.42, p2 = 0.58, p3 = 1;
  // Função para interpolar a posição e o zoom de maneira suave com Bézier
  function step(timestamp) {
    if (!startTime) startTime = timestamp;
    const elapsed = timestamp - startTime;
    const t = Math.min(elapsed / zoomDuration, 1);
    // Aplicando a curva Bézier no progresso
    const easedProgress = cubicBezier(t, p0, p1, p2, p3);
    // Interpolação da posição com base no progresso
    const newX = startX + (endX - startX) * easedProgress;
    const newY = startY + (endY - startY) * easedProgress;
    // Interpolação do zoom com base no progresso
    const newZoom = startZoom + (endZoom - startZoom) * easedProgress;
    // Atualiza o viewport com os valores interpolados
    reactFlowInstance.setViewport({ x: newX, y: newY, zoom: newZoom });
    // Continua a animação até completar
    if (t < 1) {
      requestAnimationFrame(step);
    }
  }
  requestAnimationFrame(step);
}
function ShowGlobalID() {
  //console.log("GlobalNodeID", GlobalNodeID)
}

function AdjustPositionOnCopySingle(GlobalNodes, NewNodesCopy, DestinationParentID, CopyNodeID, GlobalMousePosX, GlobalMousePosY, GlobalTransform, Node0PosX, Node0PosY) {
  //console.log("Mouse X:", GlobalMousePosX, "Mouse Y:", GlobalMousePosY);
  const ViewPortAbsoluteX = (GlobalMousePosX - GlobalTransform.x) / GlobalTransform.zoom;
  const ViewPortAbsoluteY = (GlobalMousePosY - GlobalTransform.y) / GlobalTransform.zoom;
  const { x, y } = calculateAbsolutePosition(GlobalNodes, GlobalNodes[DestinationParentID])
  //console.log("X:", x, "Mouse Y:", y);
  const NewX = ViewPortAbsoluteX - x;
  const NewY = ViewPortAbsoluteY - y;
  //console.log("New X:", NewX, "New Y:", NewY);
  NewNodesCopy[CopyNodeID].position.x = NewX + NewNodesCopy[CopyNodeID].position.x - Node0PosX;
  NewNodesCopy[CopyNodeID].position.y = NewY + NewNodesCopy[CopyNodeID].position.y - Node0PosY
};

function AdjustPositionOnCopy(GlobalNodes, NewNodesCopy, DestinationParentID, FistNodeISTypeBox, GlobalMousePosX, GlobalMousePosY, GlobalTransform) {
  const Node0PosX = NewNodesCopy[0].position.x;
  const Node0PosY = NewNodesCopy[0].position.y;
  //console.log("FistNodeISTypeBox", FistNodeISTypeBox)
  if (FistNodeISTypeBox) {
    AdjustPositionOnCopySingle(GlobalNodes, NewNodesCopy, DestinationParentID, 0, GlobalMousePosX, GlobalMousePosY, GlobalTransform, Node0PosX, Node0PosY)
  } else {
    for (let i = 0; i < NewNodesCopy.length; i++) {
      AdjustPositionOnCopySingle(GlobalNodes, NewNodesCopy, DestinationParentID, i, GlobalMousePosX, GlobalMousePosY, GlobalTransform, Node0PosX, Node0PosY)
    }
  }
}

async function UpdateStatus(GlobalEdges, GlobalNodes, NodesWithSufix, ListBlockNodes, GlobalStatus, CrossAccountRole) {
  let ListNodes = [];
  console.log("NodesWithSufix, ListBlockNodes", NodesWithSufix, ListBlockNodes)
  for (let j = 0; j < ListBlockNodes.length; j++) {
    let NodeID = parseInt(ListBlockNodes[j]);
    let NodeType = NodesWithSufix[NodeID].type;
    let NodeIndex = ResourceLookUp[NodeType];
    let HasStatus;
    let Category;
    try {
      Category = ListaStandard[NodeIndex].Category;
    } catch (error) {
      Category = [];
      console.log("No Category", NodeType);
    }
    if (Category.includes("AWS")) {
      try {
        HasStatus = ListaStandard[NodeIndex].GeneralParam.HasStatus;
      } catch (error) {
        HasStatus = false;
      }
      if (HasStatus) {
        let NodeName = NodesWithSufix[NodeID].data.Param[1][1];
        NodeName += ListaStandard[NodeIndex]?.GeneralParam?.Sufix || "";
        let AWSNodeType = ListaStandard[NodeIndex].GeneralParam.AWSName;
        let RegionID = parseInt(FindRegionAndCloud(NodesWithSufix, NodesWithSufix[NodeID].id)[1]);
        let RegionName = NodesWithSufix[RegionID].data.Param[2][2];
        if (NodeType === "CFDistributionN") {
          RegionName = 'us-east-1';
        }
        if (NodeType === "ACMN") {
          let SourceList = SearchNodesSource(GlobalEdges, NodesWithSufix, NodesWithSufix[NodeID], "R53ZoneN");
          for (let k = 0; k < SourceList.length; k++) {
            const ZoneID = parseInt(SourceList[k]);
            let TargetList = SearchNodesTarget(GlobalEdges, NodesWithSufix, NodesWithSufix[ZoneID], "CFDistributionN");
            if (TargetList.length > 0) {
              RegionName = 'us-east-1';
              break;
            }
          }
        }
        if (NodeType === "SecurityGroupN") {
          const HeadID = SearchSecurityGroupHead(GlobalEdges, NodesWithSufix, NodeID);
          console.log("HeadID", HeadID, NodeID);
          if (HeadID !== 0) {
            NodeName = NodesWithSufix[HeadID].data.Param[1][1];
          }
        }
        ListNodes.push([AWSNodeType, NodeName, RegionName, NodeID]);
      }
    }
  }
  var raw = JSON.stringify([0, ListNodes, CrossAccountRole]);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
  var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };
  try {
    const response = await fetch(APIPricing, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const responseData = await response.json();
    if (responseData.statusCode === 403) {
      throw new Error("Invalid Credentials configured in Cloud > Cross Account Role");
    }
    console.log("responseData", responseData)
    GlobalStatus = GlobalStatus.concat(responseData.body);
    GlobalStatus = await removeDuplicates(GlobalStatus);
    console.log("GlobalStatus", GlobalStatus)
    for (let j = 0; j < GlobalStatus.length; j++) {
      const LocalNodeID = GlobalStatus[j][0];
      const LocalNodeType = GlobalNodes[LocalNodeID].type;
      const Status = GlobalStatus[j][1];
      GlobalNodes[LocalNodeID].data.ARN = GlobalStatus[j][3];
      if (Status) {
        if (LocalNodeType === "EC2N") {
          const Status = GlobalStatus[j][2];
          if (Status === "Terminated") {
            GlobalNodes[LocalNodeID].data.Deployed = false;
            GlobalNodes[LocalNodeID].data.Status = "";
          } else {
            console.log("EC2 status", Status);
            GlobalNodes[LocalNodeID].data.Deployed = true;
            GlobalNodes[LocalNodeID].data.Status = Status;
          }
        } else {
          GlobalNodes[LocalNodeID].data.Deployed = true;
        }
      } else {
        GlobalNodes[LocalNodeID].data.Deployed = false;
        GlobalNodes[LocalNodeID].data.Status = "";
      }
    }
    for (let j = 0; j < GlobalStatus.length; j++) {
      const LocalNodeID = GlobalStatus[j][0];
      const Status = GlobalStatus[j][1];
      if (!Status) {
        GlobalNodes[LocalNodeID].data.Deployed = false;
      }
    }
    return [true, GlobalStatus];
  } catch (error) {
    console.error('Fetch error:', error.message);
    return [false, []];
  }
}


async function removeDuplicates(arr) {
  if (!Array.isArray(arr)) {
    throw new Error("Input is not an array");
  }
  const map = new Map();
  // Filtra elementos nulos e processa o restante
  arr.filter(item => item !== null).forEach((item) => {
    if (Array.isArray(item) && item.length === 4) { // Atualizado para 4 elementos
      const [resourceId, found, ec2Status, arn] = item; // Desestruturação com 4 elementos
      map.set(resourceId, [resourceId, found, ec2Status, arn]); // Armazena os 4 elementos
    } else {
      throw new Error("Invalid item structure. Expected 4 elements per item.");
    }
  });
  // Converte o map de volta para array
  return Array.from(map.values());
};

async function CheckStateStatus(Edges, Nodes, ListDependent, BucketName, TFID, RemoteBackend, RemoteBackendName, Command = "destroy") {
  const [CloudID, BERegionID, RG] = FindRegionAndCloud(Nodes, TFID);
  let Region = Nodes[parseInt(BERegionID)].data.Param[2][2];
  let RoleARN = Nodes[CloudID].data.Param[5][1];
  console.log("RoleARN", RoleARN)
  let SecondBucketName = BucketName;
  let FirstBucketName = BucketName;
  let SecondRegion = Region;
  let FistRegion = Region;
  if (Nodes[TFID].type === "TerraformBackendS3N" && Command === "destroy") {
    console.log("Remotebackend", RemoteBackend, TFID, RemoteBackendName)
    if (RemoteBackend === "CloudMan") {
      FirstBucketName = "CloudMan"; // indicará ao BE que deve acessar os arquivos na bucket do Cloudman
      console.log("Remotebackend cloudman")
    }
    console.log("Nodes[TFID].type", Nodes[TFID].type)
    if (ListDependent.length > 1) {
      const TFIDChild = parseInt(ListDependent[1])
      const [ListStates, BackendIDChild, Loop] = DiscoveryTerraformNetwork(Edges, Nodes, parseInt(TFIDChild));
      const [StorageBID, DBID, RepoID, BuildID, BackendErrorMSG] = FindBackendStorage(Edges, Nodes, BackendIDChild, false);
      SecondBucketName = Nodes[StorageBID].data.Param[1][1];
      const [CloudID, BERegionID, RG] = FindRegionAndCloud(Nodes, StorageBID);
      FistRegion = Nodes[parseInt(BERegionID)].data.Param[2][2];
      console.log("TFIDChild", TFIDChild, SecondBucketName, FistRegion)
    } else {
      //FirstBucketName = BucketName;
      FistRegion = Region;
    }

  } else {
    //FirstBucketName = BucketName;
    FistRegion = Region;
  }
  console.log("ListDependent", ListDependent, Command)
  let ListDependentDeployed = [], ListStatesNonDeployed = [];

  for (let i = 0; i < ListDependent.length; i++) {
    ListDependentDeployed.push(AdjustSufixName(Edges, Nodes, parseInt(ListDependent[i]), true).data.Param[1][1])
  }
  console.log("ListDependentDeployed", ListDependentDeployed)
  console.log("Buckets", FirstBucketName, SecondBucketName)
  const UserID = sessionStorage.getItem("CognitoSub").slice(0, -1);
  const raw = [15, ListDependentDeployed, RoleARN, FistRegion, FirstBucketName, SecondRegion, SecondBucketName, UserID];
  const Resp = await CallAPI(APIPricing, raw, true);
  let ListStatus = Resp.body;
  console.log("ListStatus", ListStatus)
  if (ListStatus === "Credentials Error") {
    alert(`RoleARN inválido.`)
    return false
  }
  let ListStatesDeployed = [];
  if (Command == "destroy") {
    if (!ListStatus[0]) {
      if (!(ListStatus.includes(true))) {
        if (RemoteBackend === "CloudMan") {
          //alert(`Este estado não foi encontrado no backend CloudMan. Deve ter sido criado por outro método.`);
        } else {
          alert(`Este estado não está deployed e por isso não pode ser destruido`);
          return false
        }
      }
    }
    ListStatus.shift();
    ListDependentDeployed.shift()
  }
  console.log("ListDependentDeployed", ListDependentDeployed)
  console.log("ListStatus", ListStatus)
  if (ListStatus.length > 0) {
    for (let i = 0; i < ListStatus.length; i++) {
      if (ListStatus[i]) {
        ListStatesDeployed.push(ListDependentDeployed[i])
        console.log("ListDependentDeployed[i]", ListDependentDeployed[i])
      } else {

        if (Nodes[parseInt(ListDependent[i])].data.LastDeployWith !== "CloudMan") {
          ListStatesNonDeployed.push(ListDependentDeployed[i])
        }

      }
    }
  }
  console.log("ListStatesDeployed", ListStatesDeployed)
  console.log("ListStatesNonDeployed", ListStatesNonDeployed)
  if (Command == "destroy") {
    if (ListStatesDeployed.length > 0) {
      alert(`Para destroy este estado é necessário destroy os estados  ${ListStatesDeployed}`);
      console.log("Para destroy este estado é necessário destroy os estados", ListStatesDeployed)
      return false
    }
  }
  if (Command == "delete") {
    if (ListStatesDeployed.length > 0) {
      return false
    }
    return true
  }
  if (Command == "apply") {
    if (ListStatesNonDeployed.length > 0) {
      for (let i = 0; i < ListStatesNonDeployed.length; i++) {
        const NonDeployedID = ListStatesNonDeployed[i];

        alert(`Para apply este estado é necessário apply o estado  ${ListStatesNonDeployed}`)
        return false
      }
    }
  }
  return true
}


export {
  GetVPCParent, SearchNodesSource, SearchNodesTarget, SearchNodesByLevels, ResourceLabelAdjust, SearchNodesTargetTwoLevels,
  GetFatherPosition, RemoveSelected, SearchNodeType, SearchNameNodeType, compareAndAdd, FindNodesChieldOneLevelID,
  SearchNodeTypeReturnNameList, FindNodesEdgesChield, FindNodesEdgesChieldSelected, FindSelectedNodes,
  FindResourceFather, FindNodesChieldIDFromParent, FindNodesChieldID, FindRegionAndCloud, nextIPCIDR,
  checkIPCIDRConflict, PermitionToAddNode, HandlerGeneral, GetCIDRList, RemoveNodesFromList,
  RemoveEdgesFromList, VerifyNodesTypeTarget, findIntersectingNodesBox, AdjustFatherPosition, Save, SavePartial, SaveFull,
  AjustEdgesNodesToSaveTotal, CheckIdenticalName, AdjustID, arraysAreEqual, ListTerraformDomain, GetNodeId, UpDateGlobalNodeID,
  FindFiliationLevel, FindAllFathers, ToJSONObject, ExtractTerraformResourceBlock, ReadCloud, ExtremePositionBox, MinXYBox,
  getCurrentMonthDates, GetCostFromCostExporerResponse, FindNameIDRouting, Compress, Descompact, openConsole,
  compareMetricDataQueries, findInstancesByTags, TestDuplicateEdges, CalculateScale, FindIPv6BlockIndex, ValidateArn,
  findEdgeLabel, AdjustSubnetCIDR, MaskString, ProcessLoad, ProcessLoadNew, SearchSubnetHead, UpdateDataNodes, IsEC2AutoScale,
  GenerateRandomizedBucketName, mirrorNodes, BoxRestrictions, DiscoveryTerraformNetwork, FindBackendStorage, FindNodeSourceHead, FindNodeNonGraphNHead,
  compareVersions, AdjustSubnetCIDRAfterPaste, deepEqual, reorderNodesAndEdges, UpdateRoute53BlueGreen, AdjustSufixName, updateTerraformDependencies,
  formatTick, createAndCaptureChartSequentially, UpdateMetrics, adjustTimestamps, fetchAndProcessMetrics, checkNodeVisibility, checkAndRefreshToken,
  CallAPI, SearchSecurityGroupHead, modifyTerraformCode, MakeStageStateFilesList, FindAllNetworkNodeIDs, VerifyTerraformDeployed,
  FindParams, ListBoxFunc, WaitForApprovalAction, WaitForBuildAction, checkPipelineStatusAndOpenUrl, Commit, findActionInProgress,
  FindAllEdgesFromNodeID, FindStageBoxAbove, CheckEdgeAttribute, animateNodeTransition, ShowGlobalID, AdjustPositionOnCopy, calculateAbsolutePosition,
  AjustEdgesNodesToSavePartial, UpdateStatus, CheckStateStatus,
}