import { Handle, Position } from "reactflow";
import { useState } from 'react';
import { GNodes, GEdges, ShowGraph } from '../NodesData';
import { FindNodeNonGraphNHead } from '../Functions'



const Graph = ({ data, selected, id }) => {
    const [isMouseNear, setIsMouseNear] = useState(false);
    //console.log("Opacity", Opacity)
    //if (NodeID === 0 || !imageSrc) { ShowGraph = true; }
    if (ShowGraph) {
        const imageSrc = data.img;
        let NodeID = FindNodeNonGraphNHead(GEdges, GNodes, parseInt(id));
        const Display = NodeID === 0 ? "Connect Source Node" : "Double Click to Choose Metric";
        if (!imageSrc || NodeID === 0) {
            return (
                <div
                    onMouseEnter={() => setIsMouseNear(true)}
                    onMouseLeave={() => setIsMouseNear(false)}
                >
                    <div style={{ position: 'relative', zIndex: 1000 }}>
                        <div style={{
                            width: '47px',
                            height: '35px',
                            overflow: 'hidden', // Garante que o conteúdo não ultrapasse o box
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: 'white',
                            boxShadow: '0 0 0 1px black rgba(0,0,0,0.40)',
                            filter: selected ? "brightness(96%)" : "none",
                        }}>
                            <div style={{
                                transform: 'scale(.6)',
                                transformOrigin: 'center',
                                textAlign: 'center', // Centraliza o texto
                                //whiteSpace: 'nowrap', // Evita que o texto quebre em várias linhas
                                //textOverflow: 'ellipsis', // Adiciona reticências quando o texto for muito longo
                                overflow: 'hidden', // Garante que o texto não ultrapasse o box
                            }}>
                                <h1 style={{ fontSize: '12px', margin: 0 }}>{Display}</h1>
                            </div>
                        </div>
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="right"
                            position={Position.Right}
                            type="source"
                            isConnectable={true} />

                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="left"
                            position={Position.Left}
                            type="source"
                            isConnectable={true} />
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="top"
                            position={Position.Top}
                            type="source"
                            isConnectable={true} />
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="bottom"
                            position={Position.Bottom}
                            type="source"
                            isConnectable={true} />
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    onMouseEnter={() => setIsMouseNear(true)}
                    onMouseLeave={() => setIsMouseNear(false)}
                >
                    <div style={{
                        position: 'relative', zIndex: 1000
                    }}>
                        <div style={{
                            width: '47px',
                            height: '35px',
                            overflow: 'visible',
                            position: 'relative',
                            //background: selected ? '#C0C0E0' : 'white',
                            zIndex: 1000,
                            boxShadow: '0 0 0 2px black rgba(0,0,0,0.40)',
                            borderRadius: "1%",
                            filter: selected ? "brightness(96%)" : "none",
                        }}>
                            <img src={imageSrc} alt="Graph" style={{ width: '100%', height: '100%' }} />
                        </div>
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="right"
                            position={Position.Right}
                            type="source"
                            isConnectable={true} />

                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="left"
                            position={Position.Left}
                            type="source"
                            isConnectable={true} />
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="top"
                            position={Position.Top}
                            type="source"
                            isConnectable={true} />
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="bottom"
                            position={Position.Bottom}
                            type="source"
                            isConnectable={true} />

                    </div>
                </div>
            );
        }
    } else {
        return null; // Caso o gráfico não deva ser mostrado
    }
}

export { Graph };
