import { Handle, Position } from "reactflow";
import { IsEC2AutoScale } from '../Functions'
import { Constraints, GNodes, GEdges } from '../NodesData';
import GenericNode from '../GenericNode'

const Close = (e, data, draggable, zIndex) => {
    console.log("Aqui")
    data.Mode = "Regular";
    draggable = true;
    zIndex = 10;
}

const UserData = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'UserData.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};

const KeyPair = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KeyPair.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const AMIDS = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AMIDS.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const AMI = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AMI.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const AMIFromInst = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AMIFromInst.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const AMICopy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AMICopy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const LaunchTemplate = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LaunchTemplate.png")';
    let LabelName = data.Param[1][1] + " " + data.Param[3][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const PlacementGroup = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'PlacementGroup.png")';
    let LabelName = data.Param[4][2];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const ENI = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ENI.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EIP = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EIP.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const EIPA = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EIPA.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EC2 = ({ id, draggable, data, zIndex, selected }) => {
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    if (data.Mode == "Regular") {
        let Label = "EC2";
        let LabelName = data.Param[1][1];
        let URLIcon = 'url("' + Constraints.IconsURL + 'EC2.png")';
        if (IsEC2AutoScale(GEdges, GNodes, id).length > 0) {
            URLIcon = 'url("' + Constraints.IconsURL + 'EC2AS.png")';
            Label = "EC2 with AS";
        }
        return (
            <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} Label={Label} ToolTip={data.Status} />
        );
    } else {
        var ListVars = ["Satate", "Image ID", "Instance Id", "Launch Time", "Private DNS Name", "Private IP Address",
            "Public DNS Name", "Public IP Address", "MAC Address", "Tags"];
        var Infovar = ["", "", "", "", "", "", "", "", "", "",];
        var raw = JSON.stringify([]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
        fetch(" https://gqegcoaem4.execute-api.us-west-1.amazonaws.com/Dev/ReadAWSResourcesInfo", requestOptions)
            .then(response => response.text())
            .then(result => {
                let Infovar = JSON.parse(result).body;
                console.log("Info", Infovar)
                for (let i = 0; i < Infovar.length; i++) {
                    document.getElementById(i).innerHTML = ListVars[i] + " : " + Infovar[i];
                }

            })
        return (
            <div className="react-flow__node-custom">
                <div className="custom-node__header">
                    <h2 className="custom-drag-handle">Instance Informations</h2>
                    {ListVars.map((Items, Index) =>
                        <h3 id={Index}> {Items} :  </h3>
                    )}
                    <button onClick={(e) => Close(e.target.value, data, draggable, zIndex)}>Close</button>
                </div>

            </div>
        )

    }
};
export { EC2, AMIDS, AMI, AMIFromInst, AMICopy, LaunchTemplate, UserData, KeyPair, ENI, EIP, EIPA, PlacementGroup, };

