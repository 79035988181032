import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { App } from './App';
import { APIDB, Stage, APIAuth, S3Url } from "./Config.js";
import { CallAPI, Descompact, ProcessLoad, ProcessLoadNew, AjustEdgesNodesToSaveTotal, SavePartial, SaveFull } from './Functions';

// Importando fflate
import { unzlibSync } from 'fflate';


async function loadAppData() {
  const urlParams = new URLSearchParams(window.location.search);
  const Code = urlParams.get('code');
  let ErrorCtrl = false;
  let GlobalToken = sessionStorage.getItem("Code" + Code);
  let CognitoRegion, CognitoDomain, CognitoClient, AccessExpiresIn, RefreshExpiresIn, AccessExpiresAt, RefreshExpiresAt, RefreshToken;
  let GlobalUserName, GlobalCognitoSub, FileName, ParamFull, ListStandard, ResourceToNodeLookUp, ListAWSRegions, ListAzureRegions,
    DocList, ListTemplates, LastSaved = [], Configuration;
  var ListAlarms = [];
  let appProps = {};
  const MaxItemPerPage = 100;
  const ConfigNode = {
    id: 'Config',
    type: 'ConfigN',
    dragHandle: '.custom-drag-handle',
    position: { x: 100, y: 200 },
    data: {
      nodeAction: "a", ForceRender: true, IsNode: "Node", FirstRender: true
    },
    zIndex: 100,
    hidden: true,
    Refresh: false,
  }
  let LoadedEdges = [];
  let LoadedNodes = [ConfigNode];
  FileName = sessionStorage.getItem("FileName");
  if (Stage === 'DevLocal') {
    GlobalUserName = "Ricardo";
    GlobalCognitoSub = "";
  } else {
    GlobalUserName = sessionStorage.getItem("UserName");
    GlobalCognitoSub = sessionStorage.getItem("CognitoSub");
    CognitoRegion = sessionStorage.getItem("CognitoRegion");
    CognitoDomain = sessionStorage.getItem("CognitoDomain");
    CognitoClient = sessionStorage.getItem("CognitoClient");
  }
  console.log("GlobalToken", GlobalToken)
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  if (GlobalToken === null) {
    try {
      console.log("APIAuth", APIAuth)
      const response = await fetch(APIAuth, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ "code": Code })
      });
      const authData = await response.json();
      const parsedBody = JSON.parse(authData.body);
      console.log("parsedBody", parsedBody)
      if (parsedBody.error) {
        ErrorCtrl = true;
        console.log("Erro")
      } else {
        GlobalToken = parsedBody.access_token;
        myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
        console.log("GlobalToken", GlobalToken)
        sessionStorage.setItem("Code" + Code, GlobalToken);
        sessionStorage.setItem("GlobalToken", GlobalToken);
        RefreshToken = parsedBody.refresh_token;
        sessionStorage.setItem("RefreshToken", RefreshToken);
        AccessExpiresIn = parsedBody.access_expires_in;
        sessionStorage.setItem("AccessExpiresIn", AccessExpiresIn);
        RefreshExpiresIn = parsedBody.refresh_expires_in;
        const currentTime = new Date().getTime();
        AccessExpiresAt = currentTime + (AccessExpiresIn - 90) * 1000;
        RefreshExpiresAt = currentTime + RefreshExpiresIn * 1000;
        sessionStorage.setItem("AccessExpiresAt", AccessExpiresAt);
        sessionStorage.setItem("RefreshExpiresAt", RefreshExpiresAt);
        console.log("RefreshToken", AccessExpiresIn, RefreshExpiresIn, RefreshToken)
        GlobalUserName = parsedBody.user_name;
        sessionStorage.setItem("UserName", GlobalUserName);
        console.log("GlobalUserName", GlobalUserName)
        GlobalCognitoSub = parsedBody.user_sub + ":";
        sessionStorage.setItem("CognitoSub", GlobalCognitoSub);
        FileName = parsedBody.user_sub + ":P1"
        sessionStorage.setItem("FileName", FileName);
        console.log("FileName", FileName)
        CognitoRegion = parsedBody.cog_region;
        sessionStorage.setItem("CognitoRegion", CognitoRegion);
        CognitoDomain = parsedBody.cog_domain;
        sessionStorage.setItem("CognitoDomain", CognitoDomain);
        CognitoClient = parsedBody.cog_client;
        sessionStorage.setItem("CognitoClient", CognitoClient);
        console.log("Cognito", CognitoRegion, CognitoDomain, CognitoClient)
      }
    } catch (error) {
      console.error('Erro durante a autenticação:', error);
      ErrorCtrl = true;
    }
  }
  let Old = false;
  console.log("ErrorCtrl", ErrorCtrl, myHeaders)
  if (!ErrorCtrl) {
    console.log("FileName", FileName, GlobalUserName)
    myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
    //try {
    if (Old) {
      let raw = JSON.stringify([3, "Nodes", FileName]);
      let requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
      const response = await fetch(APIDB, requestOptions);
      console.log("response", response)
      const text = await response.text();
      console.log("text", text)
      const Array = ProcessLoad(text);
      LoadedEdges = Array[0];
      LoadedNodes = Array[1];
      LoadedNodes[0].id = 'Config';
      console.log("EdgesNodes", LoadedEdges, LoadedNodes)
    } else {
      const rawload = [11, "NodesPartial", FileName];
      const respload = await CallAPI(APIDB, rawload, true);
      console.log("respload", respload);

      // Itera sobre os elementos do respload.body, filtrando apenas aqueles com chaves numéricas
      Object.entries(respload.body).forEach(([key, element], index) => {
        // Verifica se a chave é um número
        if (!isNaN(key)) {
          if (typeof element === 'string') {
            const compressedData = element; // Diretamente atribui a string compactada
            const Descompacted = Descompact(compressedData);
            const jsonData = Descompacted[0];

            // Atualiza o LastSaved com o segundo valor descompactado, se existir
            LastSaved.push(Descompacted[1]);

            if (index === 0) {
              // Para o primeiro índice, carrega Edges e Configuration
              LoadedEdges = jsonData[0];
              Configuration = jsonData[1];
            } else {
              // Para os demais índices, carrega como Nodes
              LoadedNodes.push(jsonData);
            }
          } else {
            console.warn(`Elemento ${index} não é uma string compactada:`, element);
          }
        } else {
          console.warn(`Chave ${key} não é um número, elemento ignorado.`);
        }
      });

      //console.log("LoadedEdges first", LoadedEdges, LoadedNodes)
      let Adjust = AjustEdgesNodesToSaveTotal(LoadedEdges, LoadedNodes);
      const PreLoadedEdges = Adjust[0];
      const PreLoadedNodes = Adjust[1];
      console.log("PreLoadedEdges", PreLoadedEdges, PreLoadedNodes)
      if (LoadedNodes.length !== PreLoadedNodes.length) {
        console.log("Precisa salvar completo", LastSaved);
        setTimeout(() => {
          SaveFull(PreLoadedEdges, PreLoadedNodes, FileName, LastSaved);
        }, 10000); // Aguarda 10 segundos antes de chamar SaveFull
      }
      LoadedEdges = PreLoadedEdges;
      LoadedNodes = PreLoadedNodes;
      //console.log("LoadedEdges", LoadedEdges, LoadedNodes)
      const Loaded = ProcessLoadNew(LoadedEdges, LoadedNodes)
      LoadedEdges = Loaded[0];
      LoadedNodes = Loaded[1];
      console.log("LoadedEdges final", LoadedEdges, LoadedNodes)
    }

    //} catch (error) {
    // console.error("Fetch error:", error);
    //}
    //console.log("LoadedEdges", LoadedEdges, LoadedNodes)
    //console.log("s3Url", S3Url)
    const response = await fetch(S3Url, { method: 'GET' });
    const buffer = await response.arrayBuffer();
    const compressedData = new Uint8Array(buffer);
    //console.log(compressedData.slice(0, 100));
    const decompressedData = unzlibSync(compressedData);
    const decompressedString = new TextDecoder('utf-8').decode(decompressedData);
    //console.log(decompressedString);
    let CloudManData = JSON.parse(decompressedString);
    ParamFull = CloudManData[0];
    ListStandard = CloudManData[1];
    ResourceToNodeLookUp = CloudManData[2];
    ListAWSRegions = CloudManData[3];
    ListAzureRegions = CloudManData[4];
    DocList = CloudManData[5];
    ListTemplates = CloudManData[6];
    console.log("ParamFull", ParamFull);
    console.log("ListaStandard", ListStandard);
    console.log("ResourceToNodeLookUp", ResourceToNodeLookUp);
    console.log("ListAWSRegions", ListAWSRegions);
    console.log("DocList", DocList);
    console.log("ListTemplates", ListTemplates)
    var TypeCloud = ["CloudN", "AZCloudN"];
    var TypeRegion = ["RegionN", "AZRegionN", "AZResourceGroupN"];
    var TypeVPC = ["VPCN", "AZVNETN"];
    var TypeSubnet = ["SubnetN", "AZSubnetN"];
    var TypeAZ = ["AZN", "AZAZN"];
    var TypeTerraform = ["TerraformN", "TerraformBackendS3N"];
    var TypeSBox = ["SBoxN"];
    var TypeSecurityGroup = ["SecurityGroupN"]
    console.log("GlobalUserName", GlobalUserName, LoadedEdges, LoadedNodes)
  }

  if (!ErrorCtrl) {
    appProps = {
      LoadedEdges,
      LoadedNodes,
      GlobalToken,
    };
  }

  const root = createRoot(document.getElementById('root'));
  if (!ErrorCtrl) {
    root.render(
      <App DBFull={ParamFull} ListStandard={ListStandard} DocList={DocList} TypeCloud={TypeCloud} TypeVPC={TypeVPC} TypeRegion={TypeRegion}
        TypeAZ={TypeAZ} TypeSubnet={TypeSubnet} ListAWSRegions={ListAWSRegions} ListAzureRegions={ListAzureRegions} ListAlarms={ListAlarms}
        GlobalToken={GlobalToken} Code={Code} ListTemplates={ListTemplates} GlobalCognitoSub={GlobalCognitoSub} GlobalUserName={GlobalUserName}
        LoadedNodes={LoadedNodes} LoadedEdges={LoadedEdges} CognitoRegion={CognitoRegion} CognitoDomain={CognitoDomain}
        CognitoClient={CognitoClient} AccessExpiresAt={AccessExpiresAt} RefreshExpiresAt={RefreshExpiresAt} RefreshToken={RefreshToken}
        Stage={Stage} TypeTerraform={TypeTerraform} TypeSBox={TypeSBox} TypeSecurityGroup={TypeSecurityGroup} Configuration={Configuration}
        LastSaved={LastSaved} MaxItemPerPage={MaxItemPerPage} />
    );
  } else {
    if (Stage === "DevLocal") {
      root.render(<div>Erro ao carregar dados. Por favor, tente novamente mais tarde.</div>);
    } else {
      window.location.replace('https://cloudman.pro');
    }
  }
}

loadAppData();
