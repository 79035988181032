import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const EBBus = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'EBBus.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EBTarget = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'EBTarget.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EBRule = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'EBRule.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export { EBRule, EBTarget, EBBus };

