import React, { useState, useEffect } from 'react';
import { useModal } from './ModalMain.js';
import {
    SearchNodesTarget, SearchNodesSource, DiscoveryTerraformNetwork, FindNodeSourceHead, UpdateRoute53BlueGreen,
    MakeStageStateFilesList, CallAPI, FindBackendStorage, FindRegionAndCloud, FindNodesChieldID, AdjustSufixName, modifyTerraformCode, Save,
    FindParams, ListBoxFunc, WaitForApprovalAction, WaitForBuildAction, checkPipelineStatusAndOpenUrl, Commit, findActionInProgress,
} from './Functions';
import { APIPricing } from "./Config.js";

const Action = ({ GlobalNodes, NodeID, onCopyStage, GlobalEdges, onUpdateStatus }) => {
    const NodeType = GlobalNodes[NodeID].type;
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [ShowPipelineID, setShowPipelineID] = useState(false);
    let CurrentLog, PipelineName, NextTestStageName;
    try {
        CurrentLog = GlobalNodes[NodeID].data.Historic[0] || "";
    } catch (error) {
        CurrentLog = "";
    }
    //GlobalNodes[NodeID].data.StateMachine = "Start";
    const [logs, setLogs] = useState(CurrentLog);
    const [GreenPercent, setGreenPercent] = useState(GlobalNodes[NodeID].data.GreenWeight || 0);
    const { activeModal } = useModal();
    const [StateMachine, setStateMachine] = useState(GlobalNodes[NodeID].data.StateMachine);
    const [ApplyLogs, setApplyLogs] = useState(GlobalNodes[NodeID]?.data?.ApplyLogs || false);
    const [DestroyLogs, setDestroyLogs] = useState(GlobalNodes[NodeID]?.data?.DestroyLogs || false);
    const [forceRender, setForceRender] = useState(0);
    const Stage = GlobalNodes[NodeID].data.Param[1][1].toLowerCase();
    const IsTest = Stage.toLowerCase().includes("test");
    const [CPHeadID, Level, ListSources] = FindNodeSourceHead(GlobalEdges, GlobalNodes, "CodePipelineN", "CodePipelineStageN", NodeID);
    //GlobalNodes[NodeID].data.StateMachine = "Copy";
    const CurrentListBox = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID);
    const CurrentBoxID = CurrentListBox.length > 0 ? CurrentListBox[0] : 0;
    const HasStageBox = CurrentBoxID !== 0 || NodeType === "CodePipelineN";
    console.log("HasStageBox", HasStageBox, CurrentBoxID, NodeType)
    try {
        const DevStageID = parseInt(SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[CPHeadID], "CodePipelineStageN")[0]);
        GlobalNodes[DevStageID].data.Param[1][1] = "dev";
        const TestStageID = parseInt(SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[DevStageID], "CodePipelineStageN")[0]);
        GlobalNodes[TestStageID].data.Param[1][1] = "test";
        const NextTestStageID = parseInt(SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[TestStageID], "CodePipelineStageN")[0]);
        NextTestStageName = GlobalNodes[NextTestStageID].data.Param[1][1];
        console.log("NextTestStageName", NextTestStageName)
    } catch (error) {
        console.log("Não encontrou NextTestStageName")
        NextTestStageName = ""
    }
    //PipelineID = GlobalNodes[NodeID]?.data?.PipelineID || "";
    GlobalNodes[NodeID].data.Failed = GlobalNodes[NodeID]?.data?.Failed || false;
    GlobalNodes[NodeID].data.PipelineID = GlobalNodes[NodeID]?.data?.PipelineID || "";
    let Times = 1;
    let PreviusSMState;
    let BlueVersion, GreenVersion;
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');
    const utcDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const Percent = parseInt(GlobalNodes[NodeID].data.Param[4][1]) || 10;
    const BluePercent = 100 - GreenPercent;
    const IsIdentityProvider = parseInt(GlobalNodes[NodeID].data.Param[5][1]) === 1;
    // useEffect para lidar com montagem e desmontagem do componente


    useEffect(() => {
        // Montagem: retoma o polling
        if (NodeType === "CodePipelineStageN" && HasStageBox) {
            startPolling();
        }
        return () => {
            // Desmontagem: pausa o polling
            stopPolling();
        };
    }, [NodeID]);  // Reexecuta o efeito quando NodeID ou StateMachine mudar
    if (GlobalNodes[NodeID].data.StateMachine === "Idle" && GlobalNodes[CPHeadID].data.StateMachine === "Test") {
        GlobalNodes[CPHeadID].data.StateMachine = "Start";
    }

    function StateMachineUpdate(LocalNodeID, NewState) {
        GlobalNodes[LocalNodeID].data.StateMachine = NewState;
        //let StopPolling = StopPollingA && NodeType === "CodePipelineStageN";
        //if (!StopPolling) {
        //    console.log("StopPolling", StopPolling)
        setStateMachine(NewState);
        //}
    }

    useEffect(() => {
        setStateMachine(GlobalNodes[NodeID].data.StateMachine);
    }, [NodeID]);

    useEffect(() => {
        if (NodeType === "CodePipelineStageN") {
            if (Stage !== "dev") {
                setShowPipelineID(true);
            }
        }
    }, [NodeID]);

    //força a renderização a cada 10s
    useEffect(() => {
        const interval = setInterval(() => {
            console.log("Nova renderização")
            //setForceRender(prev => prev + 1);
            if (NodeType === "CodePipelineStageN") {
                startPolling();
            }
        }, 10000 + 1000 * Times);
        return () => clearInterval(interval);
    }, []);

    console.log("IsIdentityProvider", IsIdentityProvider, GlobalNodes[NodeID].data.Param[5][1])
    console.log("CPHeadID, Level", CPHeadID, Level)
    let CopyButton = false, StartTerraformApply, DataSync = false, StartPipeline = false,
        ListBox = [], AbortStage = false, AbortPipeline = false, WasLastSucceed, RetryStage = false, Waiting = false, HideFinishStage;
    let BoxSourceID, BoxTargetID, PrevStageID = 0, Rollback = "";
    let BoxStageID = 0, RoleARN, RegionName, BucketName, CPName;
    if (NodeType === "CodePipelineStageN" && !["dev", "test"].includes(Stage)) {
        Rollback = ": Rollback";
    }
    if (NodeType === "CodePipelineN") {
        try {
            if (GlobalNodes[NodeID].data.StateMachine === "Test") {
                console.log("é test")
                let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
                if (ListStage > 0) {
                    const StageDevID = parseInt(ListStage[0]);
                    console.log("StageDevID", StageDevID)
                    ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageDevID], "CodePipelineStageN");
                    if (ListStage > 0) {
                        const StageTestID = parseInt(ListStage[0]);
                        console.log("StageTestID", StageTestID)
                        if (["Idle", "End"].includes(GlobalNodes[StageTestID].data.StateMachine)) {
                            console.log("Colocou em IDle")
                            GlobalNodes[NodeID].data.StateMachine = "Start";
                        }
                    }
                }
            }

        } catch (error) {
            setStateMachine("Start");
        }
        StartPipeline = StateMachine === "Start";
    } else {//CodePipelineStageN
        AbortStage = NodeType === "CodePipelineStageN" && StateMachine !== "Idle" && StateMachine !== "End"// && !DisableAbortStage;
        AbortPipeline = StateMachine !== "Idle" && NodeType === "CodePipelineN";
        //if ((StateMachine === "Idle") || (StateMachine === "Copy" && IsTest)) { PipelineID = "Unknown yet" }
        StartTerraformApply = StateMachine === "StartTerraformApply";
        DataSync = StateMachine === "DataSync";
        Waiting = StateMachine.includes("Waiting");
        HideFinishStage = GlobalNodes[NodeID].data.TestPass !== "Approved" && IsTest;
        //GlobalNodes[NodeID].data.WasLastSucceed = false;
        WasLastSucceed = GlobalNodes[NodeID].data.WasLastSucceed;
        RetryStage = StateMachine === "End" && !WasLastSucceed && Stage !== "dev" && Stage !== "test";
        console.log("WasLastSucceed", WasLastSucceed, RetryStage)
        console.log("CodePipelineStageN", Level, DataSync)
        console.log("StateMachine", StateMachine, GlobalNodes[NodeID].data.Param[3][1], CopyButton)
        console.log("Logs url", ApplyLogs, DestroyLogs)
        if (HasStageBox) {
            const Params = FindParams(GlobalEdges, GlobalNodes, CPHeadID, NodeID, Stage);
            [BoxStageID, RoleARN, RegionName, BucketName, CPName] = Params;
            console.log("PArams", CPHeadID, NodeID, Stage, Params)
        } else {
            BoxStageID = 0;
        }
        console.log("RoleARN zzz", RoleARN)
        async function UpdateVersionBoxState(GlobalEdges, GlobalNodes) {
            if (BoxStageID !== 0 && !IsTest) {
                console.log("BoxStageID", BoxStageID)
                const ListChild = FindNodesChieldID(GlobalNodes, BoxStageID);
                let ListVersion = [];
                for (let i = 0; i < ListChild.length; i++) {
                    const VersionID = parseInt(ListChild[i])
                    if (GlobalNodes[VersionID].data.Param[1][1].toLowerCase().includes("version")) {
                        ListVersion.push(VersionID)
                    }
                }
                console.log("ListVersion", ListVersion, GlobalNodes[NodeID].data.StateMachine, NodeID)
                for (let i = 0; i < ListVersion.length; i++) {
                    const BoxID = ListVersion[i];
                    if (GlobalNodes[BoxID].data.BlueGreen == "Blue") {
                        BlueVersion = GlobalNodes[BoxID].data.Param[4][1];
                    }
                    if (GlobalNodes[BoxID].data.BlueGreen == "Green") {
                        GreenVersion = GlobalNodes[BoxID].data.Param[4][1];
                    }
                }
                if (["Idle", "FinishStage"].includes(GlobalNodes[NodeID].data.StateMachine)) {
                    for (let i = 0; i < ListVersion.length; i++) {
                        if (i < ListVersion.length - 1) {
                            const [Error, OldEdgesNodes, NewEdgesNodes] = await onCopyStage([ListVersion[i], ListVersion[i], IsTest, "DelBlue", true]);
                        } else {
                            console.log("GlobalNodes[NodeID].data.StateMachine", GlobalNodes[NodeID].data.StateMachine)

                            console.log("Pos em blue")
                            //GlobalNodes[ListVersion[i]].data.BlueGreen = "Blue";
                        }
                    }
                }
            }
        }
        UpdateVersionBoxState(GlobalEdges, GlobalNodes)
    }
    console.log("StateMachine", StateMachine, GlobalNodes[NodeID].data.Param[3][1])
    if (activeModal !== 'modalAction') return null;
    try {
        PipelineName = GlobalNodes[CPHeadID]?.data?.Param[1][1] || "";
    } catch (error) {
        PipelineName = "";
    }
    // Função para iniciar ou retomar o pooling
    const startPolling = async () => {
        console.log("Start Polling for NodeID:", NodeID);
        const now = new Date();
        const timestampSeconds = Math.floor(now.getTime() / 1000);
        console.log("Date zzz", timestampSeconds, GlobalNodes[NodeID].data.timestampSeconds)
        const [ActionName, ActionState, URLList] = await findActionInProgress(GlobalEdges, GlobalNodes, CPHeadID, NodeID, Stage);
        console.log("ActionName", ActionName, ActionState, URLList, GlobalNodes[NodeID].data.StateMachine)
        if (["Idle", "Copy", "Clear", "StartTerraformApply"].includes(GlobalNodes[NodeID].data.StateMachine)) {
            if (IsTest) {
                let PipelineID = "Unknown yet";
                if (GlobalNodes[NodeID].data.PipelineID !== "Unknown yet") {
                    GlobalNodes[NodeID].data.LastPipelineID = GlobalNodes[NodeID].data.PipelineID;
                    console.log("trace Pipeline ID desconehcido")
                }
                GlobalNodes[NodeID].data.PipelineID = PipelineID;
            }
        } else {
            if (IsTest) {
                GlobalNodes[NodeID].data.PipelineID = GlobalNodes[CPHeadID].data.PipelineID;;
                if (GlobalNodes[NodeID].data.StateMachine === "End" && GlobalNodes[CPHeadID].data.StateMachine === "Test") {
                    GlobalNodes[CPHeadID].data.StateMachine = "Start";
                }
            }
            console.log("findActionInProgress", CPHeadID, NodeID, Stage)
            let URLApply = URLList[0] || "";
            console.log("ApplyURL", GlobalNodes[NodeID].data.ApplyLogs, URLApply)
            if (GlobalNodes[NodeID].data.ApplyLogs === false && URLApply !== "") {
                console.log("tentando acionar logs apply")
                if (["Waiting ApplyD", "Waiting TestX", "StageApproval", "Waiting DestroyA", "Waiting DestroyB", "FinishStage", "End",].includes(GlobalNodes[NodeID].data.StateMachine)) {
                    GlobalNodes[NodeID].data.ApplyURL = URLList[0]; GlobalNodes[NodeID].data.ApplyLogs = true; setApplyLogs(true);
                }
            }
            let URLDestroy = URLList[1] || "";
            console.log("DestroyURL", GlobalNodes[NodeID].data.DestroyLogs, URLDestroy)
            if (GlobalNodes[NodeID].data.DestroyLogs === false && URLDestroy !== "") {
                console.log("tentando acionar logs destroy")
                if (["Waiting DestroyB", "FinishStage", "End",].includes(GlobalNodes[NodeID].data.StateMachine)) {
                    GlobalNodes[NodeID].data.DestroyURL = URLList[1]; GlobalNodes[NodeID].data.DestroyLogs = true; setDestroyLogs(true);
                }
            }
        }
        if ((GlobalNodes[NodeID].data.StateMachine === "Waiting ApplyC") //se está no ApplyC e o LastPipelineID = PipelineID então deve voltar pra applyA
            && (GlobalNodes[NodeID].data.LastPipelineID === GlobalNodes[NodeID].data.PipelineID)) {// indica que perdeu no novo ID
            await new Promise(resolve => setTimeout(resolve, 10000));//colocar a SM em ApplyA para nova tentativa
            GlobalNodes[NodeID].data.StateMachine = "Waiting ApplyA";
            console.log("trace PAssou aqui Waiting ApplyA")
            return
        }
        if (GlobalNodes[NodeID].data.StateMachine !== "End") { //
            if (GlobalNodes[NodeID].data.StateMachine === "Waiting ApplyA") {
                if (ActionName === "") {
                    await FuncClear();
                    console.log("trace StartTerraformApply")
                    StateMachineUpdate(NodeID, "StartTerraformApply");
                    return
                } else {
                    console.log("trace Passou aqui AAA")
                    await HandlePreApply();
                    return
                }
            }
            if (ActionName.includes("Approval_to_Start_of_") && ["InProgress"].includes(ActionState)) {
                if (["Idle", "Copy", "Clear", "StartTerraformApply"].includes(GlobalNodes[NodeID].data.StateMachine)) {
                    //pass
                } else {
                    console.log("trace Passou aqui BBBB")
                    await HandlePreApply();
                    return
                }
            }
            if (ActionName.includes("Terraform_Apply_of_") && ["InProgress"].includes(ActionState) && (GlobalNodes[NodeID].data.StateMachine !== "Waiting ApplyD")) {
                console.log("trace waiting ApplyD")
                StateMachineUpdate(NodeID, "Waiting ApplyD")
                return
            }
            if (ActionName.includes("Terraform_Apply_of_") && ["Succeeded"].includes(ActionState)) {
                await onUpdateStatus();
                StateMachineUpdate(NodeID, "StartTest")
                await Save(GlobalEdges, GlobalNodes);
                return
            }
            if (ActionName.includes("Terraform_Apply_of_") && ["Failed", "Abandoned"].includes(ActionState) && !GlobalNodes[NodeID].data.PipelineID.includes("Unknown")) {
                StateMachineUpdate(NodeID, "Failed")
                console.log("Failed in Terraform_Apply_of_")
                //await Save(GlobalEdges, GlobalNodes);
                return
            }
            console.log("Passou aqui CCCC")
            if (ActionName.includes("Approval_to_Test_of_") && ["InProgress"].includes(ActionState) && GlobalNodes[NodeID].data.StateMachine !== "Waiting TestX") {
                StateMachineUpdate(NodeID, "StartTest");
            }
            if (ActionName.includes("Approval_to_Test_of_") && ["Failed", "Abandoned"].includes(ActionState) && GlobalNodes[NodeID].data.StateMachine !== "Waiting TestX") {
                StateMachineUpdate(NodeID, "Failed");
            }
            if (GlobalNodes[NodeID].data.StateMachine === "Waiting TestX") {
                await HandleStartTest();
                return
            }
            console.log("trace Passou aqui DDDD")
            if (ActionName.includes("Approval_Before_Destroy_of_") && !(GlobalNodes[NodeID].data.StateMachine === "BlueGreen") && ["InProgress"].includes(ActionState)) {
                if (GlobalNodes[NodeID].data.StateMachine === "Waiting DestroyA") {
                    await TestPass();
                    return
                } else {
                    StateMachineUpdate(NodeID, "StageApproval");
                }
            }
            if (ActionName.includes("Terraform_Destroy_of_") && ["InProgress"].includes(ActionState)) {
                StateMachineUpdate(NodeID, "Waiting DestroyB");
            }
            console.log("Passou aqui GGGG")
            if (ActionName.includes("Terraform_Destroy_of_") && ["Succeeded", "Failed", "Abandoned"].includes(ActionState)) {
                if (!["Idle", "Copy", "Clear", "StartTerraformApply"].includes(GlobalNodes[NodeID].data.StateMachine)) {
                    StateMachineUpdate(NodeID, "FinishStage");
                }
            }
            console.log("Passou aqui HHHH")
            if (GlobalNodes[NodeID].data.StateMachine === "FinishStage") {
                console.log("PAsssou aqui to end", GlobalNodes[NodeID].data.StateMachine)
                await FuncFinishStage();
                return
            }
        } else { // End
            if (!IsTest) {
                const BoxStageID = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID)[0];
                console.log("End BoxStageID", BoxStageID, NodeID)
                const ListChild = FindNodesChieldID(GlobalNodes, BoxStageID);
                console.log("End FindNodesChieldID", ListChild, NodeID, BoxStageID)
                for (let i = 0; i < ListChild.length; i++) {
                    const LocalNodeID = parseInt(ListChild[i]);
                    const LocalNodeType = GlobalNodes[LocalNodeID].type;
                    if (LocalNodeType === "SBoxN") {
                        const BoxName = GlobalNodes[LocalNodeID].data.Param[1][1].toLowerCase();
                        if (BoxName.includes("version")) {
                            GlobalNodes[LocalNodeID].data.BlueGreen = "Blue";
                        }
                    }
                }
            }
        }
        if (PreviusSMState === GlobalNodes[NodeID].data.StateMachine) {
            Times += 1;
        } else {
            Times = 1;
        }
        PreviusSMState = GlobalNodes[NodeID].data.StateMachine;
    }
    // Função para parar o pooling
    const stopPolling = () => {
        //GlobalNodes[NodeID].data.StopPolling = true;
        //StopPolling = true;
        console.log("Stop Polling for NodeID:", NodeID);
        // Aqui você pode colocar a lógica para limpar intervalos, cancelar requisições etc.
    };

    async function HandleStartPipeline() {
        let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
        console.log("ListStage", ListStage, GlobalNodes[NodeID].type);
        StateMachineUpdate(NodeID, "Test")
        GlobalNodes[NodeID].data.Historic[0] = utcDateTime + " (UTC) : StartPipeline";
        //setLogs(GlobalNodes[NodeID].data.Historic[0]);
        if (ListStage.length > 0) {
            let StageDevID = parseInt(ListStage[0]);
            console.log("StageDevID", StageDevID);
            let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, StageDevID);
            console.log("ListBox", ListBox);
            if (ListBox.length > 0) {
                let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageDevID], "CodePipelineStageN");
                if (ListStage.length > 0) {
                    let StageTestID = parseInt(ListStage[0]);
                    StateMachineUpdate(StageTestID, "Clear")
                }
            }
        }
    }

    async function FuncClear(ChangeSM = true) {
        console.log("Iniciou Clear")
        GlobalNodes[NodeID].data.Failed = false;
        GlobalNodes[NodeID].data.DestroyLogs = false;
        GlobalNodes[NodeID].data.ApplyLogs = false;
        setApplyLogs(false);
        setDestroyLogs(false);
        if (IsTest) {
            setShowPipelineID(false);
            GlobalNodes[NodeID].data.PipelineID = "Unknown yet"
            setShowPipelineID(true);
        }
        if (ChangeSM) {
            StateMachineUpdate(NodeID, "Copy")
        }
        GlobalNodes[NodeID].data.DestroyBlueOrGreen = "";
        console.log("Fim clear")
    }

    if (GlobalNodes[NodeID].data.StateMachine === "Clear") {
        FuncClear().then(() => {
        });
    }
    async function HandleCopyStage() {
        let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
        if (ListStage.length > 0) {
            const NextStageIntID = parseInt(ListStage[0]);
            if (!["End", "Idle"].includes(GlobalNodes[NextStageIntID].data.StateMachine)) {
                const NextStage = GlobalNodes[NextStageIntID].data.Param[1][1];
                alert(`Precisa finalizar o stage ${NextStage} para iniciar o stage atual.`)
                return
            } else {
                GlobalNodes[NodeID].data.StateMachine = "End";
            }
        }
        if (IsTest) {//Aborta qualquer estágio corrente de teste se houver para iniciar um novo
            const [CloudID, RegionID, RG] = FindRegionAndCloud(GlobalNodes, NodeID);
            const RegionName = GlobalNodes[parseInt(RegionID)].data.Param[2][2];
            const RoleARN = GlobalNodes[CloudID].data.Param[5][1];
            let raw = [16, PipelineName, RoleARN, RegionName, "test"];
            const Resp = CallAPI(APIPricing, raw);
            console.log("Aborting test", RoleARN)
        } else {
            const StageList = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
            const PreviousStageID = parseInt(StageList[0]);
            GlobalNodes[NodeID].data.PipelineID = GlobalNodes[PreviousStageID].data.PipelineID;
            //console.log("Non test Pipeline ID", GlobalNodes[NodeID].data.PipelineID)
        }
        let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID);
        if (ListBox.length > 0) {
            BoxTargetID = parseInt(ListBox[0]);
            const ListPreviousStage = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
            if (ListPreviousStage.length > 0) {
                PrevStageID = parseInt(ListPreviousStage[0]);
                ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, PrevStageID);
                if (ListBox.length > 0) {
                    BoxSourceID = parseInt(ListBox[0]);
                }
            }
            //console.log("GlobalNodes Pre", GlobalNodes)
            const [Error, OldEdgesNodes, NewEdgesNodes] = await onCopyStage([BoxSourceID, BoxTargetID, IsTest, "Copy", false]);
            if (Error) {
                //alert(`Erro encontrado no estagio`);
            } else {
                GlobalNodes[NodeID].data.OldEdgesNodes = OldEdgesNodes;
                GlobalNodes[NodeID].data.NewEdgesNodes = NewEdgesNodes;
                GlobalNodes[NodeID].data.R53Data = NewEdgesNodes[2];
                //console.log("GlobalNodes[NodeID].data.R53Data", GlobalNodes[NodeID].data.R53Data)
                if (Error) {
                    StateMachineUpdate(NodeID, "Idle");
                    GlobalNodes[CPHeadID].data.StateMachine = "Start";
                    return
                } else {
                    StateMachineUpdate(NodeID, "StartTerraformApply");
                    const currentDateTimeGMT = new Date().toISOString();
                    GlobalNodes[NodeID].data.CopyDateTime = currentDateTimeGMT;
                }
                //console.log("GlobalNodes Pos", GlobalNodes)
                //console.log("Pre Salvou")
                await Save(GlobalEdges.concat(NewEdgesNodes[0]), GlobalNodes.concat(NewEdgesNodes[1]));
                //console.log("Salvou")
            }
        }
    }

    async function HandleStartTerraformApply() {
        //Faz o ListBuild.txt
        setApplyLogs(false);
        StateMachineUpdate(NodeID, "Waiting ApplyA");
        const now = new Date();
        const timestampSeconds = Math.floor(now.getTime() / 1000);
        GlobalNodes[NodeID].data.timestampSeconds = timestampSeconds;
        console.log("New date ", timestampSeconds, GlobalNodes[NodeID].data.timestampSeconds)
        const OldEdgesNodes = GlobalNodes[NodeID].data.OldEdgesNodes;
        const NewEdgesNodes = GlobalNodes[NodeID].data.NewEdgesNodes;
        const MakeStageStateFilesListResp = MakeStageStateFilesList(GlobalEdges, GlobalNodes, BoxStageID, OldEdgesNodes, NewEdgesNodes, Stage);
        let [ListBuild, ListID, ListIDBlue] = MakeStageStateFilesListResp;
        GlobalNodes[NodeID].data.ListTerraformDestroyIDGreen = ListID;
        GlobalNodes[NodeID].data.ListTerraformDestroyIDBlue = ListIDBlue;
        GlobalNodes[NodeID].data.ListBuild = ListBuild;
        ListBuild.NextTestStageName = NextTestStageName;
        ListBuild = JSON.stringify(ListBuild);
        console.log("ListID", ListID, ListBuild);
        for (let i = 0; i < ListID.length; i++) {
            const LocalTFID = ListID[i];
            const LastDateTime = GlobalNodes[LocalTFID]?.data?.Compile?.LastDateTime || "";
            console.log("LastDateTime", LastDateTime)
            const CopyDateTime = GlobalNodes[NodeID].data.CopyDateTime;
            const TFName = AdjustSufixName(GlobalEdges, GlobalNodes, LocalTFID, true).data.Param[1][1];
            console.log("Esteve aqui xxx", ListID[i])
            console.log("LocalTFID", LocalTFID, GlobalNodes[LocalTFID].data.Param[1][1])
            if (LastDateTime === "") {
                alert(`State ${TFName} precisa ser compilado.`);
                StateMachineUpdate(NodeID, "StartTerraformApply")
                console.log("Esteve aqui bbb", ListID[i])
                return
            }
            if (LastDateTime === "Error") {
                alert(`State ${TFName} está com erro e precisa ser recompilado.`);
                StateMachineUpdate(NodeID, "StartTerraformApply")
                console.log("Esteve aqui ccc", ListID[i])
                return
            }
            if (LastDateTime < CopyDateTime) {
                if (!ListIDBlue.includes(ListID[i])) {
                    alert(`State ${TFName} precisa ser recompilado.`);
                    StateMachineUpdate(NodeID, "StartTerraformApply")
                    console.log("Esteve aqui ddd", ListID[i])
                    return
                }
            }
        }
        console.log("Esteve aqui aaaa")
        for (let i = 0; i < ListID.length; i++) {
            let LocalTFID = ListID[i];
            const RespCommit = Commit(GlobalEdges, GlobalNodes, CPHeadID, NodeID, LocalTFID);
            if (RespCommit === null) {
                alert("Erro no commit!")
                StateMachineUpdate(NodeID, "StartTerraformApply")
                return
            }
        }
        console.log("HandleStartTerraformApply", ListBuild, BoxStageID);
        //Salva Lista de stages na bucket backend (ListBuild.txt)
        console.log("ListBuild", ListBuild)
        let raw = [5, ListBuild, RoleARN, BucketName, CPName, Stage.toLowerCase()];
        const Resp = CallAPI(APIPricing, raw);
        if (Resp === null) { //falha na api
            alert("Falha no acesso a internet!")
            StateMachineUpdate(NodeID, "StartTerraformApply")
            return
        }
        StateMachineUpdate(NodeID, "Waiting ApplyC");
        await Save(GlobalEdges, GlobalNodes);
        WaitingApplyC();
    }

    async function WaitingApplyC() {
        try {
            GlobalNodes[NodeID].data.Failed = false;
            //inicia o CodePipeline se o stage == test
            if (IsTest) {
                const raw = [6, CPName, RoleARN, RegionName];
                console.log("raw", raw)
                const PipelineAPI = await CallAPI(APIPricing, raw, true);
                const PipelineID = PipelineAPI.body;
                if (PipelineID === null) { //falha na api
                    StateMachineUpdate(NodeID, "StartTerraformApply")
                    alert("Erro ao iniciar o Pipeline!")
                    return
                }
                console.log("PipelineID", PipelineAPI);
                if (PipelineID !== GlobalNodes[NodeID].data.LastPipelineID) {
                    GlobalNodes[NodeID].data.LastPipelineID = GlobalNodes[NodeID].data.PipelineID;
                    console.log("PipelineID pos", PipelineID);
                    GlobalNodes[CPHeadID].data.PipelineID = PipelineID;
                    GlobalNodes[NodeID].data.PipelineID = PipelineID;
                    setShowPipelineID(false);
                    setShowPipelineID(true);
                    await new Promise(resolve => setTimeout(resolve, 10000));

                    const ListID = GlobalNodes[NodeID].data.ListTerraformDestroyIDGreen;
                    for (let i = 0; i < ListID.length; i++) {
                        let LocalTFID = ListID[i];
                        const [ListStates, BackendID, Loop] = DiscoveryTerraformNetwork(GlobalEdges, GlobalNodes, LocalTFID);
                        console.log("BackendID LastDeploywith", BackendID, LocalTFID, ListID)
                        const BackendName = GlobalNodes[BackendID].data.Param[1][1]
                        GlobalNodes[LocalTFID].data.LastDeployWith = BackendName;
                        console.log("LastDeployWith", LocalTFID, GlobalNodes[LocalTFID].data.LastDeployWith)
                    }
                    StateMachineUpdate(NodeID, "Waiting ApplyC");
                    await Save(GlobalEdges, GlobalNodes);
                }
            }
        } catch (error) {
            HandleStartTerraformApply();
            alert("Erro no applyC")
        }
    }

    async function HandlePreApply() {
        let [Progressing, Token, CPName, RoleARN, RegionName] = await WaitForApprovalAction(GlobalEdges, GlobalNodes, CPHeadID, NodeID, Stage, setStateMachine, `Approval_to_Start_of_${Stage}_${PipelineName}`, "Waiting ApplyD", "Approved", "InProgress")
        /*if (Progressing) {
            await new Promise(resolve => setTimeout(resolve, 10000));
            const URL = await checkPipelineStatusAndOpenUrl(GlobalNodes, NodeID, CPName, RoleARN, RegionName, PipelineID, Stage, `Terraform_Apply_of_${Stage}_${PipelineName}`, 0)
            console.log("URL apply", URL)
            GlobalNodes[NodeID].data.ApplyURL = URL;
            GlobalNodes[NodeID].data.ApplyLogs = true;
            setApplyLogs(true);
            await Save(GlobalEdges, GlobalNodes);
        }*/
    }

    async function HandleStartTest() {
        StateMachineUpdate(NodeID, "Waiting TestX")
        let [Progressing, Token, CPName, RoleARN, RegionName] = await WaitForApprovalAction(GlobalEdges, GlobalNodes, CPHeadID, NodeID, Stage, setStateMachine, `Approval_to_Test_of_${Stage}_${PipelineName}`, "PreStageApproval", "Approved")
        if (Progressing) {
            await Save(GlobalEdges, GlobalNodes);
        }
    }

    if (GlobalNodes[NodeID].data.StateMachine === "PreStageApproval") {
        console.log("R53Data", GlobalNodes[NodeID].data.R53Data[0])
        if (IsTest) {
            StateMachineUpdate(NodeID, "StageApproval")
        } else {
            if (GlobalNodes[NodeID].data.R53Data[0] !== "") {
                StateMachineUpdate(NodeID, "BlueGreen")
            } else {
                StateMachineUpdate(NodeID, "StageApproval")
            }
        }
    }

    async function HandleStageApproved() {
        if (!IsTest) {
            GlobalNodes[NodeID].data.DestroyBlueOrGreen = " Blue";
        }
        GlobalNodes[NodeID].data.TestPass = "Approved";
        StateMachineUpdate(NodeID, "Waiting DestroyA")
        await Save(GlobalEdges, GlobalNodes);
        await TestPass();
    }

    async function HandleStageFail() {
        if (!IsTest) {
            GlobalNodes[NodeID].data.DestroyBlueOrGreen = " Green";
        }
        GlobalNodes[NodeID].data.TestPass = "Rejected";
        StateMachineUpdate(NodeID, "Waiting DestroyA")
        await Save(GlobalEdges, GlobalNodes);
        await TestPass();
    }

    async function TestPass() {
        const Status = GlobalNodes[NodeID].data.TestPass;
        const RespWaitForApprovalAction = await WaitForApprovalAction(GlobalEdges, GlobalNodes, CPHeadID, NodeID, Stage, setStateMachine, `Approval_Before_Destroy_of_${Stage}_${PipelineName}`, "Waiting DestroyB", "Approved")
        const [Progressing, Token, CPName, RoleARN, RegionName] = RespWaitForApprovalAction;
        if (Progressing) {
            const URL = await checkPipelineStatusAndOpenUrl(GlobalNodes, NodeID, CPName, RoleARN, RegionName, GlobalNodes[NodeID].data.PipelineID, Stage, `Terraform_Destroy_of_${Stage}_${PipelineName}`, 0)
            if (URL !== null) {
                GlobalNodes[NodeID].data.DestroyURL = URL;
                GlobalNodes[NodeID].data.DestroyLogs = true;
                setDestroyLogs(true);
            }
            GlobalNodes[NodeID].data.TestPass = Status;
            let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID)
            if (ListBox.length > 0) {
                BoxTargetID = parseInt(ListBox[0]);
                if (CPHeadID !== 0) {
                    console.log("Prod aqui")
                    /*if (Status === "Approved") {
                        let ListR53 = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "R53ZoneN");
                        console.log("ListR53", ListR53)
                        if (ListR53.length === 0) {
                            await WaitForBuildAction(GlobalEdges, GlobalNodes, CPHeadID, NodeID, Stage, setStateMachine, `Terraform_Destroy_of_${Stage}_${PipelineName}`, "FinishStage")
                        } else {
                            StateMachineUpdate(NodeID, "BlueGreen")
                            const R53ID = parseInt(ListR53[0])
                            GlobalNodes[NodeID].data.R53ZoneID = R53ID;
                        }
                    }*/
                    if (Status === "Rejected") {
                        let ListBuild = GlobalNodes[NodeID].data.ListBuild;
                        ListBuild.ListStatesBlue = ListBuild.ListStates;
                        ListBuild.Approved = false;
                        ListBuild.NextTestStageName = NextTestStageName;
                        ListBuild = JSON.stringify(ListBuild);
                        let raw = [5, ListBuild, RoleARN, BucketName, CPName, Stage.toLowerCase()];
                        const Resp = CallAPI(APIPricing, raw, true);
                        if (Resp === null) { //falha na api
                            alert("Falaha no acesso a internet!")
                            return
                        }
                        console.log("delete green here", ListBuild);
                    }
                }
            }
            await Save(GlobalEdges, GlobalNodes);
        }
    }

    async function HandleShiftToGreen() {
        console.log("HandleShiftToGreen")
        let GreenWeight = GlobalNodes[NodeID].data?.GreenWeight || 0;
        GreenWeight += Percent;
        setGreenPercent(GreenWeight);
        GlobalNodes[NodeID].data.GreenWeight = GreenWeight;
        console.log("GlobalNodes[NodeID].data.R53Data", GlobalNodes[NodeID].data.R53Data)
        const Domain = GlobalNodes[NodeID].data.R53Data[0];
        const SetIdentifierBlue = GlobalNodes[NodeID].data.R53Data[1];
        const update_A = GlobalNodes[NodeID].data.R53Data[2];
        const update_AAAA = GlobalNodes[NodeID].data.R53Data[3];
        const SetIdentifierGreen = GlobalNodes[NodeID].data.R53Data[4];
        const BlueWeight = 100 - GreenWeight;
        const StageLowerCase = Stage.toLowerCase();
        let ParameterID, JsonUpdate;
        //let raw = [12, Domain, RoleARN, SetIdentifierBlue, BlueWeight, update_A, update_AAAA, SetIdentifierGreen, GreenWeight];
        let ListParam = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[CPHeadID], "SSMParameterN");
        console.log("ListParam", ListParam)
        if (ListParam.length > 0) {
            ParameterID = parseInt(ListParam[0]);

        } else {
            alert("Para o blue green funcionar o CodePipeline deve conctar com a entrada de um SSM Param Store!")
            return
        }
        console.log("ParameterID", ParameterID)
        const ParameterName = AdjustSufixName(GlobalEdges, GlobalNodes, ParameterID, true).data.Param[1][1];
        const [CloudID, RegionID, RG] = FindRegionAndCloud(GlobalNodes, ParameterID);
        console.log("ParameterID", ParameterID)
        const ParamStoreRegion = GlobalNodes[parseInt(RegionID)].data.Param[2][2];
        if (GreenWeight === 100) {
            JsonUpdate = {
                [StageLowerCase]: {
                    blue: GreenVersion,
                }
            };
        } else {
            JsonUpdate = {
                [StageLowerCase]: {
                    blue: BlueVersion,
                    green: GreenVersion,
                    greenPercentage: GreenWeight.toString()
                }
            };
        }

        console.log("ParameterName, RoleARN, RegionName, JsonUpdate", ParameterName, RoleARN, ParamStoreRegion, JsonUpdate)
        let raw = [17, ParameterName, RoleARN, ParamStoreRegion, JsonUpdate];
        const Resp = CallAPI(APIPricing, raw, true);
        if (Resp === null) { //falha na api
            alert("Falaha no acesso a internet!")
            return
        }
        if (GreenWeight >= 100) {
            GlobalNodes[NodeID].data.GreenWeight = 0;
            StateMachineUpdate(NodeID, "StageApproval")
        }
    }

    async function HandleRollback() {
        //await FinalPass(false);
    }

    async function FuncFinishStage() {
        let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID)
        console.log("FinishStage list Box", ListBox)
        if (ListBox.length > 0) {
            let NextStageIntID = 0;
            let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
            if (ListStage.length > 0) { NextStageIntID = parseInt(ListStage[0]); }
            BoxTargetID = parseInt(ListBox[0]);
            if (CPHeadID !== 0) {
                const TestPassVar = GlobalNodes[NodeID].data.TestPass;
                console.log("TestPassVar", TestPassVar)
                if (IsTest) {
                    try {
                        for (let i = 0; i < GlobalNodes[NodeID].data.ListTerraformDestroyIDGreen.length; i++) {
                            const LocalTFID = GlobalNodes[NodeID].data.ListTerraformDestroyIDGreen[i];
                            GlobalNodes[LocalTFID].data.LastDeployWith = "";
                        }
                    } catch (error) {
                        //pass
                    }
                } else {
                    if (TestPassVar === "Approved") {//Delete Blue e promove Green para Blue
                        const [Error, OldEdgesNodes, NewEdgesNodes] = await onCopyStage([BoxTargetID, BoxTargetID, IsTest, "DelBlue", false]);
                        try {
                            for (let i = 0; i < GlobalNodes[NodeID].data.ListTerraformDestroyIDBlue.length; i++) {
                                const LocalTFID = GlobalNodes[NodeID].data.ListTerraformDestroyIDBlue[i];
                                GlobalNodes[LocalTFID].data.LastDeployWith = "";
                                console.log("LastDeployWith", LocalTFID, GlobalNodes[LocalTFID].data.LastDeployWith)
                            }
                        } catch (error) {
                            //pass
                        }
                        if (NewEdgesNodes) {
                            console.log("NewEdgesNodes", NewEdgesNodes)
                            await Save(GlobalEdges.concat(NewEdgesNodes[0]), GlobalNodes.concat(NewEdgesNodes[1]));
                        }
                    } else {// Delete Green
                        console.log("delete green here", GlobalNodes[NodeID].data.ListTerraformDestroyIDGreen)
                        const [Error, OldEdgesNodes, NewEdgesNodes] = await onCopyStage([BoxTargetID, BoxTargetID, IsTest, "DelGreen", false]);
                        GlobalNodes[NodeID].data.GreenPercent = 0;
                        try {
                            for (let i = 0; i < GlobalNodes[NodeID].data.ListTerraformDestroyIDGreen.length; i++) {
                                const LocalTFID = GlobalNodes[NodeID].data.ListTerraformDestroyIDGreen[i];
                                GlobalNodes[LocalTFID].data.LastDeployWith = "";
                                console.log("LastDeployWith", LocalTFID, GlobalNodes[LocalTFID].data.LastDeployWith)
                            }
                        } catch (error) {
                            //pass
                        }
                        await Save(GlobalEdges.concat(NewEdgesNodes[0]), GlobalNodes.concat(NewEdgesNodes[1]));
                    }
                }
                if (TestPassVar === "Approved") {
                    if (NextStageIntID !== 0) {
                        console.log("Iniciou o proximo stage")
                        GlobalNodes[NextStageIntID].data.StateMachine = "Clear";
                        GlobalNodes[NextStageIntID].data.GreenPercent = 0;
                    }
                    GlobalNodes[NodeID].data.WasLastSucceed = true;
                    RetryStage = false;
                } else {
                    GlobalNodes[NodeID].data.WasLastSucceed = false;
                    RetryStage = true;
                }
                StateMachineUpdate(NodeID, "End")
                if (IsTest) {
                    GlobalNodes[CPHeadID].data.StateMachine = "Start";
                }
                await onUpdateStatus();
                let NewNodes = JSON.parse(JSON.stringify(GlobalNodes));
                await Save(GlobalEdges, GlobalNodes);
                console.log("Save Final", NewNodes, GlobalNodes)
            }
        }
        console.log("Blue or green", GlobalNodes[NodeID].data.DestroyBlueOrGreen)
    }

    async function HandleAbortStage() {
        let Confirm = window.confirm("Are you sure you want to abort the pipeline execution? This action cannot be undone.");
        if (Confirm) {
            if ((StateMachine === "StartTerraformApply" || StateMachine === "Copy") && IsTest) {
                //pass
                GlobalNodes[NodeID].data.WasLastSucceed = true;
                setApplyLogs(false);
                setDestroyLogs(false);
            } else {
                const PipelineID = GlobalNodes[CPHeadID].data.PipelineID;
                let raw = [9, CPName, RoleARN, RegionName, PipelineID, true];
                const Resp = await CallAPI(APIPricing, raw, true);
                GlobalNodes[NodeID].data.WasLastSucceed = false;
            }
            StateMachineUpdate(NodeID, "End")
            RetryStage = true;
            console.log("WasLastSucceed", GlobalNodes[NodeID].data.WasLastSucceed, RetryStage)
            GlobalNodes[CPHeadID].data.StateMachine = "Start";
        }
    }

    async function HandleAbortPipeline() {
        //
    }

    async function HandleRetryStage() {
        const PipelineID = GlobalNodes[NodeID].data.PipelineID;
        setApplyLogs(false);
        setDestroyLogs(false);
        //Retry pipeline stage
        let raw = [10, CPName, RoleARN, RegionName, PipelineID, Stage];
        try {
            const Resp = await CallAPI(APIPricing, raw, true);
            console.log("Resp Retry", Resp)
            if (Resp.status === 422) {
                alert(`It was not possible to retry the stage {Stage}. Try doing it directly in the CodePipeline console.`);
                return;
            }
            if (IsTest) {
                FuncClear(false);
                HandleStartTerraformApply();
            } else {
                StateMachineUpdate(NodeID, "Clear");
            }
        } catch (error) {
            console.error("Erro ao tentar executar o estágio de retry:", error);
        }
    }


    async function HandleOpenURL(URLType) {
        const URL = GlobalNodes[NodeID].data[URLType];
        //console.log("URL open", URLType, URL)
        window.open(URL, GlobalNodes[NodeID].data.PipelineID + URLType);
    }
    async function HandleFinishFail(URLType) {
        StateMachineUpdate(NodeID, "End")
        GlobalNodes[CPHeadID].data.StateMachine = "Start";
        GlobalNodes[NodeID].data.Failed = true;
        GlobalNodes[NodeID].data.WasLastSucceed = false;
        WasLastSucceed = false;
    }
    if (HasStageBox) {
        return (
            <div style={{ backgroundColor: 'white' }}>
                <div className="custom-node__header">
                    <h2 className="custom-drag-handle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Action
                    </h2>
                    {StartPipeline && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStartPipeline()} style={{ marginRight: '10px' }}>
                                Start Pipeline
                            </button>
                        </div>
                    )}
                    {(StateMachine === "Copy") && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleCopyStage()} style={{ marginRight: '10px' }}>
                                Copy Version Box
                            </button>
                        </div>
                    )}
                    {(StateMachine === "StartTerraformApply") && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStartTerraformApply()} style={{ marginRight: '10px' }}>
                                Start Terraform Apply
                            </button>
                        </div>
                    )}
                    {(StateMachine === "StartTest") && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStartTest()} style={{ marginRight: '10px' }}>
                                Start Test
                            </button>
                        </div>
                    )}
                    {(StateMachine === "StageApproval") && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStageApproved()} style={{ marginRight: '10px' }}>
                                Stage Approved
                            </button>
                            <button id="focus" onClick={() => HandleStageFail()} style={{ marginRight: '10px' }}>
                                Stage Fail
                            </button>
                        </div>
                    )}
                    {(StateMachine === "BlueGreen") && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <br />
                            <h1>Blue {BluePercent}% Green {GreenPercent}%</h1>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <button id="focus" onClick={() => HandleShiftToGreen()} style={{ marginRight: '10px' }}>
                                    Shift {Percent}% to Green
                                </button>
                                <button id="focus" onClick={() => HandleRollback()} style={{ marginRight: '10px' }}>
                                    Rollback
                                </button>
                            </div>
                        </div>
                    )}
                    {(AbortStage &&
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleAbortStage()} style={{ marginRight: '10px', backgroundColor: 'red', color: 'white' }}>
                                Abort Stage
                            </button>
                        </div>
                    )}
                    {(AbortPipeline &&
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleAbortPipeline()} style={{ marginRight: '10px' }}>
                                Abort All Pipeline
                            </button>
                        </div>
                    )}
                    {(RetryStage &&
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleRetryStage()} style={{ marginRight: '10px' }}>
                                Retry Stage
                            </button>
                        </div>
                    )}
                    {(ApplyLogs &&
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleOpenURL("ApplyURL")} style={{ marginRight: '10px', backgroundColor: 'green', color: 'white' }}>
                                Apply Logs
                            </button>
                        </div>
                    )}
                    {(DestroyLogs &&
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleOpenURL("DestroyURL")} style={{ marginRight: '10px', backgroundColor: 'green', color: 'white' }}>
                                Destroy Logs
                            </button>
                        </div>
                    )}
                    {(Waiting &&
                        <div className="Variable" style={{ fontSize: '20px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            {StateMachine.slice(0, -1)} {GlobalNodes[NodeID].data.DestroyBlueOrGreen}...
                        </div>
                    )}
                    {(StateMachine === "Failed") && (
                        <div className="Variable" style={{ fontSize: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <br />
                            <span>The stage failed during the apply command.</span>
                            <button
                                id="focus"
                                onClick={() => HandleFinishFail()}
                                style={{ marginTop: '10px', backgroundColor: 'green', color: 'white' }}
                            >
                                Finish
                            </button>
                        </div>
                    )}

                    {(StateMachine === "End") && (
                        <div className="Variable" style={{ fontSize: '20px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            {GlobalNodes[NodeID].data.Failed ? 'Stage Failed.' : 'Stage completed.'}
                        </div>
                    )}
                </div>
                {loadingLogs && <div>Loading logs...</div>}
                {ShowPipelineID && (
                    <div className="Variable" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>
                        <br />
                        Pipeline ID: {GlobalNodes[NodeID].data.PipelineID}
                    </div>
                )}
                {logs && (
                    <div className="pipeline-logs" style={{ marginTop: '10px', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                        <h3>Pipeline Logs</h3>
                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            {logs}
                        </pre>
                    </div>
                )}
                {StateMachine}{" LastPipelineID:"} {GlobalNodes[NodeID].data.LastPipelineID}
            </div>
        );
    } else {//erro não encontrada box stage
        return (
            <div style={{ backgroundColor: 'white' }}>
                <div className="custom-node__header">
                    <h2 className="custom-drag-handle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Action
                    </h2>
                    <br></br>

                    <h2 className="custom-drag-handle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Stage Box not found!
                    </h2>


                </div>
            </div >
        )
    }
}

export { Action };
