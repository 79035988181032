import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import 'reactflow/dist/style.css';
import { Constraints, GNodes, GEdges } from '../NodesData';
import GenericNode from '../GenericNode';
import GenericBoxNode from '../GenericBoxNode';
import { CalculateScale, SearchNodesSource } from '../Functions';
const MinWidth = 120;
const MinHeight = 120;
const MaxWidth = 360;

const dragHandleStyle = {
  display: 'inline-block',
  width: 300,
  height: 30,
  backgroundColor: 'teal',
  marginLeft: 5,
  borderRadius: 1,
  cursor: 'pointer',
};


const LockStyle = {
  //display: 'block',
  position: "absolute",
  right: 5,
  width: 40,
  height: 40,
};

const VPC = ({ id, data, selected }) => {
  const URLIcon = Constraints.IconsURL + 'VPCB.png';
  const [isMouseNear, setIsMouseNear] = useState(false);
  const ListStage = SearchNodesSource(GEdges, GNodes, GNodes[parseInt(id)], "CodePipelineStageN");
  if (ListStage.length > 0) {
    const StageName = GNodes[parseInt(ListStage[0])].data.Param[1][1];
    data.Param[13][1] = StageName;
  }
  let BoxName = data.Param[1][1];
  if (data.Param[13][1] !== "") {
    BoxName += "-" + data.Param[13][1];
  }
  let Opacity = data.Opacity;
  let Cost = "";
  let ShowCost;
  try {
    ShowCost = data.ShowCost;
    Cost = "$" + data.Cost;
  } catch (error) {
    ShowCost = false;
  }
  if (Cost === undefined || ShowCost === false) { Cost = ""; }
  let Item = data.Param;
  let IPV6 = (Item[4][1] === 0 && Item[4][2][1][1] === true) ||
    (Item[4][1] === 1 && Item[4][2][2][1] !== "") ||
    (Item[4][1] === 2 && Item[4][2][3][2][2][1] !== "");
  let DualStack = "(IPV4 Only)";
  if (IPV6) { DualStack = "(IPV4 and IPV6)"; }
  //console.log("IPV6", IPV6, DualStack)
  return (
    <div
      onMouseEnter={() => setIsMouseNear(true)}
      onMouseLeave={() => setIsMouseNear(false)}
    >
      <GenericBoxNode
        id={id}
        data={data}
        selected={selected}
        HasHandle={false}
        borderWidth={4}
      >
        <div style={{
          position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
          borderRadius: "15px 15px 0 0", border: data.EnableBorder ? data.Border : 'none', opacity: Opacity
        }}>
          <div className="custom-drag-handle" style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img src={URLIcon} style={{ borderRadius: "5px", margin: "0px 11px" }} />
              {ShowCost ?
                (`${BoxName} ${data.Param[3][2][1][1]} ${DualStack} ${Cost}`) :
                (`${BoxName} ${data.Param[3][2][1][1]} ${DualStack}`)
              }

            </div>
          </div>

          <div style={{ borderRadius: "10%" }}>
          </div>
        </div>
        <Handle
          id="right"
          position={Position.Right}
          type="source"
          isConnectable={true} />
        <Handle
          id="left"
          position={Position.Left}
          type="source"
          isConnectable={true} />
        <Handle
          id="left"
          position={Position.Top}
          type="source"
          isConnectable={true} />
        <Handle
          id="top"
          position={Position.Bottom}
          type="source"
          isConnectable={true} />
      </GenericBoxNode>
    </div>
  );
};

const SBox = ({ id, data, selected, style }) => {
  const ListStage = SearchNodesSource(GEdges, GNodes, GNodes[parseInt(id)], "CodePipelineStageN");
  if (ListStage.length > 0) {
    const StageName = GNodes[parseInt(ListStage[0])].data.Param[1][1];
    data.Param[4][1] = StageName;
  }
  const Background = data.Param[3][1] || 0;
  //console.log("Background", Background)
  // Array com as cores pastéis neutras em ordem
  const pastelColors = ["FFFFFF", "#FFFFFF", "#FFF9DB", "#DFF0D8", "#D9EAF3", "#FADBD8", "#E0E0E0"];
  // Variável BackgroundColor com o valor correspondente à cor pastel
  let background = pastelColors[Background];
  //GNodes[parseInt(id)].style.background = pastelColors[Background];
  //console.log("background", background)
  let Opacity = data.Opacity;
  let Cost = "";
  let ShowCost;
  let BoxName = data.Param[1][1];
  let Color = "#f2f2f2";
  let SelectedColor = "#A2A2A2";
  if (data.Param[4][1] !== "") {
    BoxName += "-" + data.Param[4][1];
  }
  let BlueGreen = "";
  try {
    BlueGreen = data.BlueGreen ? ` (${data.BlueGreen})` : "";
    if (BlueGreen === " (Green)") {
      Color = "#22DD22";
      SelectedColor = "#11AA11";
    }
    if (BlueGreen === " (Blue)") {
      Color = "#05b0fb";
      SelectedColor = "#0590CC";
    }
  } catch (error) {
    BlueGreen = "";
  }
  BoxName += BlueGreen;
  try {
    ShowCost = data.ShowCost;
    Cost = "$" + data.Cost;
  } catch (error) {
    ShowCost = false;
  }
  if (Cost === undefined || ShowCost === false) { Cost = ""; }
  try {
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    let Height;
    if (GNodes[parseInt(id)] && GNodes[parseInt(id)].style && GNodes[parseInt(id)].style.height) {
      Height = GNodes[parseInt(id)].style.height;
    } else {
      Height = MinHeight;
    }
    let Width;
    if (GNodes[parseInt(id)] && GNodes[parseInt(id)].style && GNodes[parseInt(id)].style.width) {
      Width = GNodes[parseInt(id)].style.width;
    } else {
      Width = MinWidth;
    }
    const scaleFactor = CalculateScale(Math.sqrt(Height * Height + Width * Width), .7) * 2;
    const baseStyles = {
      img: { width: 80, height: 80, margin: "0px 11px" },
      text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" },
      providerImg: { width: 60, height: 60, margin: "0px 11px" },
      title: { fontSize: 32 }
    }
    const scaledStyles = {
      img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor * 3, height: baseStyles.img.height * scaleFactor },
      text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
      providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
      title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }
    }
    return (
      <>
        <GenericBoxNode
          id={id}
          data={data}
          selected={selected}
          HasHandle={false}
          borderWidth={6 * scaleFactor}
        >
          <div style={{
            position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? SelectedColor : Color,
            borderRadius: "15px 15px 0 0", opacity: Opacity
          }}>
            <div className="custom-drag-handle" style={{ ...scaledStyles.title, textAlign: "center" }}>
              {ShowCost ?
                (`${BoxName} ${Cost}`) :
                (`${BoxName}`)
              }
            </div>
            <div style={{ borderRadius: "10%" }}>
            </div>
          </div >
          <Handle
            id="right"
            position={Position.Right}
            type="source"
            isConnectable={true} />
          <Handle
            id="left"
            position={Position.Left}
            type="source"
            isConnectable={true} />
          <Handle
            id="left"
            position={Position.Top}
            type="source"
            isConnectable={true} />
          <Handle
            id="top"
            position={Position.Bottom}
            type="source"
            isConnectable={true} />
        </GenericBoxNode >
      </>
    );
  } catch (error) {
    //pass
  }
};


const AZ = ({ id, data, selected, style }) => {
  try {
    let AZLabel = String.fromCharCode(97 + data.Param[2][1])
    let Opacity = data.Opacity;
    return (
      <GenericBoxNode
        id={id}
        data={data}
        selected={data.Select}
        HasHandle={false}
        borderWidth={2}
      >
        <div
          style={{
            opacity: Opacity
          }}
          className="custom-drag-handle"
        >
          &nbsp;&nbsp; AZ-{AZLabel}
        </div>
        <div style={{ padding: 10, opacity: Opacity }}></div>
      </GenericBoxNode>
    );
  } catch (error) {
    //pass
  }
};
export default AZ;


const VPCPeeringC = ({ data, selected }) => {
  const URLIcon = 'url("' + Constraints.IconsURL + 'VPCPeeringC.png")';
  let LabelName = data.Param[1][1];
  return (
    <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
  );
};

const VPCFlowLogs = ({ data, selected }) => {
  const URLIcon = 'url("' + Constraints.IconsURL + 'VPCFlowLogs.png")';
  let LabelName = data.Param[1][1];
  return (
    <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
  );
};
export { VPC, AZ, SBox, VPCPeeringC, VPCFlowLogs };




