
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const Cognito = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'Cognito.png")';
    let LabelName = data.Param[1][1];
    const handleContextMenu = (event) => {
        event.preventDefault(); // impede que o menu de contexto padrão seja exibido
        console.log('Botão direito do mouse clicado!');
        // coloque aqui a lógica que você quer que seja executada no clique do botão direito

    };
    return (
        <>
            <div onContextMenu={handleContextMenu}></div>
            <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
        </>
    );
};

const CogUPool = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CogUPool.png")';
    let LabelName = data.Param[1][1];
    return (
        <>
            <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
        </>
    );
};

const CogUPoolClient = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CogUPoolClient.png")';
    let LabelName = data.Param[1][1];
    return (
        <>
            <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
        </>
    );
};
const CogUPoolDomain = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CogUPoolDomain.png")';
    let LabelName = data.Param[1][1];
    return (
        <>
            <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
        </>
    );
};
const CogSchema = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CogSchema.png")';
    let LabelName = data.Param[1][1];
    return (
        <>
            <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
        </>
    );
};
const CogUserGroup = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CogUserGroup.png")';
    let LabelName = data.Param[1][1];
    return (
        <>
            <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
        </>
    );
};

export { Cognito, CogUPool, CogUPoolClient, CogUPoolDomain, CogSchema, CogUserGroup };

