import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const AthenaDB = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'AthenaDB.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const GlueJob = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'GlueJob.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const GlueCrawler = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'GlueCrawler.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const GlueCrawlerTarget = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'GlueCrawlerTarget.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const GlueCatalogDatabase = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'GlueCatalogDatabase.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { AthenaDB, GlueJob, GlueCrawler, GlueCrawlerTarget, GlueCatalogDatabase };

