import { Handle, Position, NodeProps } from "reactflow";
import { Constraints, GlobalListVisible } from './NodesData';
import './index.css';
import { useState } from 'react';
import { Stage } from "./Config.js"

export default ({ data, URL, Border = "", EnableBorder = false, Selected, LabelName = "", Label = "", Color = "Gray", ToolTip = "" }) => {
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    if (EnableBorder === null) { EnableBorder = false; }
    if (Border === null) { Border = ""; }
    const [isMouseNear, setIsMouseNear] = useState(false);
    let ID = data?.ID || "";
    if (true) {//(GlobalListVisible[ID]) {
        try {
            Label = data.Label + ID;
        } catch (error) {
            //console.log("Label", Label)
        }
        let ShowLabel, ShowLabelName;
        if (Selected) {
            ShowLabel = true;
            ShowLabelName = true;
        } else {
            try {
                ShowLabel = data.ShowLabel;
            } catch (error) {
                ShowLabel = false;
            }
            try {
                ShowLabelName = data.ShowLabelName;
            } catch (error) {
                ShowLabelName = false;
            }
        }
        let Opacity;
        try {
            Opacity = data.Opacity;
        } catch (error) {
            Opacity = false;
        }
        let Disable;
        let FixedToolTip = false;
        try {
            Disable = data.Disable;
            if (Disable) {
                ToolTip = "Disabled";
                Opacity = .45;
                FixedToolTip = true;
            }
        } catch (error) {
            Disable = false;
        }
        let Cost;
        let ShowCost;
        try {
            ShowCost = data.ShowCost;
            Cost = data.Cost;
        } catch (error) {
            ShowCost = false;
        }

        return (
            <div
                onMouseEnter={() => setIsMouseNear(true)}
                onMouseLeave={() => setIsMouseNear(false)}
                style={{ position: 'relative' }} // Adicionando posição relativa para o pai
            >
                <div
                    className={`node-container ${isMouseNear ? 'enlarged' : ''}`}
                    style={{
                        boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
                        borderRadius: "7%",
                        border: EnableBorder ? Border : 'none',
                        opacity: Opacity
                    }}
                >
                    <div
                        className="circleNode"
                        style={{
                            backgroundImage: URL,
                            filter: Selected ? "saturate(500%)" : "none",
                            borderRadius: (ShowLabel || ShowLabelName) ? "3px 3px 0 0" : "3px",
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            color: 'yellow',
                            fontSize: '8px'
                        }}
                    >
                        {(ShowLabel || ShowLabelName) && (
                            <div style={{
                                padding: '10px',
                                background: ShowCost ? 'rgba(0, 0, 0, 0.3)' : 'none',
                                textAlign: 'center',
                                width: '100%',
                                height: '50%',
                                borderRadius: 'inherit'
                            }}>
                                {ShowLabelName && <div>{ShowLabelName}</div>}
                                {ShowCost && <div>$<br></br><br></br>{Cost}</div>}
                            </div>
                        )}
                        {ToolTip && (
                            <div
                                className="tooltip"
                                style={{
                                    visibility: isMouseNear || FixedToolTip ? 'visible' : 'hidden',
                                    backgroundColor: 'black',
                                    color: '#fff',
                                    textAlign: 'center',
                                    borderRadius: '6px',
                                    padding: '2px',
                                    position: 'absolute',
                                    zIndex: 0,
                                    bottom: '55%', // Posição do tooltip
                                    left: '10%',
                                    marginLeft: '-6px',
                                    width: '40px',
                                    opacity: 0.65,
                                    transition: 'opacity 0.3s',
                                    fontSize: '9px' // Ajuste do tamanho da fonte
                                }}
                            >
                                {ToolTip}
                            </div>
                        )}
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="right"
                            position={Position.Right}
                            type="source"
                            isConnectable={true}
                        />
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="left"
                            position={Position.Left}
                            type="source"
                            isConnectable={true}
                        />
                        <Handle
                            className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                            id="top"
                            position={Position.Top}
                            type="source"
                            isConnectable={true}
                        />
                    </div>
                    {ShowLabel && (
                        <div
                            className="BarNameFontSmaller"
                            style={{
                                width: "40px",
                                background: Selected ? "#C0C0E0" : "#FFFFFF",
                                height: "auto",
                                borderRadius: ShowLabelName ? "0" : "0 0 3px 3px"
                            }}
                        >
                            {Label}
                        </div>
                    )}
                    {ShowLabelName && (
                        <div
                            className="BarNameFontSmaller"
                            style={{
                                width: "40px",
                                background: Selected ? "#C0C0E0" : "#FDFDFD",
                                height: "auto",
                                borderRadius: "0 0 3px 3px"
                            }}
                        >
                            {LabelName}
                        </div>
                    )}
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="bottom"
                        position={Position.Bottom}
                        type="source"
                        isConnectable={true}
                    />
                </div>
            </div>
        );
    }
};
