import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const SESReceiptRuleSet = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SESReceiptRuleSet.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const SESReceiptRule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SESReceiptRule.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const SESDomainIdentity = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SESDomainIdentity.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const SESEmailIdentity = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SESEmailIdentity.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const SESConfigurationSet = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SESConfigurationSet.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const SESEventDest = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SESEventDest.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};



export { SESReceiptRuleSet, SESReceiptRule, SESDomainIdentity, SESEmailIdentity, SESConfigurationSet, SESEventDest };

