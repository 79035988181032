import React, { useState, useEffect, useRef } from 'react';
import { useModal } from './ModalMain';
import { APIPricing } from "./Config.js"
import { GEdges, GNodes, GlobalToken } from './NodesData';
import { APIExecTerraform } from "./Config.js";
import {
    FindRegionAndCloud, DiscoveryTerraformNetwork, FindBackendStorage, CallAPI, Save, CheckStateStatus, AdjustSufixName,
    SearchNodesTarget, SearchNodesSource, FindStageBoxAbove,
} from './Functions';

export const ShowCode = (CodeObj) => {
    let TFID = CodeObj.TFID;
    //console.log("Restart func")
    const { activeModal, closeModal } = useModal();
    const [fontSize, setFontSize] = useState('medium');
    const [Logs, setLogs] = useState(() => {
        return GNodes[TFID]?.data?.TerraformExecStatus || '';
    });
    GNodes[TFID].data.LastDeployWith = GNodes[TFID]?.data?.LastDeployWith || '';
    let LastDeployWith;
    if (GNodes[TFID].data.LastDeployWith === "") {
        LastDeployWith = "None";
    } else {
        LastDeployWith = GNodes[TFID].data.LastDeployWith;
    }
    const StateName = AdjustSufixName(GEdges, GNodes, TFID, true).data.Param[1][1];
    const [CloudID, RegionID, RG] = FindRegionAndCloud(GNodes, TFID);
    const Region = GNodes[parseInt(RegionID)].data.Param[2][2];
    let RoleARN = GNodes[parseInt(CloudID)].data.Param[5][1];
    console.log("GNodes[TFID].data.LastDeployWith", GNodes[TFID].data.LastDeployWith);
    const eventCountRef = useRef(GNodes[TFID]?.data?.eventCount || 0); // UseRef para armazenar o contador de eventos
    //const logsRef = useRef(null);
    //const intervalId = useRef(GNodes[TFID]?.data?.intervalId || null); // Inicializa com o valor armazenado em GNodes
    const [ListStates, BackendID, Loop] = DiscoveryTerraformNetwork(GEdges, GNodes, parseInt(TFID));
    const [StorageID, DBID, RepoID, BuildID, BackendErrorMSG] = FindBackendStorage(GEdges, GNodes, BackendID, false);
    const StageBoxID = FindStageBoxAbove(GEdges, GNodes, TFID);
    let BucketName;
    const HasBuild = BuildID !== 0 || BackendID === 0;
    let RemoteBackend = "CloudMan";
    let RemoteBackendName = "CloudMan";
    //GNodes[TFID].data.LastDeployWith = ""// usado para inicializar o valor do name do be
    console.log("BackendID", BackendID)
    if (BackendID !== 0) {
        BucketName = AdjustSufixName(GEdges, GNodes, StorageID, true).data.Param[1][1];
        RemoteBackend = "User";
        RemoteBackendName = AdjustSufixName(GEdges, GNodes, BackendID, true).data.Param[1][1];
    }
    console.log("DBID", DBID)
    /*const HasCommit = HasBuild && RemoteBackend == "User";
    const HasBuildCloudMan = HasBuild && RemoteBackend == "CloudMan";
    const intervalo = 10000; // Intervalo definido como 10 segundos
    // Primeiro useEffect para lidar com a rolagem inicial ou quando estiver no final
    const [firstScroll, setFirstScroll] = useState(true);

    useEffect(() => {
        if (activeModal !== 'modalShowCode' || !logsRef.current) return;
        setTimeout(() => {
            const { scrollTop, scrollHeight, clientHeight } = logsRef.current;
            const margin = clientHeight; // Usando a altura do contêiner como margem
            const isAtBottom = (scrollHeight - scrollTop - clientHeight <= margin);
            console.log("isAtBottom", isAtBottom);
            console.log("firstScroll", firstScroll);
            // Verifica se é a primeira vez que a scrollHeight excede a clientHeight
            if (scrollHeight > clientHeight && firstScroll) {
                logsRef.current.scrollTop = scrollHeight;
                setFirstScroll(false); // Atualiza o estado para indicar que a primeira rolagem já ocorreu
            } else if (isAtBottom) {
                logsRef.current.scrollTop = scrollHeight;
            }
        }, 20); // Delay de 20ms
    }, [Logs, firstScroll]);*/
    const Code = CodeObj.Code;
    let FlagOK = true;
    const fontSizes = {
        small: '12px',
        medium: '16px',
        large: '20px'
    };

    useEffect(() => {
        if (activeModal !== 'modalShowCode') return;
    }, [activeModal]);

    const indentCode = (codeString) => {
        const lines = codeString.split('\n');
        let indentCount = 0;
        const INDENT_SIZE = 3;
        let result = [];
        lines.forEach(line => {
            if (line.trim().startsWith('}')) {
                indentCount--;
                if (indentCount < 0) { indentCount = 0; }
            }
            if ((line.trim().startsWith('resource')) || (line.trim().startsWith('data')) || (line.trim().startsWith('output'))) {
                indentCount = 0;
            }
            const currentIndentation = ' '.repeat(indentCount * INDENT_SIZE);
            let indentedLine = currentIndentation + line;
            indentedLine = indentedLine.replace(/([^ $]){/g, '$1 {');
            result.push(indentedLine);
            if (line.trim().endsWith('{')) {
                indentCount++;
            }
            if (line.startsWith('}') && indentCount === 0) {
                result.push('');
            }
        });
        return result.join('\n');
    };

    const normalizeEqualsSign = (str) => {
        if (!str) return '';
        // Regular expression to match double-quoted strings and EOF blocks
        const pattern = /("(?:\\.|[^"\\])*"|<<-EOF[\s\S]*?EOF)|(?<![\|\!\=\<])=(?![>=])/g;
        let result = str.replace(pattern, (match, p1) => {
            // If p1 is defined, it's a double-quoted string or EOF block, return it unchanged
            if (p1) {
                return p1;
            }
            // Otherwise, replace '=' with ' = '
            return ' = ';
        });
        // Remove '|' characters only if they are not inside double-quoted strings or EOF blocks
        result = result.replace(/("(?:\\.|[^"\\])*"|<<-EOF[\s\S]*?EOF)|\|/g, (match, p1) => {
            if (p1) {
                return p1; // Return the double-quoted string or EOF block unchanged
            } else {
                return ''; // Remove '|' outside of double-quoted strings and EOF blocks
            }
        });
        return result;
    };

    const formattedCode = indentCode(normalizeEqualsSign(Code));
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(formattedCode)
            .then(() => {
                console.log("Texto copiado com sucesso!");
            })
            .catch(err => {
                console.error("Erro ao copiar o texto: ", err);
            });
    };

    const Command = async (CommandTerraform) => {
        const Code = CodeObj.Code;
        if (Code.length < 40) {
            alert("The Terraform code must be recompiled.")
            return
        }
        if (RemoteBackend == "User") {
            handleCommit();
            console.log("User Backend", BucketName, StateName)
            const ProjectName = AdjustSufixName(GEdges, GNodes, BuildID, true).data.Param[1][1];
            console.log("ProjectName", ProjectName)
            const [CloudID, RegionID, RG] = FindRegionAndCloud(GNodes, BuildID);
            const RegionName = GNodes[parseInt(RegionID)].data.Param[2][2];
            const Account = GNodes[parseInt(CloudID)].data.Param[3][1];
            const NewStateName = AdjustSufixName(GEdges, GNodes, TFID, true).data.Param[1][1];
            const raw = [4, CommandTerraform, RoleARN, NewStateName, "user_id", ProjectName, RegionName]
            console.log("NewStateName,ProjectName, RegionName", NewStateName, ProjectName, RegionName)
            try {
                const Resp = await CallAPI(APIPricing, raw, true)
                console.log("Resp", Resp)
                const bodyJson = JSON.parse(Resp.body);
                const CodeBuildID = bodyJson.build_id;
                const URL = `https://${RegionName}.console.aws.amazon.com/codesuite/codebuild/${Account}/projects/${ProjectName}/build/${CodeBuildID}/log?${RegionName}`
                window.open(URL, TFID);
                GNodes[TFID].data.LastBuildURL = Resp;
            } catch (error) {
                //pass
                console.log("Erro")
            }

        } else { //Backend CloudMan
            const UserID = sessionStorage.getItem("CognitoSub").slice(0, -1);
            console.log("UserID", UserID)
            const Type = 1;
            console.log("CommandTerraform", CommandTerraform)
            var raw = JSON.stringify([Type, CommandTerraform, UserID, StateName, Code, RoleARN]);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
            var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
            fetch(APIExecTerraform, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const URL = JSON.parse(result).body;
                    console.log("URL", URL);
                    GNodes[TFID].data.LastBuildURL = URL;
                    window.open(URL, TFID);
                })
                .catch(error => {
                    console.log('error', error);
                });
        }
    };

    const handlePlan = async () => {
        let CommandTerraform = "plan";
        if (BackendID === 0 && StageBoxID !== 0) {
            console.log("BackendID plan", BackendID)
            alert("The Terraform node must have a backend user at the source.")
            return
        }
        console.log("Remote Backend ", RemoteBackend, GNodes[TFID].data.LastDeployWith)
        if (GNodes[TFID].data.LastDeployWith === "" || GNodes[TFID].data.LastDeployWith === RemoteBackendName) {
            let Confirm = window.confirm(`Confirm Apply using ${RemoteBackendName} ?`);
            if (Confirm) {
                const NewStateName = AdjustSufixName(GEdges, GNodes, TFID, true).data.Param[1][1];
                const raw = [3, Code, RoleARN, BucketName, NewStateName]
                const Resp = await CallAPI(APIPricing, raw)
                await Command(CommandTerraform);
            }
        } else {
            console.log("Troca de backend")
        }
    };


    const handleApply = async () => {
        if (Code.length < 40) {
            alert("The Terraform code must be recompiled.")
            return
        }
        if (BackendID === 0 && StageBoxID !== 0) {
            console.log("BackendID apply", BackendID)
            alert("The Terraform node must have a backend user at the source.")
            return
        }
        let CommandTerraform = "apply";
        let ListDependent = SearchNodesSource(GEdges, GNodes, GNodes[TFID], "TerraformN");
        ListDependent = ListDependent.concat(SearchNodesSource(GEdges, GNodes, GNodes[TFID], "TerraformBackendS3N"));
        const Continue = await CheckStateStatus(GEdges, GNodes, ListDependent, BucketName, TFID, RemoteBackend, RemoteBackendName, CommandTerraform)
        console.log("Continue", Continue, GNodes[TFID].data.LastDeployWith)
        if (Continue) {
            if (GNodes[TFID].data.LastDeployWith === "" || GNodes[TFID].data.LastDeployWith === RemoteBackendName) {
                let Confirm = window.confirm(`Confirm Apply using ${RemoteBackendName} ?`);
                if (Confirm) {
                    await Command(CommandTerraform);
                    GNodes[TFID].data.LastDeployWith = RemoteBackendName;
                    Save(GEdges, GNodes);
                }
            }
        } else {
            console.log("Troca de backend")
        }
    };

    const handleDestroy = async () => {
        if (BackendID === 0 && StageBoxID !== 0) {
            console.log("BackendID Destroy", BackendID)
            alert("The Terraform node must have a backend user at the source.")
            return
        }
        console.log("RemoteBackendName", RemoteBackendName)
        let CommandTerraform = "destroy";
        if (GNodes[TFID].data.LastDeployWith === "" || GNodes[TFID].data.LastDeployWith === RemoteBackendName) {
            //const ListDependent = FindAllNetworkNodeIDs(GEdges, GNodes, TFID);
            let ListDependent = [TFID]
            ListDependent = ListDependent.concat(SearchNodesTarget(GEdges, GNodes, GNodes[TFID], "TerraformN"));
            ListDependent = ListDependent.concat(SearchNodesTarget(GEdges, GNodes, GNodes[TFID], "TerraformBackendS3N"));
            const Continue = await CheckStateStatus(GEdges, GNodes, ListDependent, BucketName, TFID, RemoteBackend, RemoteBackendName, CommandTerraform)

            if (Continue) {
                let Confirm = window.confirm(`Confirm Destroy using ${RemoteBackendName} ?`);
                if (Confirm) {
                    await Command(CommandTerraform);
                    GNodes[TFID].data.LastDeployWith = "";
                    Save(GEdges, GNodes);
                }
            }
        } else {
            alert("Troca de backend")
        }
    };

    const handleBuildLogs = async () => {
        const URL = GNodes[TFID].data.LastBuildURL;
        console.log("URL", URL)
        if (URL !== undefined) {
            window.open(URL, TFID);
        }
    }

    const handleCommit = async () => {
        const Code = GNodes[TFID].data.CodeGenerated;
        let UserID = ""
        const [BECloudID, BERegionID, RG] = FindRegionAndCloud(GNodes, BackendID);
        const BERegion = GNodes[parseInt(BERegionID)].data.Param[2][2];
        const NewStateName = AdjustSufixName(GEdges, GNodes, TFID, true).data.Param[1][1];
        //console.log("NewStateName", NewStateName)
        //const NewCode = modifyTerraformCode(Code, BucketName, UserID, NewStateName, BERegion)
        //console.log("BucketName", BucketName, StateName, NewCode)
        const raw = [3, Code, RoleARN, BucketName, NewStateName]
        const Resp = await CallAPI(APIPricing, raw)
        Save(GEdges, GNodes);
    };

    const processLine = (line) => {
        const regex = /(".*?"|\$\{.*?\}|[{ }[\]=()]|[^\s]+|[\s]+)/g;
        const segments = line.match(regex) || [];
        return segments;
    };

    const LightBlue = '#88FFFF';
    const LightGreen = '#32FF32';
    if (!FlagOK) return null;

    return (
        <div
            onDragStart={(e) => e.preventDefault()}
            style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', background: '#f4f4f4', padding: '6px' }}>
                <span>Current Backend: {RemoteBackendName} <br></br> Last Deployment Backend: {LastDeployWith}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', background: '#f4f4f4', padding: '6px' }}>

                <select
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                >
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                </select>
                <button onClick={handleCopyToClipboard} style={{ marginLeft: '1em' }}>Copy</button>
                {HasBuild && (
                    <>
                        <button onClick={handlePlan} style={{ marginLeft: '1em' }}>Plan</button>
                        <button onClick={handleApply} style={{ marginLeft: '1em' }}>Apply</button>
                        <button onClick={handleDestroy} style={{ marginLeft: '1em' }}>Destroy</button>
                        <button onClick={handleBuildLogs} style={{ marginLeft: '1em' }}>Logs</button>
                    </>
                )}
            </div>
            {

                <pre style={{
                    whiteSpace: 'pre',
                    color: LightBlue,
                    fontSize: fontSizes[fontSize],
                    flex: 1,
                    overflowY: 'auto'
                }}>
                    {formattedCode.split('\n').map((line, index) => (
                        <div key={index} style={{ color: LightBlue, marginTop: '2px', marginBottom: '2px' }}>
                            <span style={{ color: 'gray', marginRight: '1em' }}>{index + 1}</span>
                            {processLine(line).map((segment, i) => {
                                if (['{', '}', '[', ']', '(', ')'].includes(segment)) {
                                    return <span key={i} style={{ color: 'yellow' }}>{segment}</span>;
                                } else if (segment === '=') {
                                    return <span key={i} style={{ color: 'white' }}>{segment}</span>;
                                } else if (segment.startsWith('"') && segment.endsWith('"')) {
                                    return segment.split(/(\$\{.*?\})/).map((subSegment, j) => {
                                        if (subSegment.startsWith('${') && subSegment.endsWith('}')) {
                                            return <span key={`${i}-${j}`} style={{ color: LightBlue }}>{subSegment}</span>;
                                        } else {
                                            return <span key={`${i}-${j}`} style={{ color: 'orange' }}>{subSegment}</span>;
                                        }
                                    });
                                } else if ((segment.trim() === 'provider' || segment.trim() === 'terraform' || segment.trim() === 'resource'
                                    || segment.trim() === 'data' || segment.trim() === 'required_providers' || segment.trim() === 'output')
                                    && /^[ ]*$/.test(line.substring(0, line.indexOf(segment))) && !line.substring(line.indexOf(segment) + segment.length).trim().startsWith('=')) {
                                    return <span key={i} style={{ color: LightGreen }}>{segment}</span>;
                                } else if (segment.trim() === 'false' || segment.trim() === 'true') {
                                    return <span key={i} style={{ color: "#D8BFD8" }}>{segment}</span>;
                                } else {
                                    return segment;
                                }
                            })}
                        </div>
                    ))}
                </pre>

            }
        </div >
    );
}
