import { useState, useCallback, useEffect, useMemo, memo } from 'react';
import './index.css';
import { Save, ProcessLoad } from './Functions';
import { APIDB, Stage, CloudManVersion } from "./Config.js";
import { Constraints, GlobalToken, Code, GlobalCognitoSub, GlobalUserName } from './NodesData';
import Template from './Template';

const ListCloudProvider = ["Standard", "AWS", "Azure", "Kubernets"];
var Hidden = false;
var ShowCloudProvider = [];
for (let i = 0; i < ListCloudProvider.length; i++) {
    ShowCloudProvider[i] = false;
}

const ListCategory = [["Standard"], ["Network", "Computing", "Containers", "Storage", "Database", "Application & Integration", "Security, Identity & Compliance",
    "Management & Governance", "Developer Tools", "Machine Learning", "Cloud Financial Management", "Analytics"], ["Network", "Computing", "Storage"], ["Node"]];
var ShowCategory = [];
for (let i = 0; i < ListCloudProvider.length; i++) {
    ShowCategory[i] = [];
    for (let j = 0; j < ListCategory[i].length; j++) {
        ShowCategory[i][j] = false;
    }
}

const SubMenuCtrlOpen = { transform: "rotate(270deg)", cursor: "pointer" };
const SubMenuCtrlClose = { cursor: "pointer" };
var FileNameInit = sessionStorage.getItem("FileName");

const Sidebar = memo(({ setNodes, setEdges, nodes, edges, sharedNodeTypeSearch, GlobalIsSaved, Saved, CognitoRegion, CognitoDomain, CognitoClient }) => {
    const ajustarString = useCallback((str, tamanho) => {
        try {
            if (str.length > tamanho) {
                return str.substring(0, tamanho);
            } else if (str.length < tamanho) {
                return str.padEnd(tamanho, ' ');
            }
        } catch (error) {
            return "";
        }
        return str;
    }, []);

    const IsDev = Stage === "DevLocal";
    const UserName = ajustarString(GlobalUserName, 18);
    const URLIcons = Constraints.IconsURL;
    const CategoryByProvider = [Constraints.Standard, Constraints.AWS, Constraints.Azure, Constraints.Kubernets];
    const SubMenuCtrl = URLIcons + "MenuOpenTransp.png";

    // Definição das listas de nodes e paths com useMemo para evitar recriações desnecessárias
    const { imagePaths, NodesType, NodesName, NodesTypeNameList } = useMemo(() => {
        let imagePaths = [];
        let NodesType = [];
        let NodesName = [];
        let NodesTypeNameList = [];
        const ExtensionType = [".png", ".png", ".svg", ".png"];

        for (let IndexCloud = 0; IndexCloud < ListCloudProvider.length; IndexCloud++) {
            imagePaths[IndexCloud] = [];
            NodesType[IndexCloud] = [];
            NodesName[IndexCloud] = [];
            for (let i = 0; i < ListCategory[IndexCloud].length; i++) {
                let category = ListCategory[IndexCloud][i];
                imagePaths[IndexCloud][category] = [];
                NodesType[IndexCloud][category] = [];
                NodesName[IndexCloud][category] = [];
                let SelectNodes = CategoryByProvider[IndexCloud][category];
                //console.log("SelectNodes", SelectNodes)
                for (let j = 0; j < SelectNodes.length; j++) {
                    let Files = SelectNodes[j][0].slice(0, -1) + ExtensionType[IndexCloud];
                    if (Files === "AZCloud.svg") { Files = "AZCloud.png"; }
                    if (Files === "AZResourceGroup.svg") { Files = "AZResourceGroup.png"; }
                    if (Files === "AZRegion.svg") { Files = "AZRegion.png"; }
                    let PushIcon = URLIcons + Files;
                    let PushName = SelectNodes[j][1];
                    let PushType = SelectNodes[j][0].slice(0, -1);
                    imagePaths[IndexCloud][category].push(PushIcon);
                    NodesName[IndexCloud][category].push(PushName);
                    NodesType[IndexCloud][category].push(PushType);
                    NodesTypeNameList.push([PushType, PushName, PushIcon]);
                }
            }
        }
        return { imagePaths, NodesType, NodesName, NodesTypeNameList };
    }, [URLIcons]);

    const [searchText, setSearchText] = useState('');
    const [SearchListReact, setSearchListReact] = useState([]);

    const handleSearch = useCallback((inputValue) => {
        if (inputValue) {
            // Primeiro, busca por nomes que começam com o texto digitado
            let startWithList = NodesTypeNameList.filter(([type, name]) => name.toUpperCase().startsWith(inputValue));

            // Depois, busca por nomes que contêm o texto digitado, mas exclui os que já começam com o texto
            let includesList = NodesTypeNameList.filter(([type, name]) =>
                !name.toUpperCase().startsWith(inputValue) && name.toUpperCase().includes(inputValue)
            );

            // Combina as duas listas, começando pelos que começam com o texto, seguido pelos que contêm
            let combinedList = [...startWithList, ...includesList];

            // Limita a lista a 12 itens
            setSearchListReact(combinedList.slice(0, 12));
        } else {
            setSearchListReact([]);
        }
    }, [NodesTypeNameList]);

    // Função de KeyUp agora usa handleSearch
    const handleKeyUp = useCallback((event) => {
        const inputValue = event.target.value.toUpperCase();
        setSearchText(inputValue);
        handleSearch(inputValue);
    }, [handleSearch]);

    // useEffect que monitora sharedNodeTypeSearch e aciona handleSearch
    useEffect(() => {
        if (sharedNodeTypeSearch && sharedNodeTypeSearch !== searchText) {
            const inputValue = sharedNodeTypeSearch.toUpperCase(); // Adiciona verificação para evitar erro
            setSearchText(inputValue);
            handleSearch(inputValue); // Chama a lógica de busca para filtrar os nodes
        }
    }, [sharedNodeTypeSearch, searchText, handleSearch]);

    const onDragStart = useCallback((event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    }, []);

    const [showCaption, setShowCaption] = useState(false);
    const [timerB, setTimerB] = useState(null);
    const [text, setText] = useState("");
    const [hoverImageId, setHoverImageId] = useState(null);

    const handleMouseEnter = useCallback((nodeType, nodeName) => {
        clearTimeout(timerB);
        setHoverImageId(nodeType);
        setTimerB(
            setTimeout(() => {
                setShowCaption(true);
                setText(nodeName);
            }, 500)
        );
    }, [timerB]);

    const handleMouseLeave = useCallback(() => {
        clearTimeout(timerB);
        setShowCaption(false);
        setHoverImageId("");
    }, [timerB]);

    const ResetHandler = useCallback(() => {
        sessionStorage.setItem("ReloadNodes", []);
        sessionStorage.setItem("ReloadEdges", []);
        sessionStorage.setItem("FileName", "");
        document.location.reload(true);
    }, []);

    const LoadHandler = useCallback(() => {
        let FileName = document.getElementById("Login").value;
        sessionStorage.setItem("FileName", FileName);
        var raw = JSON.stringify([5, FileName]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };

        fetch(APIDB, requestOptions)
            .then(response => response.text())
            .then(result => {
                let Resp = JSON.parse(result).body;
                if (Resp === false) {
                    alert("User not found!");
                } else {
                    var raw = JSON.stringify([3, "Nodes", FileName]);
                    var myHeaders = new Headers();
                    var NewNodes = [];
                    var NewEdges = [];
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
                    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
                    fetch(APIDB, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            let Array = ProcessLoad(result);
                            NewEdges = Array[0];
                            NewNodes = Array[1];
                            sessionStorage.setItem("ReloadNodes", JSON.stringify(NewNodes));
                            sessionStorage.setItem("ReloadEdges", JSON.stringify(NewEdges));
                            document.location.reload(true);
                        })
                        .catch(error => {
                            console.log('error', error);
                        });
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }, []);

    const SaveHandler = useCallback(() => {
        try {
            Save(edges, nodes);
            Saved(true);
        } catch (error) {
            //pass
        }
    }, [edges, nodes, Saved]);

    const LogoutHandler = useCallback(() => {
        Save(edges, nodes, Stage, GlobalCognitoSub, GlobalToken);
        Saved(true);
        sessionStorage.setItem("Code" + Code, null);
        var logoutUrl = `https://${CognitoDomain}.auth.${CognitoRegion}.amazoncognito.com/logout?client_id=${CognitoClient}&logout_uri=`
            + encodeURIComponent("https://cloudman.pro") + `&redirect_uri=`
            + encodeURIComponent("https://cloudman.pro/CloudMan.html");
        window.location.href = logoutUrl;
    }, [edges, nodes, CognitoDomain, CognitoRegion, CognitoClient, Saved]);

    const [Show, setShow] = useState(ShowCategory);
    const OpenCloseCategory = useCallback((e, Index, IndexCloud) => {
        setShow((prevShow) =>
            prevShow.map((ItemCloud, IdCloud) =>
                ItemCloud.map((Item, Idx) => {
                    if (Idx === Index && IdCloud === IndexCloud) {
                        return !Item; // Inverta o valor
                    }
                    return Item; // Retorne o valor inalterado
                })
            )
        );
    }, []);

    const [ShowCloud, setShowCloud] = useState(ShowCloudProvider);
    const OpenCloseCloud = useCallback((e, Index) => {
        setShowCloud((nds) =>
            nds.map((Item, Idx) => {
                if (Idx === Index) {
                    return !Item;
                }
                return Item;
            })
        );
    }, []);

    const [isExpanded, setIsExpanded] = useState(true);
    const [Direction, setDirection] = useState("<<<");

    const handleDoubleClick = useCallback(() => {
        setIsExpanded(!isExpanded);
        setDirection(isExpanded ? ">>>" : "<<<");
    }, [isExpanded]);

    const sidebarStyle = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: isExpanded ? 0 : "-175px",
        width: "208px",
        height: "100%",
        background: "softblue",
        borderRight: "1px solid black",
        boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
        overflow: "auto",
        direction: 'rtl',
        userSelect: "none",
        zIndex: 100,
        transition: 'left 0.2s ease-in-out',
    };

    const imageStyle = {
        width: '40px',
        height: '40px',
        margin: '2px',
        border: '1px solid black',
        display: 'inline-block',
        position: 'relative',
        transition: 'transform 0.33s ease',
        borderRadius: "7%",
        cursor: 'pointer',
        zIndex: 29,
    };

    const ToolTipStyle = {
        position: 'absolute',
        left: '0',
        backgroundColor: 'yellow',
        padding: '5px',
        borderRadius: '5%',
        zIndex: 31,
        overflow: 'hidden',
        fontSize: '10px',
    };

    const hoverStyle = {
        transform: 'scale(1.45)',
        zIndex: 30,
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const TemplateHandler = useCallback(() => {
        setIsModalOpen(!isModalOpen);
        console.log("Template");
    }, [isModalOpen]);

    return (
        <>
            <Template isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
            <aside style={sidebarStyle} >
                <div style={{ direction: 'ltr' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <h2 style={{ margin: 0, marginLeft: '4px', flexShrink: 0 }}>
                                {UserName}
                            </h2>
                            <div style={{ position: 'relative', right: '-10px' }}>
                                <div onClick={handleDoubleClick} style={{
                                    backgroundColor: '#4750Fd',
                                    padding: '3px 5px',
                                    textAlign: 'center',
                                    color: 'white',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    flexShrink: 0
                                }}>
                                    {Direction}
                                </div>
                            </div>
                        </div>
                        <div className="inputContainer">
                            {IsDev && <input spellCheck="false" type="text" id="Login" defaultValue={FileNameInit} />}
                        </div>
                    </div>
                    <div className="buttonContainer">
                        <div className="HideEdge">
                            <button onClick={LogoutHandler}>Logout</button>
                        </div>
                        <div className="HideEdge">
                            <button
                                onClick={SaveHandler}
                                style={{
                                    backgroundColor: GlobalIsSaved ? '#3a6aff' : 'orange',
                                }}
                            >
                                &nbsp;Save
                            </button>
                        </div>
                    </div>
                    {IsDev && <div className="buttonContainer">
                        <div className="HideEdge">
                            <button onClick={LoadHandler}>&nbsp;Load&nbsp;</button>
                        </div>
                        <div className="HideEdge">
                            <button onClick={ResetHandler}>Clear</button>
                        </div>
                    </div>}
                    <div className="buttonContainer">
                        <div className="Variable">
                            <button onClick={TemplateHandler} id="Template" onDragStart={(event) =>
                                onDragStart(
                                    event,
                                    document.getElementById("Template").id + 'N'
                                )
                            }
                                draggable>&nbsp;Template&nbsp;</button>
                        </div>
                    </div>
                    <div className="inputContainer">
                        <h2 style={{ margin: 0 }}> Search:</h2>
                        <input spellCheck="false" autoComplete="off" type="input" id="Search" value={searchText}
                            onChange={event => setSearchText(event.target.value)} onKeyUp={handleKeyUp} />
                    </div>
                    <div style={{
                        display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '70%', height: '70%',
                        gridTemplateRows: `repeat(${Math.ceil(SearchListReact.length / 4)}, 1fr)`, gridGap: '3px',
                    }}>
                        {SearchListReact.map((Item, index) => (
                            <div key={index} style={{
                                position: "relative", boxShadow: '1px 0px 2px 0px rgba(0,0,0,0.60)', borderRadius: "7%"
                            }}>
                                <div
                                    onMouseEnter={() => handleMouseEnter(Item[0], Item[1])}
                                    onMouseLeave={handleMouseLeave}
                                    style={{ position: "relative" }}
                                >
                                    <img src={Item[2]} alt="" style={imageStyle} id={Item[0]}
                                        onDragStart={(event) => onDragStart(event, document.getElementById(Item[0]).id + "N")}
                                        draggable />
                                    {hoverImageId === Item[0] && showCaption &&
                                        <div style={ToolTipStyle}>
                                            {text}
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {ListCloudProvider.map((CloudProvider, IndexCloud) => (
                        <div key={IndexCloud}>
                            {IndexCloud !== 0 && (
                                <h1>
                                    {CloudProvider + " "}
                                    {!ShowCloud[IndexCloud] && <img src={SubMenuCtrl} alt="im" width="15" height="15" style={SubMenuCtrlOpen} onClick={(e) => OpenCloseCloud(e, IndexCloud)} />}
                                    {ShowCloud[IndexCloud] && <img src={SubMenuCtrl} alt="" width="15" height="15" style={SubMenuCtrlClose} onClick={(e) => OpenCloseCloud(e, IndexCloud)} />}
                                </h1>
                            )}
                            {ListCategory[IndexCloud].map((Category, Index) => (
                                <div key={Index}>
                                    {(ShowCloud[IndexCloud] || IndexCloud === 0) && (
                                        <>
                                            <h2>
                                                {Category + " "}
                                                {!Show[IndexCloud][Index] && <img src={SubMenuCtrl} alt="im" width="15" height="15" style={SubMenuCtrlOpen} onClick={(e) => OpenCloseCategory(e, Index, IndexCloud)} />}
                                                {Show[IndexCloud][Index] && <img src={SubMenuCtrl} alt="" width="15" height="15" style={SubMenuCtrlClose} onClick={(e) => OpenCloseCategory(e, Index, IndexCloud)} />}
                                            </h2>
                                            {Show[IndexCloud][Index] && (
                                                <div style={{
                                                    display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '70%', height: '70%',
                                                    gridTemplateRows: `repeat(${Math.ceil(imagePaths[IndexCloud][Category].length / 4)}, 1fr)`, gridGap: '3px',
                                                }}>
                                                    {imagePaths[IndexCloud][Category].map((image, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                position: 'relative',
                                                                boxShadow: '1px 0px 2px 0px rgba(0,0,0,0.60)',
                                                                borderRadius: '7%',
                                                            }}
                                                            onMouseEnter={() =>
                                                                handleMouseEnter(NodesType[IndexCloud][Category][index], NodesName[IndexCloud][Category][index])
                                                            }
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <img
                                                                src={image}
                                                                alt=""
                                                                style={hoverImageId === NodesType[IndexCloud][Category][index] ? { ...imageStyle, ...hoverStyle } : imageStyle}
                                                                id={NodesType[IndexCloud][Category][index]}
                                                                onDragStart={(event) =>
                                                                    onDragStart(
                                                                        event,
                                                                        document.getElementById(NodesType[IndexCloud][Category][index]).id + 'N'
                                                                    )
                                                                }
                                                                draggable
                                                            />
                                                            {hoverImageId === NodesType[IndexCloud][Category][index] && showCaption && (
                                                                <div style={ToolTipStyle}>
                                                                    {text}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}

                    {Stage} version {CloudManVersion}

                </div>

                <br />
                <br />
                <br />
            </aside>
        </>
    );
});

export default Sidebar;
