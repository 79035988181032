import React, { useState, useContext, useEffect } from 'react';
import '././VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import { Constraints, ResourceLookUp, ListaStandard, TypeAZ, TypeVPC, GEdges, GNodes, Stage, TypeTerraform, TypeCloud, TypeRegion } from './NodesData';
import {
    CheckIdenticalName, FindBackendStorage, AdjustSufixName, updateTerraformDependencies, FindRegionAndCloud, ListTerraformDomain, TestDuplicateEdges,
    DiscoveryTerraformNetwork, Save, FindStageBoxAbove, CallAPI, AjustEdgesNodesToSavePartial, SearchNodesTarget, CheckEdgeAttribute,
    findEdgeLabel,
} from './Functions';
import { APIAWSTerraformCode, APIAzureTerraformCode } from "./Config.js";
import { Source } from 'three/src/Three.js';
const TypeCloudRegion = TypeCloud.concat(TypeRegion);
let ErrorDiagram = false;

const Compile = ({ TFID = 0, GlobalNodes, GlobalEdges, onIconClick }) => {
    let InitialWarning = [];
    let InitialError = [];
    let InitialNameError = [];
    //console.log("TFID", TFID)
    let IsTerraform = TypeTerraform.includes(GlobalNodes[TFID].type);
    try {
        if (typeof GlobalNodes[TFID].data.Compile !== 'object' || GlobalNodes[TFID].data.Compile === null) {
            GlobalNodes[TFID].data.Compile = {};  // Inicializa Compile como um objeto vazio
        }
        InitialWarning = GlobalNodes[TFID].data.Compile.WarningList || []
        InitialError = GlobalNodes[TFID].data.Compile.ErrorList || [];
        InitialNameError = GlobalNodes[TFID].data.Compile.NameError || [];
    } catch (error) {
        console.log("Erro em leitura das listas")
    }
    let WarningArray = InitialWarning;
    let ErrorArray = InitialError;
    let NameError = InitialNameError;
    let Success = InitialError.length === 0 && InitialWarning.length === 0 && InitialNameError.length === 0 && !ErrorDiagram;
    let Fail = InitialError.length !== 0 || InitialNameError.length !== 0 && !ErrorDiagram;
    let Pass = InitialWarning.length !== 0 && !Fail && !ErrorDiagram;
    const [loading, setLoading] = useState(false);
    //console.log("ErrorArray", ErrorArray, WarningArray);
    console.log("TFID", TFID)
    let [ListStates, BackendID, HasLoop] = DiscoveryTerraformNetwork(GlobalEdges, GlobalNodes, parseInt(TFID));
    //if (BackendID === 0) { BackendID = TFID }
    console.log("BackendID", BackendID)
    let [StorageID, DBID, RepoID, BuildID, BackendErrorMSG] = FindBackendStorage(GlobalEdges, GlobalNodes, BackendID);
    console.log("StorageID", StorageID)
    const StageBoxID = FindStageBoxAbove(GlobalEdges, GlobalNodes, TFID)
    const TFType = GlobalNodes[TFID].type;
    if (BackendID === 0 && StageBoxID !== 0 && TypeTerraform.includes(TFType)) {
        console.log("BackendID compile", BackendID, TFID)
        alert("The Terraform node must have a backend user at the source.")
        return
    }
    //console.log("StorageID", StorageID)
    if (BackendID === 0) { StorageID = 0; }
    const BackendError = [BackendID, BackendErrorMSG]
    if (HasLoop) {
        console.log("hasLoop")
        return (
            <div style={{ backgroundColor: 'white' }}>
                <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                    <h2>Found Terraform nodes network loop</h2>
                </div>
            </div>
        )
    }
    if (TFID != 0 && IsTerraform && !HasLoop) {
        const handleIconClick = (ID) => {
            //console.log('click icon', ID);
            onIconClick(ID);  // Aqui estamos chamando a função passada pelo componente Filho, que por sua vez foi passada pelo componente Avô.
        };
        let ListTF = [];
        let ListPositionTFSatete = 0;
        let CountListTF = 0;
        let ListTFState = [];
        let NewNodes = [];
        let NewNode = [];
        let NewNodeToOldNode = {};
        let OldNodeToNewNode = {};
        let NewEdges = [];
        let NewEdge = [];
        let ListNodesID = [];
        var TerraformNodeName = "";
        //try {
        for (let i = 1; i < GlobalNodes.length; i++) {
            if (TypeTerraform.includes(GlobalNodes[i].type)) {
                let ListTerraform = ListTerraformDomain(GlobalNodes, GlobalNodes[i]);
                //console.log("ListTerraformDomain", ListTerraform, i)
                for (let j = 1; j < ListTerraform.length; j++) {
                    let NodeID = parseInt(ListTerraform[j]);
                    GlobalNodes[NodeID].data.Terraform = i.toString();
                }
            }
        }
        //Monta a lista de Terraform Nodes
        /*for (let i = 1; i < GlobalNodes.length; i++) {
            if (GlobalNodes[i].type === "TerraformN") {
                let TFFather = parseInt(GlobalNodes[i].parentNode);
                //console.log("TFFather", TFFather)
                let List = FindNodesChieldID(GlobalNodes, TFFather);
                ListTF.push([i, List]);
                if (i == TFID) { ListPositionTFSatete = CountListTF }
                CountListTF += 1;
            }
        }
        //console.log("ListTF", ListTF)
        //console.log("ListPositionTFSatete", ListPositionTFSatete)
        //Crai a lista de nodes do TF corrente: ListNodesID
        let UsedNodesList = FindAllFathers(GlobalNodes, TFID);
        try {
            ListTFState = ListTF[ListPositionTFSatete][1];
        } catch (error) {
            //pass
        }
        UsedNodesList = UsedNodesList.concat(ListTFState);
        for (let i = 0; i < UsedNodesList.length; i++) {
            ListNodesID.push(UsedNodesList[i]);
        }
        //verifica outros TF nodes que são source ou target do TF currente, e inclui os nodes na lista UsedNodesList
        for (let i = 0; i < GlobalEdges.length; i++) {
            let Source = parseInt(GlobalEdges[i]["source"]);
            let Target = parseInt(GlobalEdges[i]["target"]);
            if (ListTF[ListPositionTFSatete][1].includes(Target) || (ListTF[ListPositionTFSatete][1].includes(Source))) {
                //console.log("Source Target", Source, Target)
                for (let j = 0; j < ListTF.length; j++) {
                    if ((ListTF[j][1].includes(Source)) || (ListTF[j][1].includes(Target))) {
                        let CurrentTFID = ListTF[j][0];
                        if (CurrentTFID != TFID) {
                            UsedNodesList = UsedNodesList.concat(ListTF[j][1]);
                            let ListFather = FindAllFathers(GlobalNodes, CurrentTFID);
                            UsedNodesList = UsedNodesList.concat(ListFather);
                        }
                    }
                }
            }
        }
        //********* insere TODOS os nodes ******************
        for (let i = 1; i < GlobalNodes.length; i++) {
            if (["NullN"].includes(GlobalNodes[i].type) == false)
                UsedNodesList[i] = parseInt(GlobalNodes[i].id);
        }
        //Convert UsedNodesList para Int
        //for (let i = 0; i < UsedNodesList.length; i++) {
        //    UsedNodesList[i] = parseInt(UsedNodesList[i]);
        //}
        // Cria lista dos novos nodes para enviar ao backend
        UsedNodesList = [...new Set(UsedNodesList)];
        for (let i = 0; i < UsedNodesList.length; i++) {
            NewNode = JSON.parse(JSON.stringify(GlobalNodes[UsedNodesList[i]]));
            NewNode.id = i.toString();
            NewNodeToOldNode[i] = UsedNodesList[i];
            OldNodeToNewNode[UsedNodesList[i]] = i;
            NewNodes.push(NewNode);
        }
        // Atualiza o campo parentNode dos nodes
        for (let i = 0; i < NewNodes.length; i++) {
            //console.log("NewNodes[i].parentNode)", NewNodes[i].parentNode);
            try {
                NewNodes[i].parentNode = OldNodeToNewNode[parseInt(NewNodes[i].parentNode)].toString();
            } catch (error) { //pass }
                NewNodes[i].parentNode = "-1";
                //console.log("Erro em Atualiza o campo parentNode", i);
            }
        }
        //Atualiza os id de ListNodesID
        for (let i = 0; i < ListNodesID.length; i++) {
            ListNodesID[i] = OldNodeToNewNode[ListNodesID[i]].toString();
        }
        //Cria a lista das novas edges para enviar ao backend
        for (let i = 0; i < GlobalEdges.length; i++) {
            if (UsedNodesList.includes(parseInt(GlobalEdges[i].source))) {
                NewEdge = JSON.parse(JSON.stringify(GlobalEdges[i]));
                let Source = parseInt(NewEdge.source);
                NewEdge.source = OldNodeToNewNode[Source].toString();
                let Target = parseInt(NewEdge.target);
                NewEdge.target = OldNodeToNewNode[Target].toString();
                NewEdges.push(NewEdge);
            }
        }
        //} catch (error) {
        //pass
        //} */
        ListNodesID = ListTerraformDomain(GlobalNodes, GlobalNodes[TFID]);
        //console.log("ListNodesID", ListNodesID)
        let CloudRegionList = [];
        for (let i = 0; i < ListNodesID.length; i++) {
            //Seleciona Cloud e Region Nodes
            let NodeType = GlobalNodes[ListNodesID[i]].type;
            if (TypeCloudRegion.includes(NodeType)) { CloudRegionList.push(ListNodesID[i]); }
        }
        //Testa se Terraform é para apenas VPC network, e se sim remove outros nodes
        const IsVPCNetwork = GlobalNodes[TFID].data.Param[2][1] == 1;
        const IsVPCNonNetwork = GlobalNodes[TFID].data.Param[2][1] == 2;
        if (IsVPCNetwork || IsVPCNonNetwork) {
            let NewList = [];
            for (let i = 0; i < ListNodesID.length; i++) {
                if (Constraints.NetworkResources.includes(GlobalNodes[ListNodesID[i]].type)) {
                    if (IsVPCNetwork) { NewList.push(ListNodesID[i]); }
                } else {
                    if (IsVPCNonNetwork) { NewList.push(ListNodesID[i]); }
                }
            }
            ListNodesID = [...new Set(NewList)];
        }
        //Remove nodes de Terraform State abaixo do State corrente
        let ListTFStateChild = [];
        for (let i = 0; i < ListNodesID.length; i++) {
            //Seleciona Cloud e Region Nodes
            const ListNodeID = ListNodesID[i];
            const ListNodeType = GlobalNodes[ListNodeID].type
            if ((TypeTerraform.includes(ListNodeType)) && (ListNodeID != TFID)) {
                ListTFStateChild = ListTFStateChild.concat(ListTerraformDomain(GlobalNodes, GlobalNodes[ListNodesID[i]]));
            }
        }
        ListNodesID = ListNodesID.filter(elemento => !ListTFStateChild.includes(elemento));
        ListNodesID = ListNodesID.concat(CloudRegionList);


        ListNodesID = [...new Set(ListNodesID)];
        let FileName = sessionStorage.getItem("FileName");
        //Filtra os elementos da mesma cloud
        let LocalNodes = AdjustSufixName(GlobalEdges, GlobalNodes, TFID);
        console.log("LocalNodes", LocalNodes)
        let Adjust = AjustEdgesNodesToSavePartial(GlobalEdges, LocalNodes, false);
        const LocalEdges = Adjust[0];
        LocalNodes = Adjust[1];
        console.log("LocalNodes", LocalNodes)
        //tratamento de disable nodes
        for (let i = 0; i < LocalNodes.length; i++) {
            if (LocalNodes[i].data.Disable) {
                LocalNodes[i].type = "Discard";
            }
        }
        //console.log("LocalNodes", LocalNodes)
        updateTerraformDependencies(TFID, GlobalEdges, LocalNodes, LocalEdges); //Adiciona eddges entre todos os ancestrais para TFID
        //console.log("OldEdges", GlobalEdges)
        //console.log('NewEdges', LocalEdges)
        TerraformNodeName = LocalNodes[TFID].data.Param[1][1];
        //console.log("LocalNodes bbb", LocalNodes, GlobalNodes)
        var myHeaders = new Headers();
        let WarningIcon = Constraints.IconsURL + "Warning.png";
        let ErrorIcon = Constraints.IconsURL + "Error.png";
        NewEdges = TestDuplicateEdges(GlobalEdges);
        async function CompileExec() {
            let newErrors = [];
            let ListError = [];
            CheckEdgeAttribute(GlobalEdges, GlobalNodes, ListError)
            /*for (let i = 0; i < GlobalEdges.length; i++) {
                const Label = GlobalEdges[i].label;
                if (Label.includes("|")) {
                    const SourceID = parseInt(GlobalEdges[i].source)
                    const TargetID = parseInt(GlobalEdges[i].target)
                    //console.log("SourceID", SourceID, TargetID)
                    if (Label.includes("?|")) {
                        ListError.push([SourceID, ': You should double-click on edge "?" between these nodes to select an option.', TargetID])
                    } else {
                        const SourceID = parseInt(GlobalEdges[i].source)
                        let SourceType = GlobalNodes[SourceID].type;
                        let ListEdgesAttributesSource = [];
                        if (!ListNodesEdges.includes(SourceID)) {
                            ListEdgesAttributesSource = ListaStandard[parseInt(ResourceLookUp[SourceType])]["ListEdgesAttributes"] || [];
                            if (ListEdgesAttributesSource.length > 0) {
                                ListNodesEdges.push(SourceID)
                                for (let j = 0; j < ListEdgesAttributesSource.length; j++) {
                                    const ListNodes = ListEdgesAttributesSource[j][0];
                                    const Direction = ListEdgesAttributesSource[j][2];
                                    console.log("ListNodes", ListNodes, Direction)
                                    for (let k = 0; k < ListNodes.length; k++) {
                                        if (Direction === "Source") {
                                            const NodeType = ListNodes[k];
                                            const List = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[SourceID], NodeType);
                                            console.log("List", List)
                                            let LabelList = [];
                                            for (let l = 0; l < List.length; l++) {
                                                const NodeID = parseInt(List[l])
                                                const LocalLabel = findEdgeLabel(GlobalEdges, NodeID, SourceID).ctrl;
                                                console.log("LocalLabel target", LocalLabel, SourceID, NodeID)
                                                if (LabelList.includes(LocalLabel)) {
                                                    console.log("erro duplicidade edge")
                                                    ListError.push([SourceID, `The edge attribute ${LocalLabel} is duplicated. Each attribute must be unique.`])
                                                }
                                                LabelList.push(LocalLabel)
                                            }
                                        } else {
                                            const NodeType = ListNodes[k];
                                            const List = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[SourceID], NodeType);
                                            console.log("List", List)
                                            let LabelList = [];
                                            for (let l = 0; l < List.length; l++) {
                                                const NodeID = parseInt(List[l])
                                                const LocalLabel = findEdgeLabel(GlobalEdges, SourceID, NodeID).ctrl;
                                                console.log("LocalLabel target", LocalLabel, SourceID, NodeID)
                                                if (LabelList.includes(LocalLabel)) {
                                                    console.log("erro duplicidade edge")
                                                    ListError.push([SourceID, `The edge attribute ${LocalLabel} is duplicated. Each attribute must be unique.`])
                                                }
                                                LabelList.push(LocalLabel)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }*/
            let IconInput = "";
            let IconOuput = "";
            let ListOuputOpenNodes = [];
            let ListInputOpenNodes = [];
            let ListWarning = [];
            let newWarnings = [];
            console.log("Passou aqui B", ListNodesID)
            NameError = CheckIdenticalName(LocalEdges, LocalNodes, Constraints, ListNodesID);
            if (ListError.length == 0 && NameError.length == 0) {
                //console.log("Passou aqui B");
                ErrorDiagram = false;
                setLoading(true);
                let [Cloud, Region] = FindRegionAndCloud(GlobalNodes, TFID);
                let LambdaResource = "GenTerraformCode";
                let APITerraformCode = APIAWSTerraformCode;
                //console.log("Cloud", Region, Cloud, GlobalNodes[parseInt(Cloud)])
                if (GlobalNodes[parseInt(Cloud)].type === "AZCloudN") {
                    APITerraformCode = APIAzureTerraformCode;
                }
                let TFFatherID = parseInt(GlobalNodes[TFID].parentNode);
                let TFFatherType = GlobalNodes[TFFatherID].type;
                if (TFFatherType === "KNamespaceN") {
                    LambdaResource = "GenKubernetesTerraformCode";
                    console.log("GenKubernetesTerraformCode")
                }
                const raw = [TerraformNodeName, ListNodesID, FileName, LocalEdges, LocalNodes, StorageID, DBID, RepoID, BuildID, TFID];
                console.log("raw", raw)
                const RespAPI = await CallAPI(APITerraformCode, raw, false);

                let Resp = JSON.parse(RespAPI).body;
                try {
                    console.log("Compilation Resp", Resp);
                    ListOuputOpenNodes = Resp[1];
                    ListInputOpenNodes = Resp[2];
                    ListError = Resp[3];
                    ListWarning = Resp[4];
                    GlobalNodes[TFID].data.CodeGenerated = Resp[0];
                    if (Stage !== "DevLocal") {
                        Save(GEdges, GNodes)
                    }
                } catch (error) {
                    ErrorDiagram = true;
                }
            }
            if (GlobalNodes.length > 0) {
                for (let i = 0; i < ListOuputOpenNodes.length; i++) {
                    let ID = ListOuputOpenNodes[i];
                    IconOuput = Constraints.IconsURL + GlobalNodes[ID[0]].type.slice(0, -1) + ".png"
                    newWarnings.push([ID[0], ID[1], IconOuput]);
                }
                for (let i = 0; i < ListInputOpenNodes.length; i++) {
                    let ID = ListInputOpenNodes[i];
                    IconInput = Constraints.IconsURL + GlobalNodes[ID[0]].type.slice(0, -1) + ".png"
                    newWarnings.push([ID[0], ID[1], IconInput]);
                }
                //console.log("ListWarning", ListWarning)
                for (let i = 0; i < ListWarning.length; i++) {
                    let ID = ListWarning[i][0];
                    //console.log("ID warning pre", ID)
                    //ID = AdjustID(ID, GlobalNodes);
                    //console.log("ID warning pos", ID)
                    let Msg = ListWarning[i][1];
                    let IconInput = Constraints.IconsURL + GlobalNodes[ID].type.slice(0, -1) + ".png";
                    newWarnings.push([ID, Msg, IconInput]);
                }
                WarningArray = newWarnings;
                console.log("ListError", ListError)
                for (let i = 0; i < ListError.length; i++) {
                    let Msg = ListError[i][1];
                    let ID = ListError[i][0];
                    IconInput = Constraints.IconsURL + GlobalNodes[ID].type.slice(0, -1) + ".png";
                    if (ListError[i][2]) {
                        const IDB = ListError[i][2];
                        const IconInputB = Constraints.IconsURL + GlobalNodes[IDB].type.slice(0, -1) + ".png";
                        newErrors.push([ID, Msg, IconInput, IDB, IconInputB]);
                    } else {
                        newErrors.push([ID, Msg, IconInput]);
                    }
                }
                ErrorArray = newErrors
            }

            //Success = ((ErrorArray.length == 0) && (WarningArray.length == 0));
            //Fail = ErrorArray.length > 0;
            //Pass = !Fail && !Success;
            let HaveSuccess = (ErrorArray.length === 0) && (WarningArray.length === 0) && (NameError.length === 0);
            console.log("HaveSuccess var", HaveSuccess)
            console.log("ErrorArray", ErrorArray, WarningArray, WarningArray.length)
            Success = HaveSuccess;
            Fail = ErrorArray.length > 0;
            Pass = !Fail && !Success;
            const currentDateTimeGMT = new Date().toISOString();
            console.log("Success", Success, TFID);
            if (HaveSuccess) {
                console.log("GlobalNodes[TFID].data.Compile.WarningList", GlobalNodes[TFID].data.Compile.WarningList)
                GlobalNodes[TFID].data.Compile.WarningList = [];
                GlobalNodes[TFID].data.Compile.ErrorList = [];
                GlobalNodes[TFID].data.Compile.NameError = [];
                GlobalNodes[TFID].data.Compile.LastDateTime = currentDateTimeGMT;
                console.log("HaveSuccess")
            } else {
                GlobalNodes[TFID].data.Compile.WarningList = WarningArray;
                GlobalNodes[TFID].data.Compile.ErrorList = ErrorArray;
                GlobalNodes[TFID].data.Compile.NameError = NameError;
                console.log("No HaveSuccess")
                if (Fail) {
                    GlobalNodes[TFID].data.Compile.LastDateTime = "Error";
                } else {
                    GlobalNodes[TFID].data.Compile.LastDateTime = currentDateTimeGMT;
                }
            }
            WarningIcon = Constraints.IconsURL + "Warning.png";
            //console.log("NameError", NameError)
            ErrorIcon = Constraints.IconsURL + "Error.png";
            //setData(Resp);
            setLoading(false);
            //console.log("newWarnings", newWarnings)
            //console.log("WarningArray length", WarningArray, WarningArray.length)


        };

        if (loading) {
            return <div style={{ backgroundColor: 'white' }}>Compiling...</div>;
        } else {
            return (
                <div style={{ backgroundColor: 'white' }}>
                    <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <button onClick={CompileExec} style={{ marginTop: '6px' }}>New Compile</button>
                        </div>

                        <h2>Result:</h2>
                        {NameError.map((ID) =>
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', }}>
                                    <img src={ID[2]} alt="Descrição da imagem" onClick={(e) => handleIconClick((ID[0]).toString())}
                                        style={{
                                            height: "60px", width: "60px", borderRadius: "2px", verticalAlign: "middle", marginRight: '8px',
                                            border: "1px solid black", cursor: "pointer !important", boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                                        }}></img>

                                    <img src={ID[3]} alt="Descrição da imagem" onClick={(e) => handleIconClick(ID[1])}
                                        style={{
                                            height: "60px", width: "60px", borderRadius: "2px", verticalAlign: "middle", marginRight: '8px',
                                            border: "1px solid black", cursor: "pointer !important", boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                                        }}></img>
                                    <div style={{ marginLeft: '8px' }}>
                                        <img src={ErrorIcon} alt="Descrição da imagem" style={{ height: "25px", width: "25px", verticalAlign: "middle" }}></img>
                                        <strong> Error: </strong>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>{ID[4]} </span>
                                    </div>
                                </div>
                            </>

                        )}
                        {WarningArray.map((ID) =>
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={ID[2]}
                                        alt="Descrição da imagem"
                                        onClick={() => handleIconClick(ID[0])}
                                        style={{
                                            height: "60px",
                                            width: "60px",
                                            verticalAlign: "middle",
                                            cursor: "pointer !important",
                                            border: "1px solid black",
                                            boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
                                            borderRadius: "7%",
                                        }}
                                    />
                                    {ID[3] && (
                                        <img
                                            src={ID[3]}
                                            onClick={() => handleIconClick(ID[3])}
                                            alt="Segunda descrição da imagem"
                                            style={{
                                                height: "60px",
                                                width: "60px",
                                                verticalAlign: "middle",
                                                cursor: "pointer !important",
                                                border: "1px solid black",
                                                boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
                                                borderRadius: "7%",
                                                marginLeft: '8px', // Ajuste de margem entre as imagens
                                            }}
                                        />
                                    )}
                                    <div style={{ marginLeft: '8px' }}>
                                        <img
                                            src={WarningIcon}
                                            alt="Descrição do ícone de aviso"
                                            style={{
                                                height: "25px",
                                                width: "25px",
                                                verticalAlign: "middle",
                                            }}
                                        />
                                        <strong>Warning:</strong>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>
                                            {GlobalNodes?.[ID[0]]?.data?.Param?.[1]?.[1]} &nbsp; {ID[1]}
                                        </span>
                                    </div>
                                </div>


                                <br></br>
                            </>
                        )}
                        {ErrorArray.map((ID) =>
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={ID[2]}
                                        alt="Descrição da imagem"
                                        onClick={() => handleIconClick(ID[0])}
                                        style={{
                                            height: "60px",
                                            width: "60px",
                                            verticalAlign: "middle",
                                            cursor: "pointer !important",
                                            border: "1px solid black",
                                            boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
                                            borderRadius: "7%",
                                        }}
                                    />
                                    {ID[4] && (
                                        <img
                                            src={ID[4]}
                                            alt="Segunda descrição da imagem"
                                            onClick={() => handleIconClick(ID[3])}
                                            style={{
                                                height: "60px",
                                                width: "60px",
                                                verticalAlign: "middle",
                                                cursor: "pointer !important",
                                                border: "1px solid black",
                                                boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
                                                borderRadius: "7%",
                                                marginLeft: '8px', // Ajuste de margem entre as imagens
                                            }}
                                        />
                                    )}
                                    <div style={{ marginLeft: '8px' }}>
                                        <img
                                            src={ErrorIcon}
                                            alt="Descrição do ícone de erro"
                                            style={{
                                                height: "25px",
                                                width: "25px",
                                                verticalAlign: "middle",
                                            }}
                                        />
                                        <strong>Error:</strong>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>
                                            {GlobalNodes?.[ID[0]]?.data?.Param?.[1]?.[1]} &nbsp; {ID[1]}
                                        </span>
                                    </div>
                                </div>

                                <br></br>
                            </>
                        )}
                        {Success && <h3> Success! 0 Errors and 0 warnings.</h3>}
                        {Fail && <h3> Fail! With {ErrorArray.length + NameError.length} error(s). </h3>}
                        {Pass && <h3> Pass with {WarningArray.length} warning(s). </h3>}
                        {ErrorDiagram && <h3> State diagram error: <br></br> The diagram contains an unsupported configuration.</h3>}
                    </div>
                </div >
            );
        }
    }
}
export { Compile };


