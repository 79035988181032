import React, { useState, useEffect } from 'react';
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import { NodeResizer } from 'reactflow';
import './index.css';
import { MinXYBox } from './Functions';
import { GNodes, GlobalListVisible } from './NodesData';


const GenericBoxNode = ({
    id,
    data,
    selected,
    children,
    HasHandle,
    borderWidth = 1,
    Color = "gray",

}) => {
    let ID = data?.ID || "";
    const IntID = parseInt(id);
    //const Width = GNodes[IntID].width;
    //const Height = GNodes[IntID].height;
    //console.log("Width,Heght", Width, Height)
    let initialX, initialY, initialWidth, initialHeight;
    let ChildList = [];
    let XMin, YMin, XMax, YMax;
    const updateNodeInternals = useUpdateNodeInternals();
    let directions = [];
    let VarMinWidth, VarMinHeight, VarMaxHeight, VarMaxWidth;
    try {
        const onResizeStart = (event, params) => {
            [XMin, YMin, XMax, YMax] = MinXYBox(GNodes, id);
            console.log("**************************MinBox start***************", parseInt(XMin), parseInt(YMin), parseInt(XMax), parseInt(YMax))
            initialWidth = GNodes[IntID].width;
            initialHeight = GNodes[IntID].height;
            initialX = params.x;
            initialY = params.y;
            //console.log("initialX, initialY, initialWidth, initialHeight", parseInt(initialX), parseInt(initialY), parseInt(initialWidth), parseInt(initialHeight));
            let NodeClone = JSON.parse(JSON.stringify(GNodes[IntID]));
            const IDClone = GNodes.length.toString();
            NodeClone.id = IDClone;
            NodeClone.hidden = true;
            NodeClone.data.Temporary = true;
            GNodes.push(NodeClone);
            for (let i = 0; i < GNodes.length; i++) {
                if (GNodes[i].parentNode == id) {
                    GNodes[i].parentNode = IDClone;
                    ChildList.push(i);
                    //console.log("Posx", GNodes[i].position.x);
                }
            }
            updateNodeInternals(id);
        };

        const onResize = (event, params) => {
            //console.log("**************************MinBox Resise***************", parseInt(XMin), parseInt(YMin), parseInt(XMax), parseInt(YMax))
            //console.log("resize initialX, initialY, initialWidth, initialHeight", parseInt(initialX), parseInt(initialY), parseInt(initialWidth), parseInt(initialHeight));
            const ParentID = parseInt(GNodes[IntID].parentNode);
            //console.log("parans", params.width, params.x);
            // Inferir direção horizontal
            if (params.width !== initialWidth) {
                if (params.x !== initialX) {
                    directions.push('left');
                } else {
                    directions.push('right');
                }
            }
            // Inferir direção vertical
            if (params.height !== initialHeight) {
                if (params.y !== initialY) {
                    directions.push('top');
                } else {
                    directions.push('bottom');
                }
            }
            if (directions.includes('right')) {
                const MaxWidth = GNodes[ParentID].width - GNodes[IntID].position.x;
                VarMinWidth = XMax;
                VarMaxWidth = MaxWidth;
                //console.log("Right XMax, MaxWidth", XMax, MaxWidth);
            }
            if (directions.includes('left')) {
                VarMinWidth = initialWidth - XMin;
                const MaxWidth = initialWidth + initialX;
                VarMaxWidth = MaxWidth;
                //console.log("left Min-Max width ", initialWidth - XMin, MaxWidth);
            }
            if (directions.includes('top')) {
                VarMinHeight = initialHeight - YMin;
                const MaxHeight = initialHeight + initialY;
                //setMaxHeight(MaxHeight);
                VarMaxHeight = MaxHeight;
                //console.log("Top Min-Max", initialHeight - YMin, VarMaxHeight);
            }
            if (directions.includes('bottom')) {
                VarMaxHeight = GNodes[ParentID].height - GNodes[IntID].position.y;
                VarMinHeight = YMax;
                //console.log("Bottom Min-Max", VarMinHeight, VarMaxHeight)
            }
            updateNodeInternals(id);//renderiza nodes internos

        };
        const onResizeEnd = (event, params) => {
            //console.log("**************************MinBox Resise end***************", parseInt(XMin), parseInt(YMin), parseInt(XMax), parseInt(YMax))
            const DeltaX = params.x - initialX;
            const DeltaY = params.y - initialY;
            for (let i = 0; i < ChildList.length; i++) {
                GNodes[ChildList[i]].parentNode = id;
                GNodes[ChildList[i]].position.x = GNodes[ChildList[i]].position.x - DeltaX;
                GNodes[ChildList[i]].position.y = GNodes[ChildList[i]].position.y - DeltaY;
            }
            let Last = GNodes.pop();
            Last.type = "NullN";
            //console.log("Last", Last)
            updateNodeInternals(id);
        };
        if (true) {//GlobalListVisible[ID]) {
            return (
                <>
                    <div style={{ width: '100%', height: '100%' }}>
                        {children} {ID}

                    </div>
                    <NodeResizer
                        onResizeStart={onResizeStart}
                        onResize={onResize}
                        onResizeEnd={onResizeEnd}
                        lineStyle={{ borderWidth: borderWidth }} // Ajusta a espessura do NodeResizer
                        color="#ff0000" // Ajusta a cor do NodeResizer
                        isVisible={selected}
                    />
                    {HasHandle && (
                        <>
                            <Handle type="source" position={Position.Top} />
                            <Handle type="source" position={Position.Right} />
                            <Handle type="source" position={Position.Bottom} />
                            <Handle type="source" position={Position.Left} />
                        </>
                    )}
                </>
            );
        }
    } catch (error) {
        //pass
    }
}


export default GenericBoxNode;
